export const NOTCH = 'NOTCH';
export const COUPE = 'COUPE';
export const WATERLIST = 'WATERLIST';
export const CHISELED_SIDE = 'CHISELED_SIDE';
export const FINISHED_SIDE = 'FINISHED_SIDE';
export const DEBASING = 'DEBASING';
export const DEBASING_ROUGH = 'DEBASING_ROUGH';
export const CORNER_CUTOUT = 'CORNER_CUTOUT';
export const GROOVES = 'GROOVES';
export const ROUNDED_CORNER = 'ROUNDED_CORNER';
export const PROFILE = 'PROFILE';
export const DRILL_HOLE = 'DRILL_HOLE';
export const HEIGHT_COUPE = 'HEIGHT_COUPE';
export const COUPE_OVER_LENGTH = 'COUPE_OVER_LENGTH';
export const GLUED_CUSHION = 'GLUED_CUSHION';
export const RECTANGULAR_CUT_OUT = 'RECTANGULAR_CUT_OUT';
export const ANCHOR_HOLE = 'ANCHOR_HOLE';
export const RABAT = 'RABAT';
export const EXTRA_SANDING_5CM_BOTTOM = 'EXTRA_SANDING_5CM_BOTTOM';
export const NOTCH_OVER_LENGTH = 'NOTCH_OVER_LENGTH';
export const BUSHHAMMERING = 'BUSHHAMMERING';
export const FROST_FLOWERS = 'FROST_FLOWERS';
export const SABLINO = 'SABLINO';
export const STIPPLED = 'STIPPLED';
export const SANDBLASTING = 'SANDBLASTING';
export const MANUAL_TRIMMING_STRAIGHT = 'MANUAL_TRIMMING_STRAIGHT';
export const MANUAL_TRIMMING_45_DEGREES = 'MANUAL_TRIMMING_45_DEGREES';
export const MACHINE_TRIMMING_CHISELING_STRAIGHT = 'MACHINE_TRIMMING_CHISELING_STRAIGHT';
export const MACHINE_CUT_AGED_45_DEGREES = 'MACHINE_CUT_AGED_45_DEGREES';
export const MACHINE_CUT_AGED_STRAIGHT = 'MACHINE_CUT_AGED_STRAIGHT';

// Renamed
export const CHIMNEY_LEAD_THROUGH__RENAMED = 'CHIMNEY_LEAD_THROUGH';

export const ALL = [
	NOTCH,
	NOTCH_OVER_LENGTH,
	COUPE,
	CHISELED_SIDE,
	FINISHED_SIDE,
	DEBASING,
	CORNER_CUTOUT,
	GROOVES,
	ROUNDED_CORNER,
	PROFILE,
	DRILL_HOLE,
	HEIGHT_COUPE,
	COUPE_OVER_LENGTH,
	GLUED_CUSHION,
	DEBASING_ROUGH,
	RECTANGULAR_CUT_OUT,
	ANCHOR_HOLE,
	RABAT,
	EXTRA_SANDING_5CM_BOTTOM,
	BUSHHAMMERING,
	FROST_FLOWERS,
	SABLINO,
	STIPPLED,
	SANDBLASTING,
	MANUAL_TRIMMING_STRAIGHT,
	MANUAL_TRIMMING_45_DEGREES,
	MACHINE_TRIMMING_CHISELING_STRAIGHT,
	MACHINE_CUT_AGED_45_DEGREES,
	MACHINE_CUT_AGED_STRAIGHT,
];

export const SUMMARIZED_OPERATIONS = [
	FINISHED_SIDE,
	CHISELED_SIDE,
	WATERLIST,
	PROFILE,
	GLUED_CUSHION,
	EXTRA_SANDING_5CM_BOTTOM,
];

export const OPERATIONS_TO_DRAW_2D_IMAGES = [
	NOTCH,
	COUPE,
	CORNER_CUTOUT,
	GROOVES,
	ROUNDED_CORNER,
	PROFILE,
	HEIGHT_COUPE,
	DRILL_HOLE,
	COUPE_OVER_LENGTH,
	GLUED_CUSHION,
	DEBASING_ROUGH,
	RECTANGULAR_CUT_OUT,
	ANCHOR_HOLE,
	RABAT,
	NOTCH_OVER_LENGTH,
];

export const PROHIBITED_OPERATION_TYPES = {
	COUPE: [HEIGHT_COUPE, COUPE, COUPE_OVER_LENGTH, PROFILE, NOTCH, CORNER_CUTOUT, ROUNDED_CORNER, GLUED_CUSHION],
	NOTCH: [COUPE, GLUED_CUSHION],
	CORNER_CUTOUT: [COUPE],
	ROUNDED_CORNER: [COUPE],
	PROFILE: [HEIGHT_COUPE, COUPE, COUPE_OVER_LENGTH, GLUED_CUSHION],
	HEIGHT_COUPE: [HEIGHT_COUPE, COUPE_OVER_LENGTH, COUPE, PROFILE, GLUED_CUSHION],
	COUPE_OVER_LENGTH: [COUPE_OVER_LENGTH, PROFILE],
	GLUED_CUSHION: [COUPE, PROFILE, HEIGHT_COUPE, ROUNDED_CORNER, CORNER_CUTOUT, NOTCH, DEBASING_ROUGH],
	DEBASING_ROUGH: [GLUED_CUSHION],
};

export const DEBASE_ROUGH_TYPES = {
	BACK_TO_FRONT: 'BACK_TO_FRONT',
	FRONT_TO_BACK: 'FRONT_TO_BACK',
	MIDDLE_TO_SIDES: 'MIDDLE_TO_SIDES',
};

export const DEBASE_ROUGH_TYPES_PILLARS = {
	LEFT_TO_RIGHT: 'LEFT_TO_RIGHT',
	RIGHT_TO_LEFT: 'RIGHT_TO_LEFT',
};

export const DEBASE_ROUGH_TYPES_BASEBOARDS = {
	TOP_TO_BOTTOM: 'TOP_TO_BOTTOM',
	BOTTOM_TO_TOP: 'BOTTOM_TO_TOP',
};

export const RECTANGULAR_CUT_OUT_TYPES = {
	ROUND: 'ROUND',
	RECTANGULAR: 'RECTANGULAR',
};

export const OPERATION_SHAPES = {
	ROUND: 'ROUND',
	RECTANGULAR: 'RECTANGULAR',
};

export const CUMULATED_OPERATIONS = [
	DEBASING,
	DEBASING_ROUGH,
	BUSHHAMMERING,
	FROST_FLOWERS,
	SABLINO,
	STIPPLED,
	MANUAL_TRIMMING_STRAIGHT,
	MANUAL_TRIMMING_45_DEGREES,
	MACHINE_TRIMMING_CHISELING_STRAIGHT,
	MACHINE_CUT_AGED_45_DEGREES,
	MACHINE_CUT_AGED_STRAIGHT,
];

export const SURFACE_OPERATION_TYPES = [
	BUSHHAMMERING,
	FROST_FLOWERS,
	SABLINO,
	STIPPLED,
	SANDBLASTING,
	MANUAL_TRIMMING_STRAIGHT,
	MANUAL_TRIMMING_45_DEGREES,
	MACHINE_TRIMMING_CHISELING_STRAIGHT,
	MACHINE_CUT_AGED_45_DEGREES,
	MACHINE_CUT_AGED_STRAIGHT,
];

export const SURFACE_OPERATIONS_TO_SHOW_MARKS_FOR = [
	MANUAL_TRIMMING_STRAIGHT,
	MANUAL_TRIMMING_45_DEGREES,
	MACHINE_TRIMMING_CHISELING_STRAIGHT,
	MACHINE_CUT_AGED_45_DEGREES,
	MACHINE_CUT_AGED_STRAIGHT,
	SANDBLASTING,
];
