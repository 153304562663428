export const LEFT_UP = 'LEFT_UP';
export const LEFT_DOWN = 'LEFT_DOWN';
export const LEFT_BACK_UP = 'LEFT_BACK_UP';
export const LEFT_FRONT_DOWN = 'LEFT_FRONT_DOWN';
export const LEFT_HORIZONTAL = 'LEFT_HORIZONTAL';

export const RIGHT_UP = 'RIGHT_UP';
export const RIGHT_DOWN = 'RIGHT_DOWN';
export const RIGHT_BACK_UP = 'RIGHT_BACK_UP';
export const RIGHT_FRONT_DOWN = 'RIGHT_FRONT_DOWN';
export const RIGHT_HORIZONTAL = 'RIGHT_HORIZONTAL';

export const COUPE = 'COUPE';
export const INNER_COUPE = 'INNER_COUPE';
export const OUTER_COUPE = 'OUTER_COUPE';

export const NORMAL = 'NORMAL';
export const HEIGHT_COUPE = 'HEIGHT_COUPE';

export const LEFT_STYLES = [LEFT_UP, LEFT_DOWN, LEFT_BACK_UP, LEFT_FRONT_DOWN, LEFT_HORIZONTAL];

export const RIGHT_STYLES = [RIGHT_UP, RIGHT_DOWN, RIGHT_BACK_UP, RIGHT_FRONT_DOWN, RIGHT_HORIZONTAL];
