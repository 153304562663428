import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { ValidatedInput } from '../forms/inputs/ValidatedInput';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setConfirmationModal } from '../../actions/GeneralActions';
import { SectionTitle } from '../headers/SectionTitle';
import { LocationSelectorForm } from './shared/LocationSelectorForm';
import { Operation } from '../../classes/models/Operation';
import { parseNumber } from '../../classes/helpers/StringHelper';
import { VectorHelper } from '../../classes/helpers/VectorHelper';
import { createOperation, deleteOperation, updateOperation } from './index';
import { BACK, LEFT, RIGHT } from '../../constants/ObjectSides';
import { RadioButton } from '../forms/inputs/RadioButton';
import { useEffect, useState } from 'react';
import { ANCHOR_HOLE } from '../../constants/OperationTypes';
import { ModalHolder } from './ModalHolder';

export const AnchorHoleModal = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'modals.anchorHoleModal.';
	const sidesPrefix = 'constants.objectSides.';

	const { register, handleSubmit, errors } = useForm();

	const [selectedSide, setSelectedSide] = useState();

	const { currentPiece, currentOperation } = useSelector(state => state.offerReducer);
	const canEdit = useSelector(state => state.generalReducer.canEdit);

	const dispatch = useDispatch();

	const sides = [LEFT, BACK, RIGHT];

	useEffect(() => {
		if (props.isActive) {
			if (currentOperation) {
				setSelectedSide(currentOperation.side);
			} else {
				setSelectedSide(sides[0]);
			}
		}
	}, [props.isActive]);

	const createAnchorHole = fields => {
		let anchorHole = new Operation(0, ANCHOR_HOLE);
		anchorHole.dimensions = { length: 1, height: 1, width: 4 };
		anchorHole.side = selectedSide;
		anchorHole.position = getPosition(anchorHole, fields);

		createOperation(anchorHole, () => props.onClose(true), t(prefix + 'anchorHoleCreateFailed'));
	};

	const updateAnchorHole = fields => {
		currentOperation.position = getPosition(currentOperation, fields);
		currentOperation.side = selectedSide;

		updateOperation(currentOperation, () => props.onClose(true), prefix + 'anchorHoleUpdateFailed');
	};

	const deleteAnchorHole = () => {
		deleteOperation(
			currentOperation,
			() => props.onClose(true),
			t(prefix + 'anchorHoleDeleteSuccess'),
			t(prefix + 'anchorHoleDeleteFailed'),
		);
	};

	const getPosition = (anchorHole, fields) => {
		return VectorHelper.getAnchorHolePosition(
			currentPiece.dimensions,
			anchorHole,
			{ side: fields.verticalSide, value: parseNumber(fields.yField) },
			{ side: fields.horizontalSide, value: parseNumber(fields.xField) },
		);
	};

	const closeModal = fields => {
		if (!fields) {
			props.onClose();
			return;
		}

		if (currentOperation) {
			updateAnchorHole(fields);
		} else {
			createAnchorHole(fields);
		}
	};

	const openConfirmationModal = () => {
		dispatch(
			setConfirmationModal({
				isActive: true,
				content: t(prefix + 'confirmationModalContent'),
				onAccept: () => deleteAnchorHole(),
			}),
		);
	};

	const renderFooterButtons = () => {
		let secondaryButtonStyle = 'button button--outline';
		let secondaryButtonContent = t(prefix + 'cancelButton');
		let secondaryButtonOnClick = () => closeModal();

		if (currentOperation != null && currentOperation.type === ANCHOR_HOLE && canEdit) {
			secondaryButtonStyle = 'button button--danger';
			secondaryButtonContent = t(prefix + 'deleteButton');
			secondaryButtonOnClick = () => openConfirmationModal();
		}

		const submitButton = (
			<button
				key={'anchorHoleModalSubmitButton'}
				className="button button--primary"
				form="anchorHoleForm"
				type="submit"
			>
				{t(prefix + 'doneButton')}
			</button>
		);

		let buttons = [
			<button
				key={'anchorHoleModalCancelButton'}
				type="button"
				className={secondaryButtonStyle}
				onClick={secondaryButtonOnClick}
			>
				{secondaryButtonContent}
			</button>,
		];

		if (canEdit) {
			buttons.unshift(submitButton);
		}

		return buttons;
	};

	return (
		<ModalHolder isActive={props.isActive}>
			<Modal show={props.isActive} onHide={() => closeModal()} animation={true} className="modal">
				<Modal.Header>
					<Modal.Title>{t(prefix + 'header')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>{t(prefix + 'description')}</p>

					<SectionTitle content={t(prefix + 'sideTitle')} />

					{sides.map(side => {
						return (
							<RadioButton
								key={`anchorHoleSideSelect${side}`}
								name="anchorHoleSideSelect"
								content={t(sidesPrefix + side)}
								onChange={() => setSelectedSide(side)}
								checked={selectedSide === side}
								disabled={!canEdit}
							/>
						);
					})}

					<form id="anchorHoleForm" onSubmit={handleSubmit(closeModal)}>
						<LocationSelectorForm register={register} errors={errors} side={selectedSide} />
					</form>
				</Modal.Body>
				<Modal.Footer>{renderFooterButtons()}</Modal.Footer>
			</Modal>
		</ModalHolder>
	);
};

AnchorHoleModal.propTypes = {
	isActive: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};
