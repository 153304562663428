import * as ActionTypes from '../constants/ActionTypes';

export const setArticles = articles => {
	return {
		type: ActionTypes.SET_ARTICLES,
		payload: articles,
	};
};

export const setArticleModalActive = payload => {
	return {
		type: ActionTypes.SET_ARTICLE_MODAL_ACTIVE,
		payload,
	};
};

export const setActiveArticle = article => {
	return {
		type: ActionTypes.SET_ACTIVE_ARTICLE,
		payload: article,
	};
};

export const setArticleConfig = articleConfig => {
	return {
		type: ActionTypes.SET_ARTICLE_CONFIG,
		payload: articleConfig,
	};
};
