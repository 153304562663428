import axios from 'axios';
import i18next from '../../i18n';
import { getBaseUrl } from '../helpers/UrlHelper';
import { store } from '../../store';
import { convert2DImageBlobsToBase64 } from '../helpers/Base64Helper';

class Service {
	constructor() {
		axios.defaults.headers['Content-Language'] = i18next.language;
		axios.defaults.headers['Content-Type'] = 'application/json';
		axios.defaults.headers['Accept'] = 'application/json';
		axios.defaults.headers['Access-Control-Allow-Credentials'] = 'true';
		axios.defaults.headers['Access-Control-Allow-Origin'] = 'true';

		this.baseUrl = getBaseUrl(store.getState().generalReducer.domain);
	}

	authenticatedRequest(method, url, data = {}) {
		let config = {
			url: this.baseUrl + url,
			method: method,
			withCredentials: true,
		};

		if (method.toLowerCase() === 'get') {
			config.params = data;
		} else {
			config.data = data;
		}

		return axios(config);
	}

	anonymousRequest(method, url, data) {
		const config = {
			url: this.baseUrl + url,
			method: method,
			withCredentials: true,
			data: data,
		};

		return axios(config);
	}

	async uploadMultipleFilesRequest(url, files, data = {}, responseType = 'arraybuffer') {
		const formData = new FormData();

		Object.keys(data).forEach(key => {
			formData.append(key, data[key]);
		});

		files = convert2DImageBlobsToBase64(files);

		return await Promise.all(files).then(d => {
			d.forEach(file => {
				formData.append('images[]', JSON.stringify(file));
			});

			const config = {
				method: 'post',
				url: this.baseUrl + url,
				data: formData,
				responseType: responseType,
				withCredentials: true,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			};

			return axios(config);
		});
	}

	fileRequest(method, url, data = {}) {
		const config = {
			url: this.baseUrl + url,
			method: method,
			params: data, //data = params when method 'GET'
			responseType: 'blob',
			withCredentials: true,
		};

		return axios(config);
	}

	authenticatedFileRequest(method, url, data = {}) {
		let config = {
			url: this.baseUrl + url,
			method: method,
			withCredentials: true,
			responseType: 'blob',
			headers: {
				'content-type': 'multipart/form-data',
			},
		};

		if (method.toLowerCase() === 'get') {
			config.params = data;
		} else {
			config.data = data;
		}

		return axios(config);
	}
}

export { Service };
