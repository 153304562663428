import * as React from 'react';
import PropTypes from 'prop-types';

export const NavigationBarItem = props => {
	const getStyling = () => {
		if (props.isActive) return 'app__navbar__item app__navbar__item--active';

		return 'app__navbar__item';
	};

	return (
		<span onClick={() => props.action()} className={getStyling()} data-cy={props.dataCy}>
			<i className="material-icons">{props.icon}</i> {props.content}
		</span>
	);
};

NavigationBarItem.propTypes = {
	icon: PropTypes.string.isRequired,
	content: PropTypes.string.isRequired,
	action: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	isActive: PropTypes.bool,
	dataCy: PropTypes.string,
};
