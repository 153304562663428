import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { ModalHolder } from './ModalHolder';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { useSelector } from 'react-redux';
import { ConfirmationModal } from './ConfirmationModal';
import { ALL_SIDES, BOTTOM, SIDE_ORDER, SIDES, SURFACES, TOP } from '../../constants/ObjectSides';
import { SectionTitle } from '../headers/SectionTitle';
import {
	BUSHHAMMERING,
	CHISELED_SIDE,
	FROST_FLOWERS,
	MACHINE_CUT_AGED_45_DEGREES,
	MACHINE_CUT_AGED_STRAIGHT,
	MACHINE_TRIMMING_CHISELING_STRAIGHT,
	MANUAL_TRIMMING_45_DEGREES,
	MANUAL_TRIMMING_STRAIGHT,
	SABLINO,
	SANDBLASTING,
	STIPPLED,
	SURFACE_OPERATION_TYPES,
} from '../../constants/OperationTypes';
import { setOperationWithSides } from './index';
import { Message } from '../messages/Message';
import { WARNING } from '../../constants/Variants';
import { GRANITE } from '../../constants/Materials';
import { getSideNameByPreset } from '../../classes/helpers/ObjectHelper';
import { CheckboxWithIcon } from '../forms/inputs/CheckboxWithIcon';
import { COMPLETELY } from '../../constants/FinishedSideStates';
import { SelectableImage } from '../forms/inputs/SelectableImage';
import bushhammering from '../../assets/img/operations/boucharderen.png';
import frostFlowers from '../../assets/img/operations/ijsbloemen.png';
import sablino from '../../assets/img/operations/sablino.png';
import stippled from '../../assets/img/operations/gepunt.png';
import manualTrimmingStraight from '../../assets/img/operations/manueel-bekappen.png';
import manualTrimming45Degrees from '../../assets/img/operations/manueel-oud-gekapt-45-graden.png';
import machineTrimmingChiselingStraight from '../../assets/img/operations/ciseleren.png';
import machineTrimmingCutAged45Degrees from '../../assets/img/operations/machinaal-oudgekapt-45-graden.png';
import machineTrimmingCutAgedStraight from '../../assets/img/operations/machinaal-oudgekapt-recht.png';
import { CHISELED_OBJECT_TYPES, TYPE_10 } from '../../constants/ObjectTypes';
import { BASEBOARDS, LINTELS, PILLARS, UPRIGHT_PRESETS } from '../../constants/Presets';

export const SurfaceOperationsModal = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'modals.surfaceOperationsModal.';
	const sidePrefix = 'constants.objectSides.';
	const operationTypePrefix = 'constants.operationTypes.';

	const [confirmationModalIsActive, setConfirmationModalIsActive] = useState(false);
	const [disabledOperationTypes, setDisabledOperationTypes] = useState([]);
	const [disabledSides, setDisabledSides] = useState([]);
	const [selectedOperationType, setSelectedOperationType] = useState(null);
	const [selectedSides, setSelectedSides] = useState([]);
	const [isSideSelectionDisabled, setIsSideSelectionDisabled] = useState(false);
	const [selectableSides, setSelectableSides] = useState([]);
	const [warningMessage, setWarningMessage] = useState('');

	const { canEdit } = useSelector(state => state.generalReducer);
	const { currentConfiguration, currentPiece, currentOperation } = useSelector(state => state.offerReducer);

	const selectableOperationTypes = SURFACE_OPERATION_TYPES;

	useEffect(() => {
		if (currentPiece) {
			setDisabledSides(getDisabledSides());
			setDisabledOperationTypes(getDisabledOperationTypes());
		}
	}, [currentPiece, selectedOperationType]);

	useEffect(() => {
		if (!props.isActive) {
			return;
		}

		let sides;

		if ([BUSHHAMMERING, FROST_FLOWERS, SABLINO, STIPPLED].includes(selectedOperationType)) {
			sides = [TOP];
		} else if (selectedOperationType === SANDBLASTING) {
			// Sandblasting is always on all sides
			sides = [];
		} else if (selectedOperationType === MACHINE_TRIMMING_CHISELING_STRAIGHT) {
			if (
				CHISELED_OBJECT_TYPES.includes(currentConfiguration?.options?.type) ||
				[PILLARS, LINTELS, BASEBOARDS].includes(currentConfiguration.options.preset)
			) {
				sides = ALL_SIDES;
			} else {
				sides = [TOP, BOTTOM];
			}
		} else {
			sides = ALL_SIDES;
		}

		setSelectableSides(sortSides(sides));
	}, [props.isActive, selectedOperationType]);

	useEffect(() => {
		if (!currentConfiguration || !props.isActive) return;

		if (selectedOperationType === BUSHHAMMERING && currentConfiguration.options.height < 4) {
			setWarningMessage(t(prefix + 'formDisabledForHeightMessage', { height: 4 }));
		} else if (selectedOperationType === BUSHHAMMERING && currentConfiguration.options.material === GRANITE) {
			setWarningMessage(t(prefix + 'formDisabledForGraniteMessage'));
		} else if (selectedOperationType === STIPPLED && currentConfiguration.options.height < 3) {
			setWarningMessage(t(prefix + 'formDisabledForPiecesWithHeightsUnder3'));
		} else {
			setIsSideSelectionDisabled(false);
			return;
		}

		setIsSideSelectionDisabled(true);
	}, [currentConfiguration?.options, selectedOperationType, props.isActive]);

	useEffect(() => {
		if (!props.isActive) {
			setSelectedOperationType(null);
			setSelectedSides([]);

			return;
		}

		const sidesToSet = [];

		if (currentOperation) {
			setSelectedOperationType(currentOperation.type);

			currentPiece?.getOperationsByType(currentOperation.type).forEach(o => sidesToSet.push(o.side));

			if (currentOperation.type === MACHINE_TRIMMING_CHISELING_STRAIGHT) {
				// TOP and BOTTOM should not be checked as standard
				sidesToSet.push(...getChiseledSides().filter(side => !SURFACES.includes(side)));
			}
		} else {
			if (selectedOperationType === MACHINE_TRIMMING_CHISELING_STRAIGHT) {
				currentPiece?.getOperationsByType(selectedOperationType).forEach(o => sidesToSet.push(o.side));

				sidesToSet.push(...getChiseledSides().filter(side => !SURFACES.includes(side)));
			}
		}

		setSelectedSides(sidesToSet);
	}, [props.isActive, currentOperation, selectedOperationType]);

	const sortSides = sides => {
		return sides.sort((a, b) => SIDE_ORDER[a] - SIDE_ORDER[b]);
	};

	const getChiseledSides = () => {
		const sides = [];

		currentPiece.getOperationsByType(CHISELED_SIDE).forEach(o => {
			if (SIDES.includes(o.side)) {
				if (o.additionalDimension.type === COMPLETELY) {
					sides.push(o.side);
				}
			} else {
				sides.push(o.side);
			}
		});

		return sides;
	};

	const getDisabledSides = () => {
		if (!currentPiece) {
			return [];
		}

		let sides = [];

		return currentPiece
			.getOperationsByType(SURFACE_OPERATION_TYPES)
			.filter(o => o.type !== currentOperation?.type)
			.map(o => o.side)
			.concat(sides);
	};

	const getDisabledOperationTypes = () => {
		if (!currentPiece) {
			return [];
		}
		let disabledTypes = [];

		if (currentPiece.type === TYPE_10) {
			disabledTypes = SURFACE_OPERATION_TYPES.filter(
				t => ![SANDBLASTING, MACHINE_TRIMMING_CHISELING_STRAIGHT].includes(t),
			);
		}

		const configuredSurfaceOperations = currentPiece.getOperationsByType(SURFACE_OPERATION_TYPES).map(o => o.type);

		return [...new Set(configuredSurfaceOperations.concat(disabledTypes))];
	};

	const getImage = operationType => {
		let image;

		switch (operationType) {
			case BUSHHAMMERING:
				image = bushhammering;
				break;
			case FROST_FLOWERS:
				image = frostFlowers;
				break;
			case SABLINO:
				image = sablino;
				break;
			case STIPPLED:
				image = stippled;
				break;
			case SANDBLASTING:
				break;
			case MANUAL_TRIMMING_STRAIGHT:
				image = manualTrimmingStraight;
				break;
			case MANUAL_TRIMMING_45_DEGREES:
				image = manualTrimming45Degrees;
				break;
			case MACHINE_TRIMMING_CHISELING_STRAIGHT:
				image = machineTrimmingChiselingStraight;
				break;
			case MACHINE_CUT_AGED_STRAIGHT:
				image = machineTrimmingCutAgedStraight;
				break;
			case MACHINE_CUT_AGED_45_DEGREES:
				image = machineTrimmingCutAged45Degrees;
				break;
		}

		return image;
	};

	const closeModal = (shouldSave = true) => {
		if (!shouldSave) {
			props.onClose();

			return;
		}

		let sides;

		if (selectedOperationType === SANDBLASTING) {
			if (selectedSides.length > 1) {
				// Remove all sides
				sides = [];
			} else {
				// Set all sides as selected
				sides = ALL_SIDES;
			}
		} else {
			// For other surface operations it should not be possible to add the operation and select no sides
			if (!selectedSides.length) {
				return;
			}

			sides = selectedSides;
		}

		setOperationWithSides(sides, selectedOperationType, () => props.onClose(true), t(prefix + 'createFailedMessage'));
	};

	const closeConfirmationModal = hasAccepted => {
		if (hasAccepted) {
			setOperationWithSides(
				[],
				selectedOperationType,
				() => props.onClose(true),
				t(prefix + 'surfaceOperationDeletionSuccess'),
				t(prefix + 'surfaceOperationDeletionFailed'),
			);
		}

		setConfirmationModalIsActive(false);
	};

	const onSurfaceClick = surface => {
		if (selectedSides.includes(surface)) {
			setSelectedSides(selectedSides.filter(s => s !== surface));
		} else {
			setSelectedSides([...selectedSides, surface]);
		}
	};

	const renderFooterButtons = () => {
		let secondaryButtonStyle = 'button button--outline';
		let secondaryButtonContent = t(prefix + 'cancelButton');
		let secondaryButtonCy = 'surface-operation-cancelButton';
		let secondaryButtonOnClick = () => closeModal(false);

		if (currentOperation != null && canEdit) {
			secondaryButtonStyle = 'button button--danger';
			secondaryButtonContent = t(prefix + 'deleteButton');
			secondaryButtonCy = 'surface-operation-delete-button';
			secondaryButtonOnClick = () => setConfirmationModalIsActive(true);
		}

		const submitButton = (
			<button
				data-cy="surface-operation-submit-button"
				key={'surfaceOperationSubmitButton'}
				className="button button--primary"
				type="button"
				onClick={() => closeModal()}
				disabled={selectedOperationType === SANDBLASTING && selectedSides.length > 1}
			>
				{t(prefix + 'submitButton')}
			</button>
		);

		let buttons = [
			<button
				data-cy={secondaryButtonCy}
				key={'surfaceOperationCancelButton'}
				type="button"
				className={secondaryButtonStyle}
				onClick={secondaryButtonOnClick}
			>
				{secondaryButtonContent}
			</button>,
		];

		if (canEdit) {
			buttons.unshift(submitButton);
		}

		return buttons;
	};

	const renderMessages = () => {
		const messages = [];

		if (
			[MACHINE_CUT_AGED_STRAIGHT, MACHINE_CUT_AGED_45_DEGREES].includes(selectedOperationType) &&
			selectedSides.some(s => SIDES.includes(s))
		) {
			if (UPRIGHT_PRESETS.concat(PILLARS).includes(currentConfiguration.options.preset)) {
				messages.push(
					<Message
						variant={WARNING}
						dataCy={'surface-operation-manual-trimming-change-warning-upright-presets'}
						content={t(prefix + 'automaticallyManualTrimmingWarningMessageUprightPresets')}
					/>,
				);
			} else {
				messages.push(
					<Message
						variant={WARNING}
						dataCy={'surface-operation-manual-trimming-change-warning'}
						content={t(prefix + 'automaticallyManualTrimmingWarningMessage')}
					/>,
				);
			}
		}

		if (
			selectedOperationType === MACHINE_TRIMMING_CHISELING_STRAIGHT &&
			!CHISELED_OBJECT_TYPES.includes(currentPiece.type) &&
			![PILLARS, LINTELS, BASEBOARDS].includes(currentConfiguration.options.preset)
		) {
			messages.push(
				<Message
					dataCy={'surface-operation-non-chiseled-types-warning'}
					variant={WARNING}
					content={t(prefix + 'formDisabledForNonChiseledTypesMessage')}
				/>,
			);
		}

		if (isSideSelectionDisabled && warningMessage) {
			messages.push(
				<Message variant={WARNING} content={warningMessage} dataCy="surfaceOperation-form-disabled-message" />,
			);
		}

		if (messages.length) {
			return <div className="m-b-3">{messages}</div>;
		}
	};

	return (
		<ModalHolder isActive={props.isActive}>
			<ConfirmationModal
				isActive={confirmationModalIsActive}
				onClose={userHasAccepted => closeConfirmationModal(userHasAccepted)}
				content={t(prefix + 'deleteSurfaceOperationMessage')}
			/>

			<Modal show={props.isActive} onHide={() => closeModal(false)} animation={true}>
				<Modal.Header className="modal-header">
					<Modal.Title className="modal-title">{t(prefix + 'header')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form data-cy="surfaceOperationForm">
						<p>{t(prefix + 'description')}</p>

						<SectionTitle content={t(prefix + 'operationTypeTitle')} />

						<div className="form-group--selectable__holder">
							{selectableOperationTypes.map(operationType => {
								return (
									<SelectableImage
										dataCy={`operation-type-select-${operationType}`}
										key={`surfaceOperationType-${operationType}`}
										image={getImage(operationType)}
										onClick={() => setSelectedOperationType(operationType)}
										name="surfaceOperationName"
										content={t(operationTypePrefix + operationType)}
										selected={selectedOperationType === operationType}
										disabled={!canEdit || currentOperation || disabledOperationTypes.includes(operationType)}
									/>
								);
							})}
						</div>

						{selectedOperationType && (
							<>
								<SectionTitle content={t(prefix + 'surfaceTitle')} />

								{renderMessages()}

								{selectedOperationType === SANDBLASTING ? (
									<p data-cy="sandblasting-description">{t(prefix + 'sandblastingSideDescription')}</p>
								) : (
									selectableSides.map(side => {
										const sideName = getSideNameByPreset(currentConfiguration?.options.preset, side);

										return (
											<CheckboxWithIcon
												key={side}
												dataCy={`surface-operation-checkbox-${side.toLowerCase()}`}
												name={side.toLowerCase() + 'Checkbox'}
												content={t(sidePrefix + sideName)}
												checked={selectedSides.includes(side)}
												disabled={isSideSelectionDisabled || !canEdit || disabledSides.includes(side)}
												onChange={() => onSurfaceClick(side)}
											/>
										);
									})
								)}
							</>
						)}
					</form>
				</Modal.Body>
				<Modal.Footer>{renderFooterButtons()}</Modal.Footer>
			</Modal>
		</ModalHolder>
	);
};

SurfaceOperationsModal.propTypes = {
	isActive: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};
