import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { Address } from '../../classes/models/Address';
import { useForm } from 'react-hook-form';
import { ModalHolder } from './ModalHolder';
import { CountrySelect } from '../forms/selects/CountrySelect';

export const AddressModal = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'modals.addressModal.';
	const validationPrefix = 'validation.';

	const { register, handleSubmit, errors } = useForm();

	const submit = data => {
		let address = Object.assign(new Address(), data);

		if (props.address?.id) address.id = props.address.id;

		props.onClose(address);
	};

	const renderFooterButtons = () => {
		let ctaButtonText = t(prefix + 'submitButton');

		if (props.address) {
			ctaButtonText = t(prefix + 'editButton');
		}

		return [
			<button key={1} className="button button--primary" type="submit" form="addressForm">
				{ctaButtonText}
			</button>,
			<button key={2} type="button" className="button button--outline" onClick={() => props.onClose()}>
				{t(prefix + 'cancelButton')}
			</button>,
		];
	};

	return (
		<ModalHolder isActive={props.isActive}>
			<Modal show={props.isActive} onHide={() => props.onClose()} animation className="modal--prompt">
				<Modal.Header>
					<Modal.Title>{t(prefix + 'title')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form id="addressForm" onSubmit={handleSubmit(submit)}>
						<div className="row">
							<div className="col-6">
								<div className="form-group">
									<label>{t(prefix + 'streetLabel')}</label>
									<input
										defaultValue={props.address?.street}
										ref={register({ required: true, maxLength: 50 })}
										type="text"
										name="street"
										placeholder={t(prefix + 'streetPlaceholder')}
										autoFocus
									/>
									{errors.street?.type === 'required' ? (
										<small className="form-errortext">{t(validationPrefix + 'required')}</small>
									) : null}
									{errors.street?.type === 'maxLength' ? (
										<small className="form-errortext">{t(validationPrefix + 'maxLength', { max: 50 })}</small>
									) : null}
								</div>
							</div>

							<div className="col-6">
								<div className="form-group">
									<label>{t(prefix + 'numberLabel')}</label>
									<input
										defaultValue={props.address?.number}
										ref={register({ required: true, maxLength: 50 })}
										type="text"
										name="number"
										placeholder={t(prefix + 'numberPlaceholder')}
									/>
									{errors.number?.type === 'required' ? (
										<small className="form-errortext">{t(validationPrefix + 'required')}</small>
									) : null}
									{errors.number?.type === 'maxLength' ? (
										<small className="form-errortext">{t(validationPrefix + 'maxLength', { max: 50 })}</small>
									) : null}
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-6">
								<div className="form-group">
									<label>{t(prefix + 'cityLabel')}</label>
									<input
										defaultValue={props.address?.city}
										ref={register({ required: true, maxLength: 50 })}
										type="text"
										name="city"
										placeholder={t(prefix + 'cityPlaceholder')}
									/>
									{errors.city?.type === 'required' ? (
										<small className="form-errortext">{t(validationPrefix + 'required')}</small>
									) : null}
									{errors.city?.type === 'maxLength' ? (
										<small className="form-errortext">{t(validationPrefix + 'maxLength', { max: 50 })}</small>
									) : null}
								</div>
							</div>

							<div className="col-6">
								<div className="form-group">
									<label>{t(prefix + 'postalCodeLabel')}</label>
									<input
										defaultValue={props.address?.postalCode}
										ref={register({ required: true, max: 9999, min: 1000 })}
										type="number"
										name="postalCode"
										placeholder={t(prefix + 'postalCodePlaceholder')}
									/>
									{errors.postalCode?.type === 'required' ? (
										<small className="form-errortext">{t(validationPrefix + 'required')}</small>
									) : null}
									{errors.postalCode?.type === 'min' ? (
										<small className="form-errortext">{t(validationPrefix + 'min', { min: 1000 })}</small>
									) : null}
									{errors.postalCode?.type === 'max' ? (
										<small className="form-errortext">{t(validationPrefix + 'max', { max: 9999 })}</small>
									) : null}
								</div>
							</div>
						</div>

						<CountrySelect register={register} error={errors.country} address={props.address} />
					</form>
				</Modal.Body>
				<Modal.Footer>{renderFooterButtons()}</Modal.Footer>
			</Modal>
		</ModalHolder>
	);
};

AddressModal.propTypes = {
	address: PropTypes.instanceOf(Address),
	isActive: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};
