import * as React from 'react';
import PropTypes from 'prop-types';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { useTranslation } from 'react-i18next';

export const Pagination = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'shared.pagination.';

	const isLastPage = () => {
		return props.paginationSettings.currentPage === props.paginationSettings.lastPage;
	};

	const isFirstPage = () => {
		return props.paginationSettings.currentPage === 1;
	};

	const changePage = page => {
		const pagination = {
			...props.paginationSettings,
			currentPage: page,
		};

		props.changePage(pagination);
	};

	return (
		<div className="pagination">
			<button
				className="button button--primary"
				onClick={() => changePage(1)}
				disabled={isFirstPage()}
				data-cy="firstPage"
			>
				{' << '}
			</button>
			<button
				className="button button--primary"
				onClick={() => changePage(props.paginationSettings.currentPage - 1)}
				disabled={isFirstPage()}
				data-cy="previousPage"
			>
				{' < '}
			</button>
			<span>
				{t(prefix + 'info', {
					index: props.paginationSettings.currentPage,
					total: props.paginationSettings.lastPage,
				})}
			</span>
			<button
				className="button button--primary"
				onClick={() => changePage(props.paginationSettings.currentPage + 1)}
				disabled={isLastPage()}
				data-cy="nextPage"
			>
				{' > '}
			</button>
			<button
				className="button button--primary"
				onClick={() => changePage(props.paginationSettings.lastPage)}
				disabled={isLastPage()}
				data-cy="lastPage"
			>
				{' >> '}
			</button>
		</div>
	);
};

Pagination.propTypes = {
	paginationSettings: PropTypes.object.isRequired,
	changePage: PropTypes.func.isRequired,
};
