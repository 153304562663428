import * as React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { Piece } from '../../../classes/models/Piece';
import { useSelector } from 'react-redux';
import { NumberInput } from '../../forms/inputs/NumberInput';
import { MAX_PIECE_AMOUNT, MIN_PIECE_AMOUNT } from '../../../constants/Values';

export const PieceListItemHeader = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'pages.configurator.configurationPieceList.';

	const canEdit = useSelector(state => state.generalReducer.canEdit);

	const renderDeleteItem = () => {
		if (props.onDeleteClick != null) {
			return (
				<span className="pointer dropdown-item" onClick={() => props.onDeleteClick()}>
					<span className="color--red">{t(prefix + 'deleteButton')}</span>
				</span>
			);
		} else {
			return (
				<span className="dropdown-item disabled">
					<span>{t(prefix + 'deleteButton')}</span>
				</span>
			);
		}
	};

	const renderCollapseButton = () => {
		let iconName;

		if (props.collapsed) {
			iconName = 'keyboard_arrow_down';
		} else {
			iconName = 'keyboard_arrow_up';
		}

		return (
			<span data-cy={`${props.dataCy}-collapse-button`} className="configuration__objects__item__collapse">
				<i className="material-icons">{iconName}</i>
			</span>
		);
	};

	return (
		<header>
			<div onClick={() => props.onSelect()}>
				{renderCollapseButton()}
				<h3 data-cy={`${props.dataCy}-title`} className="pointer">
					{props.piece.name}
					<small>{props.piece.price ? '€' + props.piece.price : '...'}</small>
				</h3>
			</div>
			<div>
				<NumberInput
					value={props.piece.amount}
					update={value => props.updateAmount(value)}
					min={MIN_PIECE_AMOUNT}
					max={MAX_PIECE_AMOUNT}
					disabled={!canEdit}
					label={prefix + 'amountLabel'}
				/>

				{canEdit && (
					<div className="dropdown">
						<button
							data-cy={'piece-list-item-header-actions-dropdown'}
							className="button button--small button--icon dropdown-toggle"
							type="button"
							id="dropdownMenuButton"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
						>
							<i className="material-icons">more_vert</i>
						</button>
						<div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
							<span className="pointer dropdown-item" onClick={() => props.onEditClick()}>
								{t(prefix + 'editButton')}
							</span>
							<span
								className="dropdown-item pointer"
								onClick={() => props.duplicatePiece()}
								data-cy={'piece-list-item-header-actions-dropdown-duplicate'}
							>
								{t(prefix + 'duplicateButton')}
							</span>
							{renderDeleteItem()}
						</div>
					</div>
				)}
			</div>
		</header>
	);
};

PieceListItemHeader.propTypes = {
	piece: PropTypes.instanceOf(Piece).isRequired,
	selected: PropTypes.bool,
	collapsed: PropTypes.bool,
	duplicatePiece: PropTypes.func.isRequired,
	updateAmount: PropTypes.func.isRequired,
	onEditClick: PropTypes.func.isRequired,
	onDeleteClick: PropTypes.func,
	onSelect: PropTypes.func,
	dataCy: PropTypes.string,
};

PieceListItemHeader.defaultProps = {
	selected: false,
};
