import { UPRIGHT_PRESETS } from '../../constants/Presets';
import { BACK, BOTTOM, FRONT, LEFT, RIGHT, TOP } from '../../constants/CameraAngles';
import { Vector3 } from 'three';
import { cloneDeep } from 'lodash';
import { getBoundingBox } from './ThreeHelper';
import { FOV } from '../../constants/Values';

export function getCameraPositionByAspectForObject(aspect, piece, configuration, scene) {
	let position;
	let configurationObject = scene.getObjectByName(configuration.id, true);
	let object = configurationObject ? configurationObject.getObjectByName(piece.id, true) : null;

	if (object) {
		const cameraDistance = getCameraDistanceForPerspectiveCamera(piece);
		object = translateObjectByAspect(object, aspect, configuration.options.preset, cameraDistance);

		position = object.position;

		// Upright presets are rotated 90 degrees in de configurator just before they are rendered
		// but at this time there are still in the default rotation
		if (UPRIGHT_PRESETS.includes(configuration.options.preset)) {
			position = new Vector3(position.x, position.z, position.y);
		}
	}

	return position ?? new Vector3();
}

export function getPositionToPointCameraAt(piece, preset) {
	let position = cloneDeep(piece.position);

	// Upright presets are rotated 90 degrees in de configurator just before they are rendered
	// but at this time there are still in the default rotation
	if (UPRIGHT_PRESETS.includes(preset)) {
		position = new Vector3(position.x, position.z, position.y);
	}

	return position;
}

export function getCameraDistanceForPerspectiveCamera(object) {
	const offset = object.dimensions.length / 150;

	const boundingBox = getBoundingBox([object]);

	const size = new Vector3(boundingBox.length, boundingBox.height, boundingBox.width);

	// get the max side of the bounding box (fits to width OR height as needed )
	const maxDim = Math.max(size.x, size.y, size.z);
	const fov = FOV * (Math.PI / 180);
	let cameraZ = Math.abs((maxDim / 4) * Math.tan(fov * 2));

	cameraZ *= offset; // zoom out a little so that objects don't fill the screen

	return cameraZ;
}

function translateObjectByAspect(object, aspect, preset, cameraDistance) {
	const offset = 100;
	cameraDistance += offset;
	const isUpright = UPRIGHT_PRESETS.includes(preset);
	object = object.clone(true);

	switch (aspect) {
		case TOP:
			if (isUpright) {
				object.translateZ(cameraDistance);
			} else {
				object.translateY(cameraDistance);
			}
			break;
		case BOTTOM:
			if (isUpright) {
				object.translateZ(-cameraDistance);
			} else {
				object.translateY(-cameraDistance);
			}
			break;
		case LEFT:
			object.translateX(-cameraDistance);
			break;
		case RIGHT:
			object.translateX(cameraDistance);
			break;
		case FRONT:
			if (isUpright) {
				object.translateY(cameraDistance);
			} else {
				object.translateZ(cameraDistance);
			}
			break;
		case BACK:
			if (isUpright) {
				object.translateY(-cameraDistance);
			} else {
				object.translateZ(-cameraDistance);
			}
			break;
		default:
			object.translateY(cameraDistance);
			break;
	}

	return object;
}
