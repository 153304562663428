import * as React from 'react';
import PropTypes from 'prop-types';
import { ActionsDropdownItem } from '../../dropdowns/items/ActionsDropdownItem';
import { DANGER } from '../../../constants/Variants';
import { ActionsDropdown } from '../../dropdowns/ActionsDropdown';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';

export const ConfigurationActionsDropdown = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'lists.items.sideBarListItem.';

	return (
		<ActionsDropdown dataCy="configuration-actions-dropdown-button">
			<ActionsDropdownItem
				dataCy="configuration-actions-dropdown-duplicate-button"
				content={t(prefix + 'duplicate')}
				onClick={() => props.onDuplicate()}
			/>
			<ActionsDropdownItem
				dataCy="configuration-actions-dropdown-edit-button"
				content={t(prefix + 'edit')}
				onClick={() => props.onUpdate()}
			/>
			<ActionsDropdownItem
				dataCy="configuration-actions-dropdown-delete-button"
				content={t(prefix + 'delete')}
				variant={DANGER}
				onClick={() => props.onDelete()}
			/>
		</ActionsDropdown>
	);
};

ConfigurationActionsDropdown.propTypes = {
	onDuplicate: PropTypes.func.isRequired,
	onUpdate: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
};
