import { parseToCommaSeparated } from '../helpers/StringHelper';
import { LocalStorageHelper } from '../helpers/LocalStorageHelper';

export class OutdoorArticle {
	constructor(id) {
		this.id = id;
		this.description = { nl: '', fr: '', en: '' };
	}

	get price() {
		return parseToCommaSeparated(this.priceExclusive, 2);
	}

	getPalletAmount() {
		let palletAmount;

		if (this.unit === 'M2') {
			palletAmount = parseToCommaSeparated(this.palletSurfaceArea, 2);
		} else {
			palletAmount = this.palletAmount;
		}

		return palletAmount;
	}

	getPalletWeight() {
		const amount = this.unit === 'M2' ? this.palletSurfaceArea : this.palletAmount;

		return parseToCommaSeparated(amount * this.weightAmount, 2);
	}

	getDescription(locale = null) {
		if (!locale) {
			locale = LocalStorageHelper.getLanguage(true);
		}

		return this.description[locale];
	}

	static parse(data) {
		let article = new OutdoorArticle(data.id);

		article.name = data.name;
		article.number = data.number;
		article.height = data.height;
		article.length = data.length;
		article.width = data.width;
		article.category1 = data.category1;
		article.category1 = data.category2;
		article.category1 = data.category3;
		article.palletAmount = data.pallet_amount;
		article.palletSurfaceArea = data.pallet_surface_area;
		article.priceExclusive = data.price_exclusive;
		article.unit = data.unit;
		article.weightAmount = data.weight_amount;
		article.weightUnit = data.weight_unit;
		article.description = data.description
			? { nl: data.description.nl, fr: data.description.fr, en: data.description.en }
			: null;

		return article;
	}
}
