import { DEVELOPMENT } from '../../constants/Values';

export function getBaseUrl(domain) {
	domain = domain.replace('www.', '');
	const environment = process.env.NODE_ENV;
	let sslPrefix = environment === DEVELOPMENT ? 'http://' : 'https://';

	if (environment === DEVELOPMENT) {
		return `${sslPrefix}${process.env.REACT_APP_BACK_END_URL}/api/`;
	} else {
		const isDemoEnvironment = domain.includes('demo');
		const isTestingEnvironment = domain.includes('testing');
		let apiPrefix;

		if (isDemoEnvironment) apiPrefix = 'demoapi';
		else if (isTestingEnvironment) apiPrefix = 'testingapi';
		else apiPrefix = 'api';

		if (isDemoEnvironment) domain = domain.replace('demo.', '');
		if (isTestingEnvironment) domain = domain.replace('testing.', '');

		return `${sslPrefix}${apiPrefix}.${domain}/api/`;
	}
}
