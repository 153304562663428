import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { ModalHolder } from './ModalHolder';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { Log } from '../../classes/models/Log';
import { EmptyPage } from '../empty/EmptyPage';
import { TranslateLogService } from '../../classes/services/TranslateLogService';

export const LogsModal = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'modals.logsModal.';
	const actionsPrefix = 'modals.logsModal.actions.';
	const tableHeaderPrefix = 'modals.logsModal.tableHeaders.';

	const translateLogService = new TranslateLogService();

	const translateProperties = log => {
		const properties = log.properties.changesToString();
		const arrowSeparator = '->';
		let translatedProperties = [];

		const explodedProperties = properties.split(',');

		explodedProperties.forEach(p => {
			const explodedProperty = p.split(': ').map(v => v.trim());
			let values;

			if (explodedProperty.length === 2) {
				if (explodedProperty[1].includes(arrowSeparator)) {
					values = explodedProperty[1].split(arrowSeparator);
				} else {
					values = [explodedProperty[1]];
				}
			}

			const translation = translateLogService.getPropertyTranslation(log.subjectType, explodedProperty[0], values);

			if (translation) {
				translatedProperties.push(translation);
			}
		});

		return translatedProperties.join(', ');
	};

	return (
		<ModalHolder isActive={props.isActive}>
			<Modal show={props.isActive} onHide={() => props.closeModal()} animation={true} className="modal">
				<Modal.Header>
					<Modal.Title>{t(prefix + 'header')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{!!props.logs.length && (
						<table className="table--bordered table--striped">
							<thead>
								<tr>
									<th>{t(tableHeaderPrefix + 'model')}</th>
									<th>{t(tableHeaderPrefix + 'action')}</th>
									<th>{t(tableHeaderPrefix + 'changes')}</th>
									<th>{t(tableHeaderPrefix + 'causer')}</th>
									<th>{t(tableHeaderPrefix + 'timestamp')}</th>
								</tr>
							</thead>
							<tbody>
								{props.logs.map(log => (
									<tr key={log.id}>
										<td>{translateLogService.getModelTranslationKey(log.subjectType)}</td>
										<td>{t(actionsPrefix + log.description)}</td>
										<td>{translateProperties(log)}</td>
										<td>{log.causer.getFullName()}</td>
										<td>{log.createdAt.format('DD-MM-YYYY HH:mm')}</td>
									</tr>
								))}
							</tbody>
						</table>
					)}

					{!props.logs.length && <EmptyPage title={t(prefix + 'emptyMessage')} />}
				</Modal.Body>
				<Modal.Footer>
					<button type="button" className="button" onClick={() => props.closeModal()}>
						{t(prefix + 'closeButton')}
					</button>
				</Modal.Footer>
			</Modal>
		</ModalHolder>
	);
};

LogsModal.propTypes = {
	isActive: PropTypes.bool.isRequired,
	closeModal: PropTypes.func.isRequired,
	logs: PropTypes.arrayOf(PropTypes.instanceOf(Log)).isRequired,
};
