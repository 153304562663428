import { Service } from './Service';
import { captureMessage } from '@sentry/react';
import { Parser } from '../helpers/Parser';
import moment from 'moment';

export class DeliveryTypeService extends Service {
	async getAll(withDeliveryDates = false) {
		const data = {
			withDeliveryDates: withDeliveryDates,
		};

		return await this.authenticatedRequest('get', 'deliverytypes', data).then(response => {
			if (response.data.success) {
				return response.data.data.map(deliveryTypeData => {
					return Parser.parseDeliveryType(deliveryTypeData);
				});
			} else {
				captureMessage(response.data.message);

				return [];
			}
		});
	}

	async getDeliveryDatesByType(deliveryTypeId = null) {
		const url = deliveryTypeId ? `deliverytypes/deliverydates/${deliveryTypeId}` : 'deliverytypes/deliverydates';

		return await this.authenticatedRequest('get', url).then(response => {
			if (response.data.success) {
				return response.data.data.map(deliveryDateData => {
					return Parser.parseDeliveryDate(deliveryDateData);
				});
			} else {
				captureMessage(response.data.message);

				return [];
			}
		});
	}

	async addDate(typeId, date) {
		const data = {
			date: moment(date).format('YYYY-MM-DD HH:mm:ss'),
		};

		return await this.authenticatedRequest('post', 'deliverytypes/' + typeId + '/deliverydates', data).then(
			response => {
				if (response.data.success) {
					return Parser.parseDeliveryDate(response.data.data);
				} else {
					captureMessage(response.data.message);

					return null;
				}
			},
		);
	}

	async updateDate(typeId, dateId, newDate, status) {
		const data = {
			date: moment(newDate).format('YYYY-MM-DD HH:mm:ss'),
			status: status,
		};

		return await this.authenticatedRequest('put', 'deliverytypes/' + typeId + '/deliverydates/' + dateId, data).then(
			response => {
				if (response.data.success) {
					return Parser.parseDeliveryDate(response.data.data);
				} else {
					captureMessage(response.data.message);

					return null;
				}
			},
		);
	}

	async deleteDate(typeId, dateId) {
		return await this.authenticatedRequest('delete', 'deliverytypes/' + typeId + '/deliverydates/' + dateId).then(
			response => {
				if (response.data.success) {
					return true;
				} else {
					captureMessage(response.data.message);

					return false;
				}
			},
		);
	}
}
