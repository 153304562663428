import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PieceListItem } from './PieceListItem';
import { SingleInputModal } from '../../modals/SingleInputModal';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { ConfirmationModal } from '../../modals/ConfirmationModal';
import { PieceService } from '../../../classes/services/PieceService';
import { useDispatch, useSelector } from 'react-redux';
import {
	addAlertMessage,
	setActiveInfoModal,
	setShouldUpdatePrice,
	setWindowIsLoading,
} from '../../../actions/GeneralActions';
import { DANGER, SUCCESS } from '../../../constants/Variants';
import { setCurrentConfiguration, setCurrentOffer, setCurrentPiece } from '../../../actions/OfferActions';
import { Parser } from '../../../classes/helpers/Parser';
import { CoupeOperation } from '../../../classes/models/CoupeOperation';
import _ from 'lodash';
import { AddPieceModal } from '../../modals/AddPieceModal';
import ConfigurableReducerHelper from '../../../classes/helpers/ConfigurableReducerHelper';
import * as Scroll from 'react-scroll';
import { dividePieceInParts } from '../../../classes/helpers/PieceDivisionHelper';
import { COUPE, PROFILE } from '../../../constants/OperationTypes';
import { BACK } from '../../../constants/ObjectSides';
import { COMPLETELY } from '../../../constants/FinishedSideStates';
import { MAX_PIECE_AMOUNT, MIN_PIECE_AMOUNT } from '../../../constants/Values';
import { OFFER } from '../../../constants/ConfigurableTypes';
import { setCurrentOrder } from '../../../actions/OrderActions';

export const PieceList = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'pages.configurator.configurationPieceList.';

	const [editNameModalIsActive, setEditNameModalIsActive] = useState(false);
	const [deleteModalIsActive, setDeleteModalIsActive] = useState(false);
	const [selectedPiece, setSelectedPiece] = useState(null);
	const [newPieceLoading, setNewPieceLoading] = useState(false);
	const [addPieceModalIsActive, setAddPieceModalIsActive] = useState(false);

	const { currentOffer, currentConfiguration, currentPiece } = useSelector(state => state.offerReducer);
	const { currentOrder } = useSelector(state => state.orderReducer);
	const { windowIsLoading, canEdit } = useSelector(state => state.generalReducer);
	const { configurableType } = useSelector(state => state.configuratorReducer);

	const dispatch = useDispatch();

	const scroll = Scroll.animateScroll;
	const scrollRef = createRef();

	const pieceService = new PieceService();
	const configurableReducerHelper = new ConfigurableReducerHelper();

	useEffect(() => {
		scroll.scrollTo(0, scrollRef.current?.offsetBottom);
	}, [currentPiece]);

	useEffect(() => {
		if (currentConfiguration?.pieces.length === 0) {
			setAddPieceModalIsActive(true);
		}
	}, [currentConfiguration]);

	//region Modal management
	const openEditNameModal = piece => {
		setSelectedPiece(piece);
		setEditNameModalIsActive(true);
	};

	const closeEditNameModal = name => {
		if (name === undefined) {
			setEditNameModalIsActive(false);
			return;
		}

		if (name !== selectedPiece.name) {
			dispatch(setWindowIsLoading(true));

			pieceService
				.updateName(currentConfiguration.id, selectedPiece.id, name)
				.then(response => {
					if (response.success) {
						dispatch(addAlertMessage(SUCCESS, t(prefix + 'editNameSuccess')));

						selectedPiece.name = name;
						configurableReducerHelper.updatePiece(selectedPiece, currentConfiguration);

						props.refreshCanvas();
					} else {
						dispatch(addAlertMessage(DANGER, t(prefix + 'editNameFailed')));
					}
				})
				.catch(() => {
					dispatch(addAlertMessage(DANGER, t(prefix + 'editNameFailed')));
				})
				.finally(() => dispatch(setWindowIsLoading(false)));
		}
		setEditNameModalIsActive(false);
		setSelectedPiece(null);
	};

	const updateCurrentPiece = piece => {
		if (currentPiece?.id === piece.id) {
			dispatch(setCurrentPiece(null));
		} else {
			dispatch(setCurrentPiece(piece));
		}
	};

	const openDeleteModal = piece => {
		setSelectedPiece(piece);
		setDeleteModalIsActive(true);
	};

	const createPiece = (name, length, width, type, profileData) => {
		dispatch(setWindowIsLoading(true));
		setNewPieceLoading(true);

		let newPiece = Parser.instantiateObjectByType(type);
		newPiece.name = name;
		newPiece.isAnchor = true;
		newPiece.type = type;

		newPiece.dimensions.length = length;
		newPiece.dimensions.width = width;
		newPiece.dimensions.height = currentConfiguration.options.height;

		newPiece.parts = dividePieceInParts(
			newPiece.dimensions.length,
			newPiece.dimensions.width,
			newPiece.dimensions.height,
			newPiece.type,
			currentConfiguration.options.isConfiguredForStock() ? 1 : null,
		);

		let operationsToCreate = [];

		if (profileData.height && profileData.depth) {
			operationsToCreate = [
				{
					sides: [{ name: BACK, status: COMPLETELY }],
					type: PROFILE,
					height: profileData.height,
					width: profileData.depth,
				},
			];
		}

		createPieceWithOperations(newPiece, operationsToCreate);
	};

	const createPieceWithOperations = (pieceToCreate, operationsToCreate) => {
		pieceService
			.create(currentConfiguration, pieceToCreate, operationsToCreate)
			.then(response => {
				configurableReducerHelper.addPiece(response.data);
				dispatch(setShouldUpdatePrice(true));

				props.refreshCanvas();
				scrollToBottom();
			})
			.catch(error => {
				dispatch(addAlertMessage(DANGER, t(prefix + 'addPieceFailed')));
				throw error;
			})
			.finally(() => {
				dispatch(setWindowIsLoading(false));
				setNewPieceLoading(false);
			});
	};

	const closeDeleteModal = confirmed => {
		if (confirmed) {
			dispatch(setWindowIsLoading(true));

			pieceService
				.delete(currentConfiguration.id, selectedPiece.id)
				.then(response => {
					if (response.success) {
						dispatch(addAlertMessage(SUCCESS, t(prefix + 'deletePieceSuccess')));

						let coupes = selectedPiece.getOperationsByType(COUPE);

						let tempConfiguration = currentConfiguration;

						// Find the piece that was deleted
						tempConfiguration.pieces.forEach((deletedPiece, index) => {
							if (deletedPiece.id === selectedPiece.id) {
								// If the piece was connected to other pieces - remove that link
								deletedPiece.connectedObjects.forEach(connectedPiece => {
									connectedPiece = tempConfiguration.pieces.find(piece => piece.id === connectedPiece.id);

									connectedPiece.connectedObjects = connectedPiece.connectedObjects.filter(
										piece => piece.id !== deletedPiece.id,
									);

									tempConfiguration.pieces.forEach(piece => {
										if (piece.id === connectedPiece.id) piece = connectedPiece;
									});
								});

								tempConfiguration.pieces.splice(index, 1);
							}
							deletedPiece.operations.forEach(operation => {
								if (operation instanceof CoupeOperation) {
									coupes.forEach(coupe => {
										if (coupe.id === operation.connectedCoupe) {
											operation.connectedCoupe = null;
										}
									});
								}
							});
						});

						configurableReducerHelper.updateConfiguration(tempConfiguration);

						// Set another piece as active, so that something is displayed on the canvas
						if (tempConfiguration.pieces.length > 0) {
							let nextDisplayedPiece =
								selectedPiece.connectedObjects.length > 0
									? tempConfiguration.pieces.find(p => p.id === selectedPiece.connectedObjects[0].id)
									: null;

							if (nextDisplayedPiece) {
								dispatch(setCurrentPiece(nextDisplayedPiece));
							} else {
								dispatch(setCurrentPiece(tempConfiguration.pieces[0]));
							}
						}

						dispatch(configurableType === OFFER ? setCurrentOffer(currentOffer) : setCurrentOrder(currentOrder));
						dispatch(setCurrentConfiguration(tempConfiguration));
						dispatch(setShouldUpdatePrice(true));

						props.refreshCanvas();
					} else {
						dispatch(addAlertMessage(DANGER, t(prefix + 'deletePieceFailed')));
					}
				})
				.catch(() => {
					dispatch(addAlertMessage(DANGER, t(prefix + 'deletePieceFailed')));
				})
				.finally(() => dispatch(setWindowIsLoading(false)));
		}

		setDeleteModalIsActive(false);
		setSelectedPiece(null);
	};
	//endregion

	const scrollToBottom = () => {
		scroll.scrollMore(500, { containerId: 'scrollContainer', smooth: true, duration: 500, offset: 200 });
	};

	const updatePiece = pieceToUpdate => {
		dispatch(setWindowIsLoading(true));

		pieceService
			.update(currentConfiguration, pieceToUpdate)
			.then(response => {
				if (response.success) {
					const newPiece = response.data;

					let tempConfiguration = _.cloneDeep(currentConfiguration);
					let tempOffer = configurableType === OFFER ? _.cloneDeep(currentOffer) : _.cloneDeep(currentOrder);

					tempConfiguration.pieces = tempConfiguration.pieces.map(piece => {
						if (piece.id === newPiece.id) piece = newPiece;

						return piece;
					});

					tempOffer.configurations = tempOffer.configurations.map(configuration => {
						if (tempConfiguration.id === configuration.id) configuration = tempConfiguration;

						return configuration;
					});

					dispatch(configurableType === OFFER ? setCurrentOffer(tempOffer) : setCurrentOrder(tempOffer));
					dispatch(setCurrentConfiguration(tempConfiguration));
					dispatch(setShouldUpdatePrice(true));

					props.refreshCanvas();
				} else {
					dispatch(addAlertMessage(DANGER, t(prefix + 'updatePieceFailed')));
				}
			})
			.catch(() => {
				dispatch(addAlertMessage(DANGER, t(prefix + 'updatePieceFailed')));
			})
			.finally(() => dispatch(setWindowIsLoading(false)));
	};

	const updatePieceAmount = (amount, pieceToUpdate) => {
		if (pieceToUpdate.amount === amount) return;
		if (amount < MIN_PIECE_AMOUNT || amount > MAX_PIECE_AMOUNT) {
			dispatch(
				setActiveInfoModal(
					true,
					t(prefix + 'invalidPieceAmountMessage', {
						minAmount: MIN_PIECE_AMOUNT,
						maxAmount: MAX_PIECE_AMOUNT,
					}),
				),
			);
			return;
		}
		amount = parseInt(amount);

		if (isNaN(amount)) return;

		pieceService.updateAmount(currentConfiguration.id, pieceToUpdate.id, amount).then(response => {
			if (response.success) {
				pieceToUpdate.amount = amount;
				configurableReducerHelper.updatePiece(pieceToUpdate, currentConfiguration);

				dispatch(setCurrentConfiguration(currentConfiguration));
				dispatch(setShouldUpdatePrice(true));
			} else {
				dispatch(addAlertMessage(DANGER, t(prefix + 'updateAmountFailed')));
			}
		});
	};

	const duplicatePiece = piece => {
		pieceService.duplicate(currentConfiguration, piece.id).then(response => {
			if (response.success) {
				configurableReducerHelper.addPiece(response.data);
				dispatch(setShouldUpdatePrice(true));
				props.refreshCanvas();
			}
		});
	};

	const closeAddPieceModal = fields => {
		if (fields) {
			const { name, length, width, type, profileDepth, profileHeight } = fields;

			if (length && width) {
				createPiece(name, length, width, type, { depth: profileDepth, height: profileHeight });
			}
		}

		setAddPieceModalIsActive(false);
	};

	const renderPieces = () => {
		return props.pieces?.map(piece => {
			return (
				<PieceListItem
					dataCy={`piece${piece.id}`}
					key={piece.id}
					reference={currentPiece?.id === piece.id ? scrollRef : null}
					piece={piece}
					updatePiece={pieceToUpdate => updatePiece(pieceToUpdate)}
					updateAmount={amount => updatePieceAmount(amount, piece)}
					duplicatePiece={() => duplicatePiece(piece)}
					onClick={pieceToUpdate => updateCurrentPiece(pieceToUpdate)}
					onEditNameClick={() => openEditNameModal(piece)}
					onDeleteClick={() => openDeleteModal(piece)}
				/>
			);
		});
	};

	return (
		<div id="scrollContainer" data-cy={'configurator-piece-list'} className="configuration__objects">
			<SingleInputModal
				isActive={editNameModalIsActive}
				onClose={name => closeEditNameModal(name)}
				content={t(prefix + 'editNameModal.content')}
				header={t(prefix + 'editNameModal.header')}
				value={selectedPiece?.name}
			/>

			<ConfirmationModal
				isActive={deleteModalIsActive}
				onClose={confirmed => closeDeleteModal(confirmed)}
				content={t(prefix + 'deleteModalContent')}
			/>

			<AddPieceModal isActive={addPieceModalIsActive} onClose={fields => closeAddPieceModal(fields)} />

			{renderPieces()}

			{!!(!windowIsLoading && currentConfiguration && canEdit) && (
				<div className="d-flex flex-column">
					<button
						data-cy="pieceList-addPieceButton"
						className="button button--primary button--icon-before m-b-2"
						type="button"
						onClick={() => setAddPieceModalIsActive(true)}
						disabled={newPieceLoading}
					>
						<i className="material-icons">add</i> {t(prefix + 'addPieceButton')}
					</button>
				</div>
			)}
		</div>
	);
};

PieceList.propTypes = {
	pieces: PropTypes.array.isRequired,
	refreshCanvas: PropTypes.func.isRequired,
};

PieceList.defaultProps = {
	pieces: [],
};
