import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { ModalHolder } from './ModalHolder';

export function InfoModal(props) {
	const prefix = 'modals.infoModal.';
	const { t } = useTranslation(TRANSLATION_NAMESPACE);

	return (
		<ModalHolder isActive={props.isActive}>
			<Modal show={props.isActive} onHide={props.onClose} animation={true} className="modal--prompt">
				<Modal.Header>
					<Modal.Title>{t(prefix + 'header')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>{props.content}</p>
				</Modal.Body>
				<Modal.Footer>
					<div className="button button-blue" onClick={() => props.onClose(true)}>
						{t(prefix + 'closeButton')}
					</div>
				</Modal.Footer>
			</Modal>
		</ModalHolder>
	);
}

InfoModal.propTypes = {
	header: PropTypes.string.isRequired,
	content: PropTypes.string.isRequired,
	isActive: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};

InfoModal.defaultProps = {
	header: '',
	content: '',
	isActive: false,
	onClose: null,
};
