import { User } from './User';
import moment from 'moment';

export class Log {
	constructor() {
		this.id = 0;
		this.name = '';
		this.description = '';
		this.subjectType = '';
		this.subjectId = 0;
		this.causer = new User();
		this.properties = {};
		this.createdAt = moment();
	}
}
