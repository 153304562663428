import {
	CORNER_CUTOUT,
	COUPE,
	COUPE_OVER_LENGTH,
	DRILL_HOLE,
	GLUED_CUSHION,
	NOTCH,
	RECTANGULAR_CUT_OUT,
	ROUNDED_CORNER,
} from '../../../constants/OperationTypes';
import { getPointsForNotch } from './operations/NotchMeasurementLineHelper';
import { getPointsForCoupeOverWidth } from './operations/CoupeMeasurementLineHelper';
import { Vector3 } from 'three';
import MeasurementPoints from '../../models/MeasurementPoints';
import { BACK, FRONT, LEFT, RIGHT } from '../../../constants/ObjectSides';
import {
	DISTANCE_TO_SHORTEN,
	HORIZONTAL_OFFSET_FROM_PIECE,
	VERTICAL_OFFSET_FROM_PIECE,
} from '../../../constants/MeasurementLineConstants';
import { getPointsForCornerCutout } from './operations/CornerCutoutMeasurementLineHelper';
import { getPointsForDrillHole } from './operations/DrillHoleMeasurementLineHelper';
import { getPointsForRoundedCorner } from './operations/RoundedCornerMeasurementLineHelper';
import { getPointsForGluedCushionsOnSurface } from './operations/GluedCushionSurfaceMeasurementLineHelper';
import { getPointsForCoupeOverLength } from './operations/CoupeOverLengthMeasurementLineHelper';
import { getPointsForRectangularCutOut } from './operations/RectangularCutOutMeasurementLineHelper';
import { getLineLength } from './LineHelper';

export function getPointsForOperation(piece, pieceType, operation, sideOfLine) {
	let points;

	switch (operation.type) {
		case COUPE:
			points = getPointsForCoupeOverWidth(piece.dimensions, operation, sideOfLine);
			break;
		case NOTCH:
			points = getPointsForNotch(piece.dimensions, operation);
			break;
		case CORNER_CUTOUT:
			points = getPointsForCornerCutout(pieceType, piece.dimensions, operation);
			break;
		case DRILL_HOLE:
			points = getPointsForDrillHole(piece, operation);
			break;
		case ROUNDED_CORNER:
			points = getPointsForRoundedCorner(piece.dimensions, operation);
			break;
		case GLUED_CUSHION:
			points = getPointsForGluedCushionsOnSurface(piece.dimensions, operation);
			break;
		case COUPE_OVER_LENGTH:
			points = getPointsForCoupeOverLength(piece.dimensions, operation);
			break;
		case RECTANGULAR_CUT_OUT:
			points = getPointsForRectangularCutOut(piece, operation);
			break;
		default:
			points = {
				horizontal: null,
				vertical: null,
			};
	}

	return points;
}

export function getPointsForEmptySpacesBetweenOperations(piece, horizontalPoints, sideOfLine) {
	let pointsBetweenOperations = [];
	const pieceDimensions = piece.dimensions;

	const linePosition = {
		xPos: getXPosForSide(pieceDimensions, sideOfLine),
		yPos: pieceDimensions.height / 2,
		zPos: getZPosForSide(pieceDimensions, sideOfLine),
	};

	for (let i = 0; i < horizontalPoints.length; i++) {
		// Set the points for the first line
		if (i === 0) {
			const firstPoint = getFirstPointOfLine(pieceDimensions, horizontalPoints[i], linePosition, sideOfLine);
			if (firstPoint) pointsBetweenOperations.push(firstPoint);
		}

		// Set the points for the last line
		if (i === horizontalPoints.length - 1) {
			const lastPoint = getLastPointOfLine(pieceDimensions, horizontalPoints[i], linePosition, sideOfLine);
			if (lastPoint) pointsBetweenOperations.push(lastPoint);
		}

		// Set the points for the lines in between
		if (i !== horizontalPoints.length - 1) {
			const point = getPointInBetweenTwoPoints(horizontalPoints[i], horizontalPoints[i + 1], linePosition, sideOfLine);

			pointsBetweenOperations.push(point);
		}
	}

	return pointsBetweenOperations;
}

export function getXPosForSide(pieceDimensions, side) {
	let xPos;

	switch (side) {
		case FRONT:
			xPos = 0;
			break;
		case LEFT:
			xPos = pieceDimensions.length / -2;
			break;
		case BACK:
			xPos = 0;
			break;
		case RIGHT:
			xPos = pieceDimensions.length / 2;
			break;
	}

	return xPos;
}

export function getYPosForSide(pieceDimensions) {
	return pieceDimensions.height / 2;
}

export function getZPosForSide(pieceDimensions, side) {
	let zPos;

	switch (side) {
		case FRONT:
			zPos = pieceDimensions.width / 2;
			break;
		case LEFT:
			zPos = 0;
			break;
		case BACK:
			zPos = pieceDimensions.width / -2;
			break;
		case RIGHT:
			break;
	}

	return zPos;
}

// Checks if the first point starts at the beginning of the line, if not there has to be a new set of points
function firstPointIsBeginningOfLine(pieceDimensions, firstHorizontalPoint, sideOfLine) {
	let isBeginning = false;

	switch (sideOfLine) {
		case FRONT:
			isBeginning = firstHorizontalPoint.pointA.x === pieceDimensions.length / -2;
			break;
		case LEFT:
			isBeginning = firstHorizontalPoint.pointA.z === pieceDimensions.width / -2;
			break;
		case BACK:
			isBeginning = firstHorizontalPoint.pointA.x === pieceDimensions.length / 2;
			break;
		case RIGHT:
			isBeginning = firstHorizontalPoint.pointA.z === pieceDimensions.width / 2;
			break;
	}

	return isBeginning;
}

// Checks if the last point is at the end of the line, if not there has to be a new line
function lastPointIsEndOfLine(pieceDimensions, lastHorizontalPoint, sideOfLine) {
	let isEnding = false;

	switch (sideOfLine) {
		case FRONT:
			isEnding = lastHorizontalPoint.pointB.x === pieceDimensions.length / 2;
			break;
		case LEFT:
			isEnding = lastHorizontalPoint.pointB.z === pieceDimensions.width / 2;
			break;
		case BACK:
			isEnding = lastHorizontalPoint.pointB.x === pieceDimensions.length / -2;
			break;
		case RIGHT:
			isEnding = lastHorizontalPoint.pointB.z === pieceDimensions.width / -2;
			break;
	}

	return isEnding;
}

function getFirstPointOfLine(pieceDimensions, firstHorizontalPoint, { xPos, yPos, zPos }, sideOfLine) {
	if (firstPointIsBeginningOfLine(pieceDimensions, firstHorizontalPoint, sideOfLine)) return null;

	let xPosA, xPosB;
	let zPosA, zPosB;

	switch (sideOfLine) {
		case FRONT:
			xPosA = pieceDimensions.length / -2;
			xPosB = firstHorizontalPoint.pointA.x;
			break;
		case BACK:
			xPosA = pieceDimensions.length / 2;
			xPosB = firstHorizontalPoint.pointA.x;
			break;
		case LEFT:
			zPosA = pieceDimensions.width / -2;
			zPosB = firstHorizontalPoint.pointA.z;
			break;
		case RIGHT:
			zPosA = pieceDimensions.width / 2;
			zPosB = firstHorizontalPoint.pointA.z;
			break;
	}

	const pointA = new Vector3(xPosA ?? xPos, yPos, zPosA ?? zPos);

	const pointB = new Vector3(xPosB ?? xPos, yPos, zPosB ?? zPos);

	return new MeasurementPoints(pointA, pointB);
}

function getLastPointOfLine(pieceDimensions, lastHorizontalPoint, { xPos, yPos, zPos }, sideOfLine) {
	if (lastPointIsEndOfLine(pieceDimensions, lastHorizontalPoint, sideOfLine)) return null;

	let xPosA, xPosB;
	let zPosA, zPosB;

	switch (sideOfLine) {
		case FRONT:
			xPosA = lastHorizontalPoint.pointB.x;
			xPosB = pieceDimensions.length / 2;
			break;
		case BACK:
			xPosA = lastHorizontalPoint.pointB.x;
			xPosB = pieceDimensions.length / -2;
			break;
		case LEFT:
			zPosA = lastHorizontalPoint.pointB.z;
			zPosB = pieceDimensions.width / 2;
			break;
		case RIGHT:
			zPosA = lastHorizontalPoint.pointB.z;
			zPosB = pieceDimensions.width / -2;
			break;
	}

	const pointA = new Vector3(xPosA ?? xPos, yPos, zPosA ?? zPos);

	const pointB = new Vector3(xPosB ?? xPos, yPos, zPosB ?? zPos);

	return new MeasurementPoints(pointA, pointB);
}

function getPointInBetweenTwoPoints(horizontalPointA, horizontalPointB, { xPos, yPos, zPos }, sideOfLine) {
	let xPosA, xPosB;
	let zPosA, zPosB;

	switch (sideOfLine) {
		case FRONT:
		case BACK:
			xPosA = horizontalPointA.pointB.x;
			xPosB = horizontalPointB.pointA.x;
			break;
		case LEFT:
			zPosA = horizontalPointA.pointB.z;
			zPosB = horizontalPointB.pointA.z;
			break;
		case RIGHT:
			zPosA = horizontalPointA.pointB.z;
			zPosB = horizontalPointB.pointA.z;
			break;
	}

	const pointA = new Vector3(xPosA ?? xPos, yPos, zPosA ?? zPos);

	const pointB = new Vector3(xPosB ?? xPos, yPos, zPosB ?? zPos);

	return new MeasurementPoints(pointA, pointB);
}

export function applyOffsetToPoints(horizontalPoints, verticalPoints, sideOfLine) {
	if (horizontalPoints.length) {
		horizontalPoints = applyVerticalOffsetToHorizontalPoints(horizontalPoints, sideOfLine);
		horizontalPoints = shortenDistanceBetweenHorizontalPoints(horizontalPoints, sideOfLine);
	}

	if (verticalPoints.length) {
		verticalPoints = applyHorizontalOffsetToVerticalPoints(verticalPoints, sideOfLine);
	}

	return {
		horizontal: horizontalPoints,
		vertical: verticalPoints,
	};
}

export function getMiddlePointOfTwoPoints(pointA, pointB) {
	const getMiddleValue = (a, b) => {
		const difference = Math.abs(a - b);
		return difference / 2;
	};

	let xPos, yPos, zPos;

	if (pointA.x !== pointB.x) {
		yPos = pointA.y;
		zPos = pointA.z;

		const middleValue = getMiddleValue(pointA.x, pointB.x);

		if (middleValue === 0) {
			xPos = 0;
		} else if (pointA.x > pointB.x) {
			xPos = pointA.x - middleValue;
		} else {
			xPos = pointA.x + middleValue;
		}
	} else if (pointA.y !== pointB.y) {
		xPos = pointA.x;
		zPos = pointA.z;

		const middleValue = getMiddleValue(pointA.y, pointB.y);

		if (middleValue === 0) {
			yPos = 0;
		} else {
			if (pointA.y > pointB.y) {
				yPos = pointA.y - middleValue;
			} else {
				yPos = pointA.y + middleValue;
			}
		}
	} else {
		xPos = pointA.x;
		yPos = pointA.y;

		const middleValue = getMiddleValue(pointA.z, pointB.z);

		if (middleValue === 0) {
			zPos = 0;
		} else {
			if (pointA.z > pointB.z) {
				zPos = pointA.z - middleValue;
			} else {
				zPos = pointA.z + middleValue;
			}
		}
	}

	return new Vector3(xPos, yPos, zPos);
}

// Apply an offset to every point so that it has some distance away from the piece
// This way the lines will not be at the same position as the piece itself, thus they will be properly visible
function applyVerticalOffsetToHorizontalPoints(horizontalPoints, sideOfLine) {
	const offset = VERTICAL_OFFSET_FROM_PIECE;

	const addVerticalOffset = horizontalPoint => {
		switch (sideOfLine) {
			case FRONT:
				horizontalPoint.pointA.z += offset;
				horizontalPoint.pointB.z += offset;
				break;
			case LEFT:
				if (horizontalPoint.lineSide === RIGHT) {
					horizontalPoint.pointA.x += offset;
					horizontalPoint.pointB.x += offset;
				} else {
					horizontalPoint.pointA.x -= offset;
					horizontalPoint.pointB.x -= offset;
				}
				break;
			case BACK:
				horizontalPoint.pointA.z -= offset;
				horizontalPoint.pointB.z -= offset;
				break;
			case RIGHT:
				if (horizontalPoint.lineSide === LEFT) {
					horizontalPoint.pointA.x -= offset;
					horizontalPoint.pointB.x -= offset;
				} else {
					horizontalPoint.pointA.x += offset;
					horizontalPoint.pointB.x += offset;
				}
				break;
		}

		return horizontalPoint;
	};

	if (Array.isArray(horizontalPoints)) {
		horizontalPoints = horizontalPoints.map(horizontalPoint => {
			return addVerticalOffset(horizontalPoint);
		});
	} else {
		horizontalPoints = addVerticalOffset(horizontalPoints);
	}

	return horizontalPoints;
}

export function shortenDistanceBetweenHorizontalPoints(horizontalPoints, sideOfLine) {
	const distance = DISTANCE_TO_SHORTEN;

	const shortenPoints = horizontalPoint => {
		// When distance is only 2 or less they do not have to be shortened, will result in buggy behaviour for text positioning
		if (horizontalPoint.pointA.distanceTo(horizontalPoint.pointB) > distance * 2) {
			switch (sideOfLine) {
				case FRONT:
					horizontalPoint.pointA.x += distance;
					horizontalPoint.pointB.x -= distance;
					break;
				case BACK:
					horizontalPoint.pointA.x -= distance;
					horizontalPoint.pointB.x += distance;
					break;
				case LEFT:
					horizontalPoint.pointA.z += distance;
					horizontalPoint.pointB.z -= distance;
					break;
				case RIGHT:
					horizontalPoint.pointA.z -= distance;
					horizontalPoint.pointB.z += distance;
					break;
			}

			horizontalPoint.isShortened = true;
		}

		return horizontalPoint;
	};

	if (Array.isArray(horizontalPoints)) {
		horizontalPoints = horizontalPoints.map(horizontalPoint => {
			return shortenPoints(horizontalPoint);
		});
	} else {
		horizontalPoints = shortenPoints(horizontalPoints);
	}

	return horizontalPoints;
}

function applyHorizontalOffsetToVerticalPoints(verticalPoints, sideOfLine) {
	const distance = HORIZONTAL_OFFSET_FROM_PIECE;

	const shortenPoints = verticalPoint => {
		switch (sideOfLine) {
			case FRONT:
			case BACK:
				if (verticalPoint.lineSide === LEFT) {
					verticalPoint.pointA.x -= distance;
					verticalPoint.pointB.x -= distance;
				} else {
					verticalPoint.pointA.x += distance;
					verticalPoint.pointB.x += distance;
				}
				break;
			case LEFT:
				if (verticalPoint.lineSide === LEFT) {
					verticalPoint.pointA.z -= distance;
					verticalPoint.pointB.z -= distance;
				} else {
					verticalPoint.pointA.z += distance;
					verticalPoint.pointB.z += distance;
				}
				break;
			case RIGHT:
				if (verticalPoint.lineSide === LEFT) {
					verticalPoint.pointA.z += distance;
					verticalPoint.pointB.z += distance;
				} else {
					verticalPoint.pointA.z -= distance;
					verticalPoint.pointB.z -= distance;
				}
				break;
		}

		return verticalPoint;
	};

	if (Array.isArray(verticalPoints)) {
		verticalPoints = verticalPoints.map(verticalPoint => {
			return shortenPoints(verticalPoint);
		});
	} else {
		verticalPoints = shortenPoints(verticalPoints);
	}

	return verticalPoints;
}

function addLineLengthToPointsInBetweenOperations(pointsInBetweenOperations, points, piece, sideOfLine) {
	const currentSideLength = [FRONT, BACK].includes(sideOfLine) ? piece.dimensions.length : piece.dimensions.width;
	const originalSideLength = getOriginalSideLengthOfPiece(piece, sideOfLine);

	let totalOperationLength = 0;

	points.forEach(point => {
		if (point.lineLength) totalOperationLength += point.lineLength;
	});

	let pool = currentSideLength - totalOperationLength;

	pointsInBetweenOperations.forEach(point => {
		const lineLength = getLineLength(point);
		const percentage = lineLength / pool;
		point.lineLength = (originalSideLength - totalOperationLength) * percentage;
	});

	return pointsInBetweenOperations;
}

export function getOriginalSideLengthOfPiece(piece, side) {
	const currentSideLength = [FRONT, BACK].includes(side) ? piece.dimensions.length : piece.dimensions.width;
	const ratio = [FRONT, BACK].includes(side) ? piece.dimensionRatio.length : piece.dimensionRatio.width;

	return currentSideLength * (1 / ratio);
}
