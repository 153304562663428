import { addAlertMessage, setShouldUpdatePrice, setWindowIsLoading } from '../../actions/GeneralActions';
import { DANGER, SUCCESS } from '../../constants/Variants';
import { OperationService } from '../../classes/services/OperationService';
import ConfigurableReducerHelper from '../../classes/helpers/ConfigurableReducerHelper';
import { store } from '../../store/index';
import {
	ANCHOR_HOLE,
	CHISELED_SIDE,
	CORNER_CUTOUT,
	COUPE_OVER_LENGTH,
	DEBASING_ROUGH,
	DRILL_HOLE,
	EXTRA_SANDING_5CM_BOTTOM,
	FINISHED_SIDE,
	GROOVES,
	HEIGHT_COUPE,
	NOTCH,
	NOTCH_OVER_LENGTH,
	RABAT,
	RECTANGULAR_CUT_OUT,
	ROUNDED_CORNER,
} from '../../constants/OperationTypes';
import { captureException } from '@sentry/react';

const configurableReducerHelper = new ConfigurableReducerHelper();

function dispatch(action) {
	store.dispatch(action);
}

function onCreate(response, onSuccess) {
	const state = store.getState();
	const currentConfiguration = state.offerReducer.currentConfiguration;
	const currentPiece = state.offerReducer.currentPiece;

	if (response.success) {
		currentPiece.operations.push(response.data);

		configurableReducerHelper.updatePiece(currentPiece, currentConfiguration);
		dispatch(setShouldUpdatePrice(true));

		onSuccess();
	} else {
		throw Error(response.message);
	}
}

function onCreateOperations(response, onSuccess, operationType) {
	const state = store.getState();
	const currentConfiguration = state.offerReducer.currentConfiguration;
	const currentPiece = state.offerReducer.currentPiece;

	if (response.success) {
		const operationData = response.data.surface_operation;
		const finishedSideData = response.data.finished_sides;

		currentPiece.operations = currentPiece.operations.filter(o => o.type !== operationType);

		// Remove all finished/chiseled sides. They will be added back if needed in the next few lines of code
		// This to sync them with the server side, as finished/chiseled sides could have been deleted
		currentPiece.operations = currentPiece.operations.filter(o => o.type !== CHISELED_SIDE && o.type !== FINISHED_SIDE);

		operationData.concat(finishedSideData).forEach(operation => {
			currentPiece.operations.push(operation);
		});

		configurableReducerHelper.updatePiece(currentPiece, currentConfiguration);
		dispatch(setShouldUpdatePrice(true));

		onSuccess();
	} else {
		throw Error(response.message);
	}
}

export function createOperation(operation, onSuccess, failedMessage) {
	const state = store.getState();
	const currentConfiguration = state.offerReducer.currentConfiguration;
	const currentPiece = state.offerReducer.currentPiece;

	const apiCall = getApiCall(operation.type);

	dispatch(setWindowIsLoading(true));

	apiCall(currentConfiguration.id, currentPiece, operation)
		.then(response => onCreate(response, onSuccess))
		.catch(error => {
			dispatch(addAlertMessage(DANGER, failedMessage));
			throw error;
		})
		.finally(() => dispatch(setWindowIsLoading(false)));
}

export function setOperationWithSides(sides, operationType, onSuccess, failedMessage) {
	const state = store.getState();
	const currentConfiguration = state.offerReducer.currentConfiguration;
	const currentPiece = state.offerReducer.currentPiece;

	const apiCall = getApiCallForOperationWithMultipleSides();

	dispatch(setWindowIsLoading(true));

	apiCall(currentConfiguration.id, currentPiece, operationType, sides)
		.then(response => onCreateOperations(response, onSuccess, operationType))
		.catch(error => {
			dispatch(addAlertMessage(DANGER, failedMessage));
			throw error;
		})
		.finally(() => dispatch(setWindowIsLoading(false)));
}

export function updateSandedSides(sides, failedMessage) {
	const operationService = new OperationService();

	const state = store.getState();
	const currentOffer = state.offerReducer.currentOffer;
	const currentConfiguration = state.offerReducer.currentConfiguration;
	const currentPiece = state.offerReducer.currentPiece;

	dispatch(setWindowIsLoading(true));
	operationService
		.updateSandedSides(currentConfiguration.id, currentPiece, sides)
		.then(response => {
			if (response.success) {
				currentPiece.operations = currentPiece.operations.filter(
					operation => operation.type !== EXTRA_SANDING_5CM_BOTTOM,
				);
				currentPiece.operations = currentPiece.operations.concat([...response.data]);

				configurableReducerHelper.updatePiece(currentPiece);
				dispatch(setShouldUpdatePrice(true));
			} else {
				throw Error(response.message);
			}
		})
		.catch(error => {
			dispatch(addAlertMessage(DANGER, failedMessage));
			captureException(error);
		})
		.finally(() => dispatch(setWindowIsLoading(false)));
}

export function updateProfiles({ type, sides, dimensions, data }, onSuccess, failedMessage) {
	const operationService = new OperationService();

	const state = store.getState();
	const currentOffer = state.offerReducer.currentOffer;
	const currentConfiguration = state.offerReducer.currentConfiguration;
	const currentPiece = state.offerReducer.currentPiece;

	dispatch(setWindowIsLoading(true));
	operationService
		.updateProfiles(currentConfiguration.id, currentPiece, type, sides, dimensions, data)
		.then(response => {
			if (response.success) {
				if (response.data) {
					currentPiece.operations = [...response.data];
				} else {
					currentPiece.operations = currentPiece.operations.filter(operation => operation.additionalType.type !== type);
				}

				configurableReducerHelper.updatePiece(currentPiece);
				dispatch(setShouldUpdatePrice(true));
				onSuccess();
			} else {
				throw Error(response.message);
			}
		})
		.catch(error => {
			dispatch(addAlertMessage(DANGER, failedMessage));
			throw error;
		})
		.finally(() => dispatch(setWindowIsLoading(false)));
}

export function updateOperation(operation, onSuccess, failedMessage) {
	const operationService = new OperationService();

	const state = store.getState();
	const currentConfiguration = state.offerReducer.currentConfiguration;
	const currentPiece = state.offerReducer.currentPiece;

	dispatch(setWindowIsLoading(true));

	operationService
		.update(currentConfiguration.id, currentPiece, operation)
		.then(response => {
			if (response.success) {
				currentPiece.operations = currentPiece.operations.map(op => {
					if (op.id === operation.id) {
						return response.data;
					}

					return op;
				});

				configurableReducerHelper.updatePiece(currentPiece, currentConfiguration);
				dispatch(setShouldUpdatePrice(true));

				onSuccess();
			} else {
				throw Error(response.message);
			}
		})
		.catch(error => {
			dispatch(addAlertMessage(DANGER, failedMessage));
			throw error;
		})
		.finally(() => dispatch(setWindowIsLoading(false)));
}

export function deleteOperation(operation, onSuccess, successMessage, failedMessage) {
	const operationService = new OperationService();

	const state = store.getState();
	const currentOffer = state.offerReducer.currentOffer;
	const currentConfiguration = state.offerReducer.currentConfiguration;
	const currentPiece = state.offerReducer.currentPiece;

	dispatch(setWindowIsLoading(true));

	operationService
		.deleteOperation(currentConfiguration.id, currentPiece.id, operation.id)
		.then(response => {
			if (response) {
				dispatch(addAlertMessage(SUCCESS, successMessage));

				currentPiece.operations = currentPiece.operations.filter(op => op.id !== operation.id);
				configurableReducerHelper.updatePiece(currentPiece, currentConfiguration);

				dispatch(setShouldUpdatePrice(true));
				onSuccess();
			} else {
				throw Error(response.message);
			}
		})
		.catch(error => {
			dispatch(addAlertMessage(DANGER, failedMessage));
			throw error;
		})
		.finally(() => dispatch(setWindowIsLoading(false)));
}

function getApiCall(operationType) {
	const operationService = new OperationService();
	let apiCall;

	switch (operationType) {
		case NOTCH:
			apiCall = operationService.createNotch;
			break;
		case DEBASING_ROUGH:
			apiCall = operationService.createDebasingRough;
			break;
		case CORNER_CUTOUT:
			apiCall = operationService.createCornerCutout;
			break;
		case GROOVES:
			apiCall = operationService.createGrooves;
			break;
		case ROUNDED_CORNER:
			apiCall = operationService.createRoundedCorner;
			break;
		case DRILL_HOLE:
			apiCall = operationService.createDrillHole;
			break;
		case HEIGHT_COUPE:
			apiCall = operationService.createHeightCoupe;
			break;
		case COUPE_OVER_LENGTH:
			apiCall = operationService.createCoupeOverLength;
			break;
		case RECTANGULAR_CUT_OUT:
			apiCall = operationService.createRectangularCutOut;
			break;
		case ANCHOR_HOLE:
			apiCall = operationService.createAnchorHole;
			break;
		case RABAT:
			apiCall = operationService.createRabat;
			break;
		case NOTCH_OVER_LENGTH:
			apiCall = operationService.createNotchOverLength;
			break;
		default:
			break;
	}

	return apiCall.bind(operationService);
}

function getApiCallForOperationWithMultipleSides() {
	const operationService = new OperationService();

	return operationService.updateSurfaceOperation.bind(operationService);
}
