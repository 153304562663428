import { useEffect, useState } from 'react';
import { OfferService } from '../services/OfferService';
import { setCurrentOffer } from '../../actions/OfferActions';
import { DANGER, SUCCESS } from '../../constants/Variants';
import { addAlertMessage, setShouldUpdatePrice, setWindowIsLoading } from '../../actions/GeneralActions';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { useParams } from 'react-router-dom';
import { PaginationData } from '../models/PaginationData';
import { usePrevious } from './usePrevious';
import { debounce } from 'lodash';

export const useOffers = () => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'hooks.useOffers.';

	const [paginationData, setPaginationData] = useState(new PaginationData());
	const [offers, setOffers] = useState([]);
	const [isUpdatingArticleOffers, setIsUpdatingArticleOffers] = useState(false);

	const urlParameters = useParams();
	const dispatch = useDispatch();

	const { currentOffer } = useSelector(state => state.offerReducer);
	const { currentCompanyBranch, currentCompanyBranchId = currentCompanyBranch?.id } = useSelector(
		state => state.companyReducer,
	);
	const { priceType, searchKeyword } = useSelector(state => state.generalReducer);
	const previousCompanyBranchId = usePrevious(currentCompanyBranchId);
	const previousSearchKeyword = usePrevious(searchKeyword);
	const previousPriceType = usePrevious(priceType);

	const offerService = new OfferService();

	useEffect(() => {
		setPaginationData(paginationData.reset());
	}, [currentCompanyBranchId, searchKeyword]);

	const loadOffers = debounce((page = 1, resetPagination = false) => {
		if (resetPagination) {
			paginationData.reset();
			setPaginationData(paginationData);
		}

		return getOffers(page);
	}, 500);

	const getOffers = (page = 1) => {
		dispatch(setWindowIsLoading(true));
		return offerService
			.getAll(priceType, !isUpdatingArticleOffers && page, currentCompanyBranchId, searchKeyword)
			.then(response => {
				if (response.success) {
					setPaginationData(response.paginationData);
					setOffers(response.data);
					updateCurrentOffer(response.data);

					return response.data;
				} else {
					throw Error(response.message);
				}
			})
			.catch(error => {
				dispatch(addAlertMessage(DANGER, t(prefix + 'getOffersFailed')));
				throw error;
			})
			.finally(() => dispatch(setWindowIsLoading(false)));
	};

	const createOffer = (name, customer, margin, vat, companyBranchId, isVisible) => {
		dispatch(setWindowIsLoading(true));

		return offerService
			.createOffer(name, customer, margin, vat, companyBranchId, isVisible)
			.then(response => {
				if (response.success) {
					dispatch(setCurrentOffer(response.data));
					if (currentCompanyBranchId === companyBranchId) {
						// Concat to new element, so that this element is at first position of array
						setOffers([response.data].concat(offers));
					}
					dispatch(addAlertMessage(SUCCESS, t(prefix + 'createSuccess')));

					return response.data;
				} else {
					throw Error(response.message);
				}
			})
			.catch(error => {
				dispatch(setWindowIsLoading(false));
				dispatch(addAlertMessage(DANGER, t(prefix + 'createFailed')));
				throw error;
			})
			.finally(() => dispatch(setWindowIsLoading(false)));
	};

	const updateOffer = (name, customer, margin, vat, companyBranchId, isVisible) => {
		dispatch(setWindowIsLoading(true));

		return offerService
			.update(currentOffer.id, name, customer, margin, vat, companyBranchId, isVisible)
			.then(response => {
				if (response.success) {
					if (currentOffer.margin !== response.data.margin) {
						dispatch(setShouldUpdatePrice(true));
					}

					let updatedOffer = currentOffer;
					updatedOffer.name = response.data.name;
					updatedOffer.customer = response.data.customer;
					updatedOffer.margin = response.data.margin;
					updatedOffer.vat = response.data.vat;
					updatedOffer.isVisible = response.data.isVisible;
					dispatch(setCurrentOffer(updatedOffer));

					let tempOffers = offers.map(offer => {
						if (offer.id === updatedOffer.id) {
							offer.name = updatedOffer.name;
							offer.customer = updatedOffer.customer;
							offer.margin = updatedOffer.margin;
							offer.vat = updatedOffer.vat;
							offer.isVisible = updatedOffer.isVisible;
						}

						return offer;
					});

					setOffers(tempOffers);

					dispatch(addAlertMessage(SUCCESS, t(prefix + 'updatedOfferSuccess')));
				} else {
					throw Error(response.message);
				}
			})
			.catch(error => {
				dispatch(addAlertMessage(DANGER, t(prefix + 'updatedOfferFailed')));
				throw error;
			})
			.finally(() => dispatch(setWindowIsLoading(false)));
	};

	const deleteOffer = () => {
		dispatch(setWindowIsLoading(true));

		return offerService
			.deleteOffer(currentOffer.id)
			.then(response => {
				if (response.success) {
					setOffers(offers.filter(offer => offer.id !== currentOffer.id));
					dispatch(setCurrentOffer(null));

					dispatch(addAlertMessage(SUCCESS, t(prefix + 'deleteSuccess')));
				} else {
					throw Error(response.message);
				}
			})
			.catch(error => {
				dispatch(addAlertMessage(DANGER, t(prefix + 'deleteFailed')));
				throw error;
			})
			.finally(() => dispatch(setWindowIsLoading(false)));
	};

	const updateCurrentOffer = offerList => {
		offerList.forEach(offer => {
			if (urlParameters.id) {
				if (offer.id === parseInt(urlParameters.id)) {
					dispatch(setCurrentOffer(offer));
				}
			}
		});
	};

	const updateOfferDates = offer => {
		setOffers(
			offers.map(o => {
				if (o.id === offer.id) {
					o.timestamp = offer.timestamp;
					o.expiryDate = offer.expiryDate;
				}

				return o;
			}),
		);

		if (currentOffer?.id === offer.id) {
			currentOffer.timestamp = offer.timestamp;
			currentOffer.expiryDate = offer.expiryDate;
			dispatch(setCurrentOffer(currentOffer));
		}
	};

	return {
		loadOffers,
		offers,
		paginationData,
		allOffersLoaded: paginationData.total === offers.length,
		createOffer,
		updateOffer,
		deleteOffer,
		setIsUpdatingArticleOffers,
		updateOfferTimeStamp: updateOfferDates,
	};
};
