import * as React from 'react';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ModalHolder } from './ModalHolder';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import PropTypes from 'prop-types';
import CompanyService from '../../classes/services/CompanyService';
import { CompanyBranch } from '../../classes/models/CompanyBranch';
import { captureException } from '@sentry/react';
import { SectionTitle } from '../headers/SectionTitle';
import { Select } from '../forms/selects/Select';

export const ExternalContactPersonModal = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'modals.externalContactPersonModal.';

	const [selectedUser, setSelectedUser] = useState(null);
	const [users, setUsers] = useState([]);
	const [contactsToChooseFrom, setContactsToChooseFrom] = useState([]);

	const companyService = new CompanyService();

	useEffect(() => {
		if (!props.isActive) {
			setSelectedUser(null);
		}
	}, [props.isActive]);

	useEffect(() => {
		let isMounted = true;

		if (props.companyBranch) {
			// setUsers(props.companyBranch.id);

			companyService
				.getUsers(props.companyBranch.companyId)
				.then(response => {
					if (response.success) {
						if (isMounted) {
							setUsers(response.data);
						}
					} else {
						throw Error(response.message);
					}
				})
				.catch(e => captureException(e));
		}

		return () => {
			isMounted = false;
		};
	}, [props.companyBranch?.id]);

	useEffect(() => {
		// Remove all users that are already in the branch, as they are automatically contacts
		let contactPersons = users.filter(user => !props.companyBranch.users.find(u => u.id === user.id));
		// Remove users that are already registered as contacts
		contactPersons = contactPersons.filter(u => !props.contactPersons.find(c => c.id === u.id));

		setContactsToChooseFrom(contactPersons);

		if (contactPersons.length && !selectedUser) {
			setSelectedUser(contactPersons[0]);
		}
	}, [props.contactPersons, users]);

	const updateSelectedUser = userId => {
		const user = users.find(u => u.id === parseInt(userId));

		setSelectedUser(user);
	};

	return (
		<ModalHolder isActive={props.isActive}>
			<Modal show={props.isActive} onHide={props.onClose} animation={true} className="modal--prompt">
				<Modal.Header>
					<Modal.Title>{t(prefix + 'header')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<SectionTitle content={t(prefix + 'title')} dataCy={'externalContactPersonModal-title'} />

					<Select
						name="externalContactPerson"
						value={selectedUser?.id}
						options={contactsToChooseFrom.map(u => {
							return { id: u.id, value: u.getFullName() };
						})}
						onChange={id => updateSelectedUser(id)}
						dataCy={'externalContactPersonModal-userSelect'}
					/>
				</Modal.Body>
				<Modal.Footer>
					<div className="d-flex w-100 space-between">
						<button
							data-cy={'externalContactPersonModal-submitButton'}
							type="button"
							className="button button--primary"
							disabled={!users.length}
							onClick={() => props.onClose(selectedUser)}
						>
							{t(prefix + 'submitButton')}
						</button>

						<div
							className="button button--outline"
							onClick={() => props.onClose()}
							data-cy={'externalContactPersonModal-closeButton'}
						>
							{t(prefix + 'closeButton')}
						</div>
					</div>
				</Modal.Footer>
			</Modal>
		</ModalHolder>
	);
};

ExternalContactPersonModal.propTypes = {
	isActive: PropTypes.bool,
	companyBranch: PropTypes.instanceOf(CompanyBranch),
	contactPersons: PropTypes.array,
	onClose: PropTypes.func,
};
