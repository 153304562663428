import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
	MONDAY_DELIVERY,
	PMO_1,
	PMO_2,
	WEDNESDAY_DELIVERY_BI_WEEKLY,
	WEDNESDAY_DELIVERY_WEEKLY,
} from '../../../constants/DeliveryTypes';
import { DeliveryType } from '../../../classes/models/DeliveryType';
import { CLOSED, OPEN } from '../../../constants/Statuses';
import { parseToCommaSeparated } from '../../../classes/helpers/StringHelper';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { DeliveryDate } from '../../../classes/models/DeliveryDate';

export const DeliveryInfo = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'pages.orders.deliveryInfo.';

	const [shouldRenderFranco, setShouldRenderFranco] = useState(false);
	const [shouldRenderDate, setShouldRenderDate] = useState(false);

	useEffect(() => {
		if (props.deliveryType) {
			if (
				[PMO_1, PMO_2, MONDAY_DELIVERY, WEDNESDAY_DELIVERY_WEEKLY, WEDNESDAY_DELIVERY_BI_WEEKLY].includes(
					props.deliveryType.name,
				)
			) {
				if (!shouldRenderFranco) setShouldRenderFranco(true);
			} else {
				if (shouldRenderFranco) setShouldRenderFranco(false);
			}

			if (props.deliveryType.__deliveryDates.some(date => date.status === OPEN)) {
				if (!shouldRenderDate) setShouldRenderDate(true);
			} else {
				if (shouldRenderDate) setShouldRenderDate(false);
			}
		}
	}, [props.deliveryType]);

	const getSubText = () => {
		let subText;

		if (props.deliveryDate.status === OPEN) {
			subText = t(prefix + 'nextDeliveryDate');
		} else {
			subText = t(prefix + 'closedDeliveryDate');
		}

		return subText;
	};

	const getDateTextColor = () => {
		return isClosed() ? 'color--red' : '';
	};

	const isClosed = () => {
		return props.deliveryDate.status === CLOSED;
	};

	const getFrancoText = () => {
		if (!props.franco) {
			return 0;
		}

		return parseToCommaSeparated(props.franco.toFixed(2));
	};

	return (
		<>
			{shouldRenderFranco || shouldRenderDate ? (
				<div className="delivery-info" data-tip={isClosed() ? t(prefix + 'closedDateToolTipContent') : ''}>
					{isClosed() ? (
						<div className="delivery-info-status">
							<i className="material-icons">block</i>
						</div>
					) : null}

					{shouldRenderFranco ? (
						<div className={isClosed() ? 'delivery-info-item color--red' : 'delivery-info-item'}>
							{props.franco ? (
								<>
									<span>
										€ <strong>{getFrancoText()}</strong>/1000
									</span>
									<small>{t(prefix + 'franco')}</small>
								</>
							) : (
								<span>{t(prefix + 'noOrdersForDeliveryDate')}</span>
							)}
						</div>
					) : null}

					{shouldRenderFranco && shouldRenderDate ? <div className="delivery-info-divider" /> : null}

					{shouldRenderDate ? (
						<div className={isClosed() ? 'delivery-info-item color--red' : 'delivery-info-item'}>
							<span>{props.deliveryDate.getReadableDate()}</span>
							<small className={getDateTextColor()}>{getSubText()}</small>
						</div>
					) : null}
				</div>
			) : null}
		</>
	);
};

DeliveryInfo.propTypes = {
	deliveryType: PropTypes.instanceOf(DeliveryType),
	deliveryDate: PropTypes.instanceOf(DeliveryDate),
	franco: PropTypes.number,
};

DeliveryInfo.defaultProps = {
	deliveryType: null,
};
