import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { useSelector } from 'react-redux';
import { parseToCommaSeparated } from '../../../classes/helpers/StringHelper';

export const CumulatedOperationItem = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'pages.offers.configurationList.';

	const { currentOffer } = useSelector(state => state.offerReducer);

	const getCumulatedOperationsPrice = () => {
		let price = 0;

		currentOffer.cumulatedOperations.forEach(o => {
			price += o.price;
		});

		return '€' + parseToCommaSeparated(price, 2);
	};

	const renderCumulatedOperationsCount = () => {
		let content;
		const count = currentOffer?.cumulatedOperations.length;

		if (count === 1) {
			content = `${count} ${t(prefix + 'operationsSingular')}`;
		} else {
			content = `${count} ${t(prefix + 'operationsPlural')}`;
		}

		return content;
	};

	return (
		<>
			<tr className="offer__table__level1">
				<td>{t(prefix + 'cumulatedOperations')}</td>
				<td colSpan={2}>
					<small>{renderCumulatedOperationsCount()}</small>
				</td>
				<td style={{ textAlign: 'left' }}>{getCumulatedOperationsPrice()}</td>
				<td></td>
			</tr>
		</>
	);
};
