export const NEW_USERS = 'NEW_USERS';
export const COMPANIES = 'COMPANIES';
export const DELIVERIES = 'DELIVERIES';
export const CATALOGS = 'CATALOGS';
export const GALLERY_IMAGES = 'GALLERY_IMAGES';
export const PRODUCTS = 'PRODUCTS';
export const SETTINGS = 'SETTINGS';

export const NEW_USERS_URL_NAME = 'newusers';
export const COMPANIES_URL_NAME = 'companies';
export const DELIVERIES_URL_NAME = 'deliveries';
export const CATALOGS_URL_NAME = 'catalogs';
export const GALLERY_IMAGES_URL_NAME = 'galleryimages';
export const PRODUCTS_URL_NAME = 'products';
export const SETTINGS_URL_NAME = 'settings';

export const ADMIN_PAGES = [
	NEW_USERS,
	COMPANIES,
	DELIVERIES,
	SETTINGS,
	// PRODUCTS,
];

export const ADMIN_PAGE_URL_LOOKUP = {
	[NEW_USERS]: NEW_USERS_URL_NAME,
	[COMPANIES]: COMPANIES_URL_NAME,
	[DELIVERIES]: DELIVERIES_URL_NAME,
	[CATALOGS]: CATALOGS_URL_NAME,
	[GALLERY_IMAGES]: GALLERY_IMAGES_URL_NAME,
	[PRODUCTS]: PRODUCTS_URL_NAME,
	[SETTINGS]: SETTINGS_URL_NAME,
};

export const ADMIN_PAGE_NAME_LOOKUP = {
	[NEW_USERS_URL_NAME]: NEW_USERS,
	[COMPANIES_URL_NAME]: COMPANIES,
	[DELIVERIES_URL_NAME]: DELIVERIES,
	[CATALOGS_URL_NAME]: CATALOGS,
	[GALLERY_IMAGES_URL_NAME]: GALLERY_IMAGES,
	[PRODUCTS_URL_NAME]: PRODUCTS,
	[SETTINGS_URL_NAME]: SETTINGS,
};
