import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Configuration } from '../../../classes/models/Configuration';
import { CHIMNEY_COVERS, SILLS, SILLS_STOCK, WALL_COVERS } from '../../../constants/Presets';
import {
	CHIMNEY_COVER_TYPES,
	getTypesByPreset,
	TYPE_10,
	TYPE_9,
	WALL_COVER_TYPES,
} from '../../../constants/ObjectTypes';
import { Select } from './Select';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';

export const PieceTypeSelect = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'forms.selects.pieceTypeSelect.';
	const pieceTypePrefix = 'constants.types.';

	useEffect(() => {
		if (!props.selectedPieceType && props.configuration?.options) {
			props.onChange(props.configuration.options.type);
		}
	}, [props.configuration]);

	const getPieceTypes = () => {
		if (!props.configuration) {
			return;
		}

		const { decor, material, preset, subPreset } = props.configuration.options;

		return (
			getTypesByPreset(decor, material, preset, subPreset)
				// Type 9 and 10 cannot be selected here
				.filter(type => ![TYPE_9, TYPE_10].includes(type))
				.map(type => {
					return { id: type, value: t(pieceTypePrefix + type) };
				})
		);
	};

	const shouldRenderSelect = () => {
		if (!props.configuration) {
			return false;
		}

		const configurationPresetCanHaveMultipleTypes = [SILLS, WALL_COVERS, CHIMNEY_COVERS].includes(
			props.configuration.options.preset,
		);

		const configurationIsForStockItems = props.configuration.options.subPreset === SILLS_STOCK;

		return configurationPresetCanHaveMultipleTypes && !configurationIsForStockItems;
	};

	return (
		shouldRenderSelect() && (
			<Select
				label={t(prefix + 'label')}
				name="pieceTypeSelect"
				options={getPieceTypes()}
				value={props.selectedPieceType}
				onChange={pieceType => props.onChange(pieceType)}
			/>
		)
	);
};

PieceTypeSelect.propTypes = {
	selectedPieceType: PropTypes.string,
	configuration: PropTypes.instanceOf(Configuration),
	onChange: PropTypes.func.isRequired,
};
