import * as React from 'react';
import PropTypes from 'prop-types';
import { DANGER, INFO, WARNING } from '../../constants/Variants';

export const ValidationMessage = props => {
	const getStyling = () => {
		let styling = 'form-';

		switch (props.variant) {
			case INFO:
				styling += 'infotext';
				break;
			case WARNING:
				styling += 'warningtext';
				break;
			case DANGER:
				styling += 'errortext';
				break;
			default:
				styling = '';
				break;
		}

		return styling;
	};

	return (
		<>
			<small data-cy={props.dataCy} className={getStyling()}>
				{props.content}
			</small>
		</>
	);
};

ValidationMessage.propTypes = {
	content: PropTypes.string.isRequired,
	variant: PropTypes.string,
	dataCy: PropTypes.string,
};
