export const DEGREES = 'DEGREES';
export const CENTIMETERS = 'CENTIMETERS';
export const EXTERIOR = 'EXTERIOR';
export const INTERIOR = 'INTERIOR';
export const PROPRIETARY = 'PROPRIETARY';
export const PROFESSIONAL = 'PROFESSIONAL';

export const TWO_DIMENSIONAL = '2D';
export const THREE_DIMENSIONAL = '3D';

export const LANGUAGE_KEY = 'i18nextLng';

export const MAX_HEIGHT = 45;

export const BAR_EXTRUSION = 1;
export const BAR_DEPTH = 1;

export const DEFAULT_DIMENSION_RATIO = 1;

export const FOV = 75;

// Weight for a cubic meter bluestone
export const BLUESTONE_WEIGHT = 2800;

export const WATERLIST_DISTANCE_FROM_SIDE = 3;

export const MAX_PIECE_AMOUNT = 500;
export const MIN_PIECE_AMOUNT = 1;

export const DEVELOPMENT = 'development';
export const PRODUCTION = 'production';

export const ENVIRONMENTS = [DEVELOPMENT, PRODUCTION];

export const DELIVERY_DATE_TO_BE_DETERMINED = 'DELIVERY_DATE_TO_BE_DETERMINED';
