import { TranslateLogService } from '../services/TranslateLogService';

export class LogProperties {
	constructor() {
		this.old = [];
		this.new = [];
	}

	changesToString() {
		let string = '';

		this.new.forEach(propertyNew => {
			const newValue = propertyNew.value;
			const oldValue = this.old.find(p => p.key === propertyNew.key)?.value;

			if (newValue !== undefined && newValue !== null) {
				let stringToAdd = '';

				if (string.length) {
					stringToAdd += ', ' + propertyNew.key + TranslateLogService.SPLIT_OPERATOR_COLON;
				} else {
					stringToAdd = propertyNew.key + TranslateLogService.SPLIT_OPERATOR_COLON;
				}

				if (oldValue) {
					stringToAdd += oldValue + TranslateLogService.SPLIT_OPERATOR_ARROW + newValue;
				} else {
					stringToAdd += newValue;
				}

				string += stringToAdd;
			}
		});

		return string;
	}

	static parse(propertiesData) {
		return Object.keys(propertiesData).map(key => {
			return {
				key: key,
				value: propertiesData[key],
			};
		});
	}
}
