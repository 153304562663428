import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
	ConfirmationModal,
	CoupeOperation,
	getSideNameByPreset,
	OperationHelper,
	OperationService,
	RadioButton,
	SectionTitle,
	VectorHelper,
} from '../../internal';
import { INNER, OUTER } from '../../constants/Angles';
import { CENTIMETERS, DEGREES } from '../../constants/Values';
import { LEFT, RIGHT } from '../../constants/ObjectSides';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { setCurrentConfiguration } from '../../actions/OfferActions';
import { addAlertMessage, setShouldUpdatePrice, setWindowIsLoading } from '../../actions/GeneralActions';
import { DANGER, SUCCESS, WARNING } from '../../constants/Variants';
import innerAngleLeftIcon from '../../assets/img/inner-angle-left.svg';
import outerAngleLeftIcon from '../../assets/img/outer-angle-left.svg';
import innerAngleRightIcon from '../../assets/img/inner-angle-right.svg';
import outerAngleRightIcon from '../../assets/img/outer-angle-right.svg';
import ConfigurableReducerHelper from '../../classes/helpers/ConfigurableReducerHelper';
import { useForm } from 'react-hook-form';
import { ValidatedInput } from '../forms/inputs/ValidatedInput';
import { PieceService } from '../../classes/services/PieceService';
import { Message } from '../messages/Message';
import { PROHIBITED_OPERATION_TYPES } from '../../constants/OperationTypes';
import { PILLARS } from '../../constants/Presets';
import { parseNumber, parseToCommaSeparated } from '../../classes/helpers/StringHelper';
import { ModalHolder } from './ModalHolder';

export function CoupeModal(props) {
	const FULL_WIDTH = 'FULL_WIDTH';
	const CUSTOM_WIDTH = 'CUSTOM_WIDTH';

	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'modals.coupeModal.';

	const [dimensionType, setDimensionType] = useState('');
	const [selectedSide, setSelectedSide] = useState('');
	const [selectedAngleType, setSelectedAngleType] = useState(INNER);
	const [confirmationModalIsActive, setConfirmationModalIsActive] = useState(false);
	const [coupeOptionsAreDisabled, setCoupeOptionsAreDisabled] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [availableSides, setAvailableSides] = useState([]);
	const [formIsValid, setFormIsValid] = useState(false);
	const [selectedWidthType, setSelectedWidthType] = useState(false);

	const { errors, register, handleSubmit, setValue } = useForm();

	const { currentConfiguration, currentPiece, currentOperation } = useSelector(state => state.offerReducer);
	const { canEdit } = useSelector(state => state.generalReducer);

	const dispatch = useDispatch();

	const operationService = new OperationService();
	const pieceService = new PieceService();
	const configurableReducerHelper = new ConfigurableReducerHelper();

	useEffect(() => {
		manageInitialFormState();

		if (props.isActive) {
			let tempAvailableSides = currentPiece ? currentPiece.getAvailableSides(PROHIBITED_OPERATION_TYPES.COUPE) : [];
			tempAvailableSides = tempAvailableSides.filter(s => [LEFT, RIGHT].includes(s));

			if (currentOperation) {
				setSelectedSide(currentOperation.side);
				setSelectedAngleType(currentOperation.angle);
				setDimensionType(currentOperation.additionalDimension.type);

				tempAvailableSides.push(currentOperation.side);

				setSelectedWidthType(
					currentOperation.dimensions.width === currentPiece?.dimensions.width ? FULL_WIDTH : CUSTOM_WIDTH,
				);
			} else {
				setSelectedSide(tempAvailableSides.length ? tempAvailableSides[0] : '');
				setSelectedAngleType(INNER);
				setDimensionType(DEGREES);

				setSelectedWidthType(FULL_WIDTH);
			}

			setAvailableSides(tempAvailableSides);
		} else {
			clearForm();
		}
	}, [props.isActive]);

	useEffect(() => {
		if (selectedSide && !formIsValid) {
			setFormIsValid(true);
		}

		if (!selectedSide && formIsValid) {
			setFormIsValid(false);
		}
	}, [selectedSide]);

	const getAngleIcon = angle => {
		let icon;

		if (currentConfiguration?.options.preset === PILLARS) {
			if (angle === INNER) {
				icon = selectedSide === LEFT ? innerAngleRightIcon : outerAngleRightIcon;
			} else {
				icon = selectedSide === LEFT ? innerAngleLeftIcon : outerAngleLeftIcon;
			}
		} else {
			if (angle === INNER) {
				icon = selectedSide === LEFT ? innerAngleLeftIcon : innerAngleRightIcon;
			} else {
				icon = selectedSide === LEFT ? outerAngleLeftIcon : outerAngleRightIcon;
			}
		}

		return icon;
	};

	const manageInitialFormState = () => {
		if (!currentPiece) return;
		if (currentPiece.connectedObjects == null) return;

		setDisabled(currentOperation?.connectedCoupe != null || !canEdit);

		if (currentOperation) {
			if (currentOperation.connectedCoupe != null) {
				setCoupeOptionsAreDisabled(true);
			}
		}
	};

	const clearForm = () => {
		setSelectedSide('');
		setSelectedAngleType(INNER);
		setSelectedWidthType(FULL_WIDTH);
	};

	const parseDimensions = (coupe, dimension) => {
		dimension = parseNumber(dimension);

		if (dimensionType === CENTIMETERS) {
			// The user chooses how long the short side should be, we subtract it from the long side here
			// That way we know the length of the mesh that we should create to subtract later
			coupe.dimensions.length = currentPiece.dimensions.length - dimension;
			coupe.additionalDimension = { type: CENTIMETERS, value: dimension };
		} else {
			const width =
				currentPiece.dimensions.width === coupe.dimensions.width
					? currentPiece.dimensions.width
					: coupe.dimensions.width;
			coupe.dimensions.length = OperationHelper.getBottomSideLengthOfCoupeByAngleDegrees(dimension, width);
			coupe.additionalDimension = { type: DEGREES, value: dimension };
		}

		return coupe;
	};

	const createCoupe = fields => {
		let coupe = new CoupeOperation(0, currentPiece.id, selectedSide, selectedAngleType);

		// Set some empty dimensions, depending on the dimensions of the main object
		coupe.dimensions.height = parseNumber(currentPiece.dimensions.height);
		coupe.dimensions.width = fields.width
			? getWidthByInputValue(parseNumber(fields.width))
			: parseNumber(currentPiece.dimensions.width);

		coupe = parseDimensions(coupe, fields.coupeDimension);

		// Update the rotation and position of the object
		currentPiece.updateObjectRotationAndPosition();
		coupe.position = VectorHelper.getVectorForCoupe(currentPiece.dimensions, coupe);

		dispatch(setWindowIsLoading(true));

		operationService
			.createCoupe(currentConfiguration, currentPiece.id, coupe)
			.then(response => {
				if (response.success) {
					configurableReducerHelper.updatePiece(response.data, currentConfiguration);

					dispatch(setShouldUpdatePrice(true));

					props.onClose(true);
				} else {
					dispatch(addAlertMessage(DANGER, t(prefix + 'createFailed')));
				}
			})
			.catch(() => {
				dispatch(addAlertMessage(DANGER, t(prefix + 'createFailed')));
			})
			.finally(() => dispatch(setWindowIsLoading(false)));
	};

	const updateCoupe = fields => {
		let coupe = currentOperation;
		coupe.side = selectedSide;
		coupe.angle = selectedAngleType;

		coupe.dimensions.width = fields.width
			? getWidthByInputValue(parseNumber(fields.width))
			: currentPiece.dimensions.width;
		coupe = parseDimensions(coupe, fields.coupeDimension);

		coupe.position = VectorHelper.getVectorForCoupe(currentPiece.dimensions, coupe);

		currentPiece.updateOperation(coupe);
		currentPiece.updateObjectRotationAndPosition();

		dispatch(setWindowIsLoading(true));
		operationService
			.update(currentConfiguration.id, currentPiece, coupe)
			.then(response => {
				if (response.success) {
					currentPiece.updateOperation(response.data);

					configurableReducerHelper.updatePiece(currentPiece, currentConfiguration);

					return currentPiece;
				} else {
					throw Error(response.message);
				}
			})
			.then(piece => {
				return pieceService.update(currentConfiguration, piece);
			})
			.then(response => {
				if (response.success) {
					configurableReducerHelper.updatePiece(response.data);
				} else {
					throw Error(response.message);
				}
			})
			.catch(error => {
				dispatch(setWindowIsLoading(false));
				dispatch(addAlertMessage(DANGER, t(prefix + 'updateFailed')));

				throw error;
			})
			.finally(() => {
				dispatch(setWindowIsLoading(false));

				dispatch(setShouldUpdatePrice(true));
				props.onClose(true);
			});
	};

	const deleteCoupe = () => {
		dispatch(setWindowIsLoading(true));

		operationService
			.deleteOperation(currentConfiguration.id, currentPiece.id, currentOperation.id)
			.then(success => {
				if (success) {
					dispatch(addAlertMessage(SUCCESS, t(prefix + 'deleteSuccess')));

					currentConfiguration.pieces.forEach(piece => {
						if (piece.id === currentPiece.id) {
							piece.operations = currentPiece.operations.filter(operation => operation.id !== currentOperation.id);
						}
					});

					dispatch(setCurrentConfiguration(currentConfiguration));
					dispatch(setShouldUpdatePrice(true));

					props.onClose(true);
				} else {
					dispatch(addAlertMessage(DANGER, t(prefix + 'deleteFailed')));
				}
			})
			.catch(() => {
				dispatch(addAlertMessage(DANGER, t(prefix + 'deleteFailed')));
			})
			.finally(() => dispatch(setWindowIsLoading(false)));
	};

	const close = fields => {
		if (!fields) {
			props.onClose();
			return;
		}

		if (currentOperation == null) {
			createCoupe(fields);
		} else {
			updateCoupe(fields);
		}
	};

	const getWidthInputValue = () => {
		if (!currentPiece || !currentOperation) return;

		return parseToCommaSeparated(currentPiece.dimensions.width - currentOperation.dimensions.width);
	};

	const getWidthByInputValue = value => {
		if (!currentPiece) return;

		return currentPiece.dimensions.width - value;
	};

	const onAngleTypeChange = value => {
		if (value !== INNER && value !== OUTER) return;

		setSelectedAngleType(value);
	};

	const onSideChange = value => {
		if (value !== LEFT && value !== RIGHT) return;

		// Set the new value for the radio buttons, so the correct one is shown as selected
		setSelectedSide(value);
	};

	const onCoupeInputTypeChange = value => {
		if (disabled) return;

		if (value === 1) {
			setDimensionType(DEGREES);
		} else if (value === 2) {
			setDimensionType(CENTIMETERS);
		}
	};

	const toggleConfirmationModal = userHasAccepted => {
		if (userHasAccepted) {
			deleteCoupe();
		}

		setConfirmationModalIsActive(!confirmationModalIsActive);
	};

	const updateSelectedWidthType = widthType => {
		if (widthType === FULL_WIDTH && selectedWidthType === CUSTOM_WIDTH) {
			setValue('width', '');
		}

		setSelectedWidthType(widthType);
	};

	const renderDimensionTitle = () => {
		let titleContent;

		if (dimensionType === DEGREES) {
			titleContent = t(prefix + 'degreeDimensionTitle');
		} else {
			titleContent = t(prefix + 'centimeterDimensionTitle');
		}

		return <SectionTitle content={titleContent} />;
	};

	const renderCoupeDimensionInput = () => {
		const placeholder =
			dimensionType === CENTIMETERS ? t(prefix + 'centimeterPlaceholder') : t(prefix + 'degreePlaceholder');

		const maxValue = dimensionType === CENTIMETERS ? currentPiece?.dimensions.length : 89;

		return (
			<ValidatedInput
				dataCy="coupeModal-dimensionInput"
				name={'coupeDimension'}
				register={register}
				placeholder={placeholder}
				value={currentOperation?.additionalDimension.value}
				disabled={disabled}
				error={errors.coupeDimension}
				required={true}
				min={1}
				max={maxValue}
				noMargin={true}
				label={dimensionType === CENTIMETERS ? t(prefix + 'centimeterButton') : t(prefix + 'degreeButton')}
			/>
		);
	};

	const renderCoupeInputButtons = () => {
		const active = 'button button--active';
		const outline = 'button button--outline';

		let degreesButtonClass = active;
		let centimetersButtonClass = outline;

		if (disabled) {
			degreesButtonClass = outline;
			centimetersButtonClass = outline;
		} else if (dimensionType === CENTIMETERS) {
			degreesButtonClass = outline;
			centimetersButtonClass = active;
		}

		return (
			<div className="button-group w-100 m-l-1">
				<button
					data-cy="coupeModal-degreesButton"
					type="button"
					className={`${degreesButtonClass} w-100`}
					onClick={() => onCoupeInputTypeChange(1)}
					disabled={disabled}
				>
					{t(prefix + 'degreeButton')}
				</button>
				<button
					data-cy="coupeModal-centimetersButton"
					type="button"
					className={`${centimetersButtonClass} w-100`}
					onClick={() => onCoupeInputTypeChange(2)}
					disabled={disabled}
				>
					{t(prefix + 'centimeterButton')}
				</button>
			</div>
		);
	};

	const renderDeleteButton = () => {
		if (!canEdit) return;

		let className = 'button button--danger';
		let onClick = () => toggleConfirmationModal();
		let content = t(prefix + 'deleteButton');

		if (!currentOperation) {
			className = 'button button--outline';
			onClick = () => props.onClose();
			content = t(prefix + 'cancelButton');
		}

		return (
			<button
				data-cy="coupeModal-deleteButton"
				type="button"
				className={className}
				onClick={onClick}
				disabled={coupeOptionsAreDisabled}
			>
				{content}
			</button>
		);
	};

	const renderSubmitButton = () => {
		return (
			<button
				data-cy="coupeModal-submitButton"
				type="submit"
				form="coupeForm"
				className="button button--primary"
				disabled={!formIsValid || !canEdit || disabled}
			>
				{t(prefix + 'submitButton')}
			</button>
		);
	};

	const renderSideSelect = () => {
		return (
			<>
				<SectionTitle content={t(prefix + 'sideTitle')} />

				{[LEFT, RIGHT].map(side => {
					const sideName = getSideNameByPreset(currentConfiguration?.options.preset, side);

					return (
						<RadioButton
							dataCy={`coupeModal-sideSelect-${side.toLowerCase()}`}
							key={`coupe${side}`}
							content={t('constants.objectSides.' + sideName)}
							onChange={() => onSideChange(side)}
							checked={selectedSide === side}
							disabled={!availableSides.includes(side) || disabled}
							name="side"
						/>
					);
				})}
			</>
		);
	};

	const renderAngleForm = () => {
		return (
			<>
				<SectionTitle content={t(prefix + 'angleTitle')} />

				{[INNER, OUTER].map(angle => {
					return (
						<RadioButton
							dataCy={`coupeModal-angleSelect-${angle.toLowerCase()}`}
							key={`angleType${angle}`}
							image={getAngleIcon(angle)}
							onChange={() => onAngleTypeChange(angle)}
							name="angleRadio"
							checked={selectedAngleType === angle}
							disabled={disabled}
							content={t('constants.angles.' + angle)}
						/>
					);
				})}
			</>
		);
	};

	const renderWidthTypes = () => {
		return (
			<>
				<SectionTitle content={t(prefix + 'widthTypeTitle')} />

				{[FULL_WIDTH, CUSTOM_WIDTH].map(widthType => {
					return (
						<RadioButton
							dataCy={`coupeModal-widthType-${widthType.toLowerCase()}`}
							key={widthType}
							onChange={() => updateSelectedWidthType(widthType)}
							name="widthTypeRadioButton"
							checked={selectedWidthType === widthType}
							disabled={disabled}
							content={t(prefix + widthType)}
						/>
					);
				})}
			</>
		);
	};

	return (
		<ModalHolder isActive={props.isActive}>
			<ConfirmationModal
				isActive={confirmationModalIsActive}
				onClose={userHasAccepted => toggleConfirmationModal(userHasAccepted)}
				content={t('modals.coupeModal.deleteCoupe')}
			/>

			<Modal show={props.isActive} onHide={() => close()} animation={true} data-cy="coupe-modal">
				<Modal.Header className="modal-header">{t(prefix + 'header')}</Modal.Header>
				<Modal.Body>
					<form id="coupeForm" onSubmit={handleSubmit(close)}>
						<p>{t(prefix + 'description')}</p>

						{renderSideSelect()}
						{renderAngleForm()}
						{renderWidthTypes()}

						{selectedWidthType === CUSTOM_WIDTH && (
							<ValidatedInput
								dataCy="coupeModal-customWidthInput"
								name="width"
								register={register}
								label={t(prefix + 'widthLabel')}
								placeholder={t(prefix + 'widthPlaceholder')}
								value={getWidthInputValue()}
								disabled={disabled}
								error={errors.width}
								required={true}
								min={1}
								max={currentPiece?.dimensions.width}
							/>
						)}

						{renderDimensionTitle()}

						<div className="d-flex align-items-bottom">
							{renderCoupeDimensionInput()}
							{renderCoupeInputButtons()}
						</div>
					</form>

					{disabled && (
						<div className="m-t-3">
							<Message content={t(prefix + 'disabledMessage')} variant={WARNING} />
						</div>
					)}
				</Modal.Body>
				<Modal.Footer>
					{renderSubmitButton()}
					{renderDeleteButton()}
				</Modal.Footer>
			</Modal>
		</ModalHolder>
	);
}

CoupeModal.propTypes = {
	isActive: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};
