import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { SectionTitle } from '../headers/SectionTitle';
import { ModalHolder } from './ModalHolder';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { Select } from '../forms/selects/Select';
import { Order } from '../../classes/models/Order';
import { ALL_DELIVERY_OPTIONS } from '../../constants/DeliveryOptions';
import { OrderService } from '../../classes/services/OrderService';
import { useDispatch, useSelector } from 'react-redux';
import { addAlertMessage } from '../../actions/GeneralActions';
import { DANGER } from '../../constants/Variants';
import { setCurrentOrder, setOrders } from '../../actions/OrderActions';

export const OrderModal = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'modals.orderModal.';
	const deliveryOptionPrefix = 'constants.deliveryOptions.';

	const [selectedDeliveryOption, setSelectedDeliveryOption] = useState('');

	const dispatch = useDispatch();

	const { orders } = useSelector(state => state.orderReducer);

	const orderService = new OrderService();

	useEffect(() => {
		if (props.isActive) {
			setSelectedDeliveryOption(props.order.deliveryType);
		}
	}, [props.isActive]);

	const onClose = submitted => {
		if (submitted) {
			orderService
				.update(props.order.id, selectedDeliveryOption)
				.then(response => {
					let updatedOrder;

					const updatedOrders = orders.map(order => {
						if (order.id === response.data.id) {
							order.deliveryType = response.data.deliveryType;
							updatedOrder = order;
						}

						return order;
					});

					dispatch(setOrders(updatedOrders));

					if (updatedOrder) dispatch(setCurrentOrder(updatedOrder));
				})
				.catch(() => {
					dispatch(addAlertMessage(DANGER, t(prefix + 'updateOrderFailedMessage')));
				});
		}

		props.onClose();
	};

	const getOptions = () => {
		return ALL_DELIVERY_OPTIONS.map(option => {
			return {
				id: option,
				value: t(deliveryOptionPrefix + option),
			};
		});
	};

	return (
		<ModalHolder isActive={props.isActive}>
			<Modal show={props.isActive} onHide={props.onClose} animation={true} className="modal">
				<Modal.Header>
					<Modal.Title>{t(prefix + 'header')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<SectionTitle content={t(prefix + 'deliveryOptionTitle')} />

					<Select
						name="delivery"
						options={getOptions()}
						value={selectedDeliveryOption}
						onChange={option => setSelectedDeliveryOption(option)}
					/>
				</Modal.Body>
				<Modal.Footer>
					<button className="button button--primary" type="button" onClick={() => onClose(true)}>
						{t(prefix + 'submitButton')}
					</button>
					<button type="button" className="button button--outline" onClick={() => onClose(false)}>
						{t(prefix + 'cancelButton')}
					</button>
				</Modal.Footer>
			</Modal>
		</ModalHolder>
	);
};

OrderModal.propTypes = {
	isActive: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	order: PropTypes.instanceOf(Order),
};
