import { Piece } from '../../internal';
import { Vector3 } from 'three';
import { BACK, FRONT, LEFT, RIGHT } from '../../constants/ObjectSides';

export class ChiseledPiece extends Piece {
	constructor(id, dimensions = { length: 0, height: 0, width: 0 }, operations = [], position = new Vector3()) {
		super(id, dimensions, operations, position);
	}

	__getSideLength(side) {
		let length;

		switch (side) {
			case FRONT:
				length = this.dimensions.length;
				break;
			case BACK:
				length = this.dimensions.length;
				break;
			case LEFT:
				length = this.dimensions.width;
				break;
			case RIGHT:
				length = this.dimensions.width;
				break;
			default:
				break;
		}

		return length;
	}
}
