import * as React from 'react';
import PropTypes from 'prop-types';
import { LoadingMessage } from '../../messages/LoadingMessage';
import { useSelector } from 'react-redux';
import { THREE_DIMENSIONAL, TWO_DIMENSIONAL } from '../../../constants/Values';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { useEffect, useRef, useState } from 'react';
import { BACK, BOTTOM, FRONT, TOP } from '../../../constants/ObjectSides';

export const Canvas3D = props => {
	const canvasHolder = useRef();

	const [canvasWidth, setCanvasWidth] = useState(0);
	const [canvasHeight, setCanvasHeight] = useState(0);
	const [activeAspect, setActiveAspect] = useState(TWO_DIMENSIONAL);

	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const viewPrefix = 'constants.objectSides.';

	const isLoading = useSelector(state => state.generalReducer.canvasIsLoading);

	useEffect(() => {
		window.addEventListener('resize', () => resizeCanvas(), false);

		resizeCanvas();
	}, []);

	const onAspectChange = aspect => {
		setActiveAspect(aspect);
		props.onAspectChange(aspect);
	};

	const resizeCanvas = () => {
		if (canvasHolder == null || canvasHolder.current == null) return;

		setCanvasWidth(canvasHolder.current.clientWidth);
		setCanvasHeight(canvasHolder.current.clientHeight);

		props.onResize(canvasHolder.current.clientWidth, canvasHolder.current.clientHeight);
	};

	const getAspectButtonStyling = aspect => {
		const defaultStyle = 'button button--small';
		const activeStyle = 'button--active';

		if (activeAspect === aspect) {
			return `${defaultStyle} ${activeStyle}`;
		} else {
			return defaultStyle;
		}
	};

	const getViewButtonStyling = side => {
		const defaultStyle = 'button button--small';
		const activeStyle = 'button--active';

		if (props.activeView === side) {
			return `${defaultStyle} ${activeStyle}`;
		} else {
			return defaultStyle;
		}
	};
	return (
		<div className="configuration__3D" ref={canvasHolder}>
			{isLoading ? <LoadingMessage variant="overlay" /> : null}

			<canvas ref={props.reference} id="configuratorCanvas" width={canvasWidth} height={canvasHeight} />

			<div className="configuration__3D__actions">
				<div className="configuration__3D__actions__inner">
					<div className="button-group m-r-2">
						{[TWO_DIMENSIONAL, THREE_DIMENSIONAL].map(aspect => {
							return (
								<button
									key={`aspectButton${aspect}`}
									className={getAspectButtonStyling(aspect)}
									type="button"
									onClick={() => onAspectChange(aspect)}
								>
									{aspect === THREE_DIMENSIONAL ? '3D' : '2D'}
								</button>
							);
						})}
					</div>

					<div className="button-group">
						{[TOP, BOTTOM, FRONT, BACK].map(side => {
							return (
								<button
									key={`viewButton${side}`}
									className={getViewButtonStyling(side)}
									type="button"
									onClick={() => props.onViewChange(side)}
								>
									{t(viewPrefix + side)}
								</button>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

Canvas3D.propTypes = {
	reference: PropTypes.any.isRequired,
	activeView: PropTypes.oneOfType([PropTypes.string, null]),
	onAspectChange: PropTypes.func.isRequired,
	onViewChange: PropTypes.func.isRequired,
	onResize: PropTypes.func.isRequired,
};
