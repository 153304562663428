import { Vector3 } from 'three';
import MeasurementPoints from '../../../models/MeasurementPoints';
import { BACK, FRONT, LEFT, RIGHT } from '../../../../constants/ObjectSides';
import { getXPosForSide, getYPosForSide, getZPosForSide } from '../SurfaceMeasurementLineHelper';

export function getPointsForNotch(pieceDimensions, notch) {
	let horizontalPoints;
	let verticalPoints;

	const linePosition = {
		xPos: getXPosForSide(pieceDimensions, notch.side),
		yPos: getYPosForSide(pieceDimensions) + 0.05,
		zPos: getZPosForSide(pieceDimensions, notch.side),
	};

	horizontalPoints = new MeasurementPoints(
		getHorizontalPointA(notch, linePosition),
		getHorizontalPointB(notch, linePosition),
	);

	if (notch.connectedCoupe != null) {
		horizontalPoints.visible = false;
	}

	verticalPoints = new MeasurementPoints(
		getVerticalPointA(notch, linePosition),
		getVerticalPointB(notch, linePosition),
	);

	verticalPoints.lineSide = getLineSide(notch.position, notch.side);
	verticalPoints.textSide = getTextSide(notch.position, notch.side);

	if (notch.side === BACK) {
		verticalPoints.textSide = BACK;
	}

	return {
		horizontal: horizontalPoints,
		vertical: verticalPoints,
	};
}

function getHorizontalPointA(notch, { xPos, yPos, zPos }) {
	switch (notch.side) {
		case FRONT:
			xPos = notch.position.x - notch.dimensions.length / 2;
			break;
		case LEFT:
			zPos = notch.position.z - notch.dimensions.length / 2;
			break;
		case BACK:
			xPos = notch.position.x + notch.dimensions.length / 2;
			break;
		case RIGHT:
			zPos = notch.position.z + notch.dimensions.length / 2;
			break;
	}

	return new Vector3(xPos, yPos, zPos);
}

function getHorizontalPointB(notch, { xPos, yPos, zPos }) {
	switch (notch.side) {
		case FRONT:
			xPos = notch.position.x + notch.dimensions.length / 2;
			break;
		case LEFT:
			zPos = notch.position.z + notch.dimensions.length / 2;
			break;
		case BACK:
			xPos = notch.position.x - notch.dimensions.length / 2;
			break;
		case RIGHT:
			zPos = notch.position.z - notch.dimensions.length / 2;
			break;
	}

	return new Vector3(xPos, yPos, zPos);
}

function getVerticalPointA(notch, { xPos, yPos, zPos }) {
	switch (notch.side) {
		case FRONT:
		case BACK:
			if (notch.position.x > 0) {
				xPos = notch.position.x - notch.dimensions.length / 2;
			} else {
				xPos = notch.position.x + notch.dimensions.length / 2;
			}
			break;
		case LEFT:
		case RIGHT:
			if (notch.position.z > 0) {
				zPos = notch.position.z - notch.dimensions.length / 2;
			} else {
				zPos = notch.position.z + notch.dimensions.length / 2;
			}
			break;
	}

	return new Vector3(xPos, yPos, zPos);
}

function getVerticalPointB(notch, { xPos, yPos, zPos }) {
	switch (notch.side) {
		case FRONT:
			if (notch.position.x > 0) {
				xPos = notch.position.x - notch.dimensions.length / 2;
			} else {
				xPos = notch.position.x + notch.dimensions.length / 2;
			}
			zPos -= notch.dimensions.width;
			break;
		case LEFT:
			xPos += notch.dimensions.width;
			if (notch.position.z > 0) {
				zPos = notch.position.z - notch.dimensions.length / 2;
			} else {
				zPos = notch.position.z + notch.dimensions.length / 2;
			}
			break;
		case BACK:
			if (notch.position.x > 0) {
				xPos = notch.position.x - notch.dimensions.length / 2;
			} else {
				xPos = notch.position.x + notch.dimensions.length / 2;
			}
			zPos += notch.dimensions.width;
			break;
		case RIGHT:
			xPos -= notch.dimensions.width;
			if (notch.position.z > 0) {
				zPos = notch.position.z - notch.dimensions.length / 2;
			} else {
				zPos = notch.position.z + notch.dimensions.length / 2;
			}
			break;
	}

	return new Vector3(xPos, yPos, zPos);
}

function getLineSide(position, side) {
	let lineSide;

	switch (side) {
		case FRONT:
			if (position.x > 0) {
				lineSide = LEFT;
			} else {
				lineSide = RIGHT;
			}
			break;
		case LEFT:
			if (position.z > 0) {
				lineSide = LEFT;
			} else {
				lineSide = RIGHT;
			}
			break;
		case BACK:
			if (position.x > 0) {
				lineSide = LEFT;
			} else {
				lineSide = RIGHT;
			}
			break;
		case RIGHT:
			if (position.z > 0) {
				lineSide = RIGHT;
			} else {
				lineSide = LEFT;
			}
			break;
	}

	return lineSide;
}

function getTextSide(position, side) {
	let textSide;

	switch (side) {
		case FRONT:
			if (position.x > 0) {
				textSide = BACK;
			} else {
				textSide = FRONT;
			}
			break;
		case LEFT:
			if (position.z > 0) {
				textSide = BACK;
			} else {
				textSide = FRONT;
			}
			break;
		case BACK:
			if (position.x > 0) {
				textSide = FRONT;
			} else {
				textSide = BACK;
			}
			break;
		case RIGHT:
			if (position.z > 0) {
				textSide = FRONT;
			} else {
				textSide = BACK;
			}
			break;
	}

	return textSide;
}
