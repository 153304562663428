import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import Autosuggest from 'react-autosuggest';
import Customer from '../../classes/models/Customer';
import { useForm } from 'react-hook-form';
import { parseNumber } from '../../classes/helpers/StringHelper';
import { ValidatedInput } from '../forms/inputs/ValidatedInput';
import { ModalHolder } from './ModalHolder';
import { Select } from '../forms/selects/Select';
import { hasOneOfRoles } from '../../classes/helpers/UserHelper';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentCompanyBranch } from '../../actions/CompanyActions';
import { Offer } from '../../classes/models/Offer';
import { ADMIN, SALES, SUPERVISOR, USER } from '../../constants/RoleNames';
import { BELGIUM } from '../../constants/Countries';
import { SectionTitle } from '../headers/SectionTitle';
import { CheckboxWithIcon } from '../forms/inputs/CheckboxWithIcon';

function AddOfferModal(props) {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'modals.addOfferModal.';

	// Adsolut allows 160 characters but we add 20 programmatically
	const MAX_CHARACTER_COUNT = 40;

	const [customerInputValue, setCustomerInputValue] = useState('');
	const [suggestions, setSuggestions] = useState([]);
	const [autoSuggestIsFocused, setAutoSuggestIsFocused] = useState(false);
	const [selectedCompanyBranch, setSelectedCompanyBranch] = useState(null);
	const [isVisible, setIsVisible] = useState(false);

	const { errors, register, handleSubmit } = useForm();

	const { currentCompanyBranch } = useSelector(state => state.companyReducer);
	const dispatch = useDispatch();

	useEffect(() => {
		setSelectedCompanyBranch(currentCompanyBranch);

		if (props.isActive) {
			setIsVisible(props.offer.isVisible);
		} else {
			setIsVisible(false);
		}
	}, [props.isActive]);

	useEffect(() => {
		setCustomerInputValue(props.offer.customer?.name ?? '');
	}, [props.offer.customer]);

	const getVatPercentage = () => {
		let country;

		if (selectedCompanyBranch) {
			country = selectedCompanyBranch.address.country;
		} else {
			country = currentCompanyBranch?.address.country;
		}

		if (country === BELGIUM) {
			return 21;
		}

		return 0;
	};

	const submit = data => {
		if (autoSuggestIsFocused) return;

		let customer = props.customers.find(c => c.name === customerInputValue);

		if (!customer && customerInputValue !== '') {
			customer = new Customer(0, customerInputValue);
		}

		// Set default vat when there is no percentage given
		if (data.vat === '') {
			data.vat = getVatPercentage();
		}

		if (currentCompanyBranch && selectedCompanyBranch && currentCompanyBranch.id !== selectedCompanyBranch.id) {
			dispatch(setCurrentCompanyBranch(selectedCompanyBranch));
		}

		const offer = Object.assign(new Offer(), props.offer);
		offer.name = data.offerName;
		offer.customer = customer;
		offer.margin = parseNumber(data.margin);
		offer.vat = parseNumber(data.vat);
		offer.isVisible = isVisible;

		props.onClose(offer, selectedCompanyBranch?.id);
	};

	const updateSelectedCompanyBranch = branchId => {
		const branch = props.companyBranches.find(b => b.id === parseInt(branchId));

		setSelectedCompanyBranch(branch);
		dispatch(setCurrentCompanyBranch(branch));
	};

	const renderFooterButtons = () => {
		let ctaButtonText = t(prefix + 'submitButton');

		if (props.isEditing) {
			ctaButtonText = t(prefix + 'editButton');
		}

		return (
			<>
				<button
					className="button button--primary"
					type="submit"
					form="addOfferForm"
					data-cy="addOfferModalForm-submitButton"
				>
					{ctaButtonText}
				</button>
				<button
					data-cy="addOfferModalForm-cancelButton"
					className="button button--outline"
					onClick={() => props.onClose()}
				>
					{t(prefix + 'cancelButton')}
				</button>
			</>
		);
	};

	const getHeadText = () => {
		let headerText = t(prefix + 'createHeader');

		if (props.isEditing) {
			headerText = t(prefix + 'editHeader');
		}

		return headerText;
	};

	const getSuggestions = value => {
		const inputValue = value.trim().toLowerCase();

		let list;

		if (inputValue.length === 0) {
			list = [];
		} else {
			list = props.customers.filter(customer => customer.name.toLowerCase().includes(inputValue));
		}

		return list;
	};

	const getSuggestionValue = suggestion => suggestion.name;

	const renderSuggestion = suggestion => <div className="list__item">{suggestion.name}</div>;

	const onSuggestionChange = (event, { newValue }) => {
		setCustomerInputValue(newValue);
	};

	const onSuggestionsFetchRequested = ({ value }) => {
		setSuggestions(getSuggestions(value));
	};

	const onSuggestionsClearRequested = () => {
		setSuggestions([]);
	};

	const suggestionsInputProps = {
		placeholder: t(prefix + 'yardPlaceholder'),
		value: customerInputValue,
		onChange: onSuggestionChange,
		onFocus: () => setAutoSuggestIsFocused(true),
		onBlur: () => setAutoSuggestIsFocused(false),
		'data-cy': 'addOfferModalForm-yard',
	};

	const getCompanyBranchOptions = () => {
		return props.companyBranches.map(branch => {
			return { id: branch.id, value: branch.name };
		});
	};

	const shouldRenderBranchSelect = () => {
		let shouldRender = false;

		if (hasOneOfRoles([ADMIN, SUPERVISOR, SALES])) {
			if (props.isEditing) {
				if (props.offer.isCreatedByAdmin) shouldRender = true;
			} else {
				shouldRender = true;
			}
		}

		return shouldRender;
	};

	return (
		<ModalHolder isActive={props.isActive}>
			<Modal
				show={props.isActive}
				onHide={() => props.onClose()}
				animation={false}
				className="modal--prompt"
				data-cy="add-offer-modal"
			>
				<Modal.Header>
					<Modal.Title>{getHeadText()}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form id="addOfferForm" data-cy="addOfferModalForm" onSubmit={handleSubmit(submit)}>
						{shouldRenderBranchSelect() && (
							<Select
								label={t(prefix + 'companyBranchSelectLabel')}
								name="companyBranchSelect"
								value={selectedCompanyBranch?.id}
								options={getCompanyBranchOptions()}
								onChange={branch => updateSelectedCompanyBranch(branch)}
							/>
						)}

						<ValidatedInput
							register={register}
							name="offerName"
							label={t(prefix + 'label')}
							placeholder={t(prefix + 'placeholder')}
							value={props.offer.name}
							autoFocus={true}
							required={true}
							maxLength={MAX_CHARACTER_COUNT - customerInputValue.length}
							error={errors.offerName}
							dataCy="addOfferModalForm-name"
						/>

						<div className="form-group">
							<label data-cy="addOfferModalForm-yard-label">{t(prefix + 'yardLabel')}</label>
							<Autosuggest
								suggestions={suggestions}
								onSuggestionsFetchRequested={onSuggestionsFetchRequested}
								onSuggestionsClearRequested={onSuggestionsClearRequested}
								getSuggestionValue={getSuggestionValue}
								renderSuggestion={renderSuggestion}
								inputProps={suggestionsInputProps}
							/>
							<small>{t(prefix + 'yardSmallText')}</small>
						</div>

						<div className="row">
							<div className="col-6">
								<div className="form-group">
									<ValidatedInput
										name="margin"
										register={register}
										label={t(prefix + 'marginLabel')}
										placeholder={t(prefix + 'marginPlaceholder')}
										value={props.offer.margin ? props.offer.margin : null}
										max={500}
										error={errors.margin}
										dataCy="addOfferModalForm-margin"
									/>
								</div>
							</div>

							<div className="col-6">
								<ValidatedInput
									name="vat"
									label={t(prefix + 'vatLabel')}
									placeholder={t(prefix + 'vatPlaceholder', { vatPercentage: getVatPercentage() })}
									value={props.offer.vat}
									register={register}
									max={100}
									error={errors.vat}
									smallText={t(prefix + 'vatInfoText', { vatPercentage: getVatPercentage() })}
									dataCy="addOfferModalForm-vat"
								/>
							</div>
						</div>

						{!hasOneOfRoles(USER) && (
							<>
								<SectionTitle content={t(prefix + 'visibilityTitle')} />

								<div className="row">
									<div className="col-12">
										<div className="form-group">
											<CheckboxWithIcon
												dataCy={'isOfferVisibleCheckbox'}
												onChange={() => setIsVisible(!isVisible)}
												content={t(prefix + 'visibilityLabel')}
												checked={isVisible}
											/>
										</div>
									</div>
								</div>
							</>
						)}
					</form>
				</Modal.Body>
				<Modal.Footer>{renderFooterButtons()}</Modal.Footer>
			</Modal>
		</ModalHolder>
	);
}

AddOfferModal.propTypes = {
	isActive: PropTypes.bool.isRequired,
	offer: PropTypes.instanceOf(Offer).isRequired,
	onClose: PropTypes.func.isRequired,
	isEditing: PropTypes.bool,
	companyBranches: PropTypes.array,
	customers: PropTypes.array,
};

AddOfferModal.defaultProps = {
	name: '',
	isEditing: false,
	companyBranches: [],
};

export { AddOfferModal };
