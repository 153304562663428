import { Service } from './Service';
import { Parser } from '../helpers/Parser';

class CatalogService extends Service {
	async getCoverImage(catalogId) {
		return await this.fileRequest('get', 'catalogs/' + catalogId)
			.then(response => {
				return new Blob([response.data], { type: 'image/*' });
			})
			.catch(error => {
				throw error;
			});
	}

	async create(manufacturerId, name, file) {
		const data = new FormData();
		data.append('manufacturerId', manufacturerId);
		data.append('name', name);
		data.append('file', file);

		return await this.authenticatedRequest('post', 'catalogs', data).then(response => {
			if (response.data.success) {
				response.data.data = Parser.parseCatalog(response.data.data);
			}

			return response.data;
		});
	}

	async downloadFile(catalogId, name) {
		return await this.fileRequest('get', 'catalogs/' + catalogId + '/download')
			.then(response => {
				const pdfFile = new Blob([response.data], { type: 'application/pdf' });
				//Build a URL from the file
				const fileURL = URL.createObjectURL(pdfFile);
				const link = document.createElement('a');
				link.href = fileURL;
				link.setAttribute('download', name + '.pdf'); //or any other extension
				document.body.appendChild(link);
				link.click();
			})
			.catch(error => {
				throw error;
			});
	}

	async updateFile(id, file) {
		const data = new FormData();
		data.append('file', file);

		return await this.authenticatedRequest('post', 'catalogs/' + id, data).then(response => {
			if (response.data.success) {
				response.data.data = Parser.parseCatalog(response.data.data);
			}

			return response.data;
		});
	}

	async updateName(id, name) {
		const data = {
			name: name,
		};

		return await this.authenticatedRequest('put', 'catalogs/' + id, data).then(response => {
			if (response.data.success) {
				response.data.data = Parser.parseCatalog(response.data.data);
			}

			return response.data;
		});
	}

	async delete(id) {
		return await this.authenticatedRequest('delete', 'catalogs/' + id).then(response => {
			return response.data;
		});
	}
}

export default CatalogService;
