// General actions

export const SET_DOMAIN = 'SET_DOMAIN';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_USER_SETTINGS = 'SET_USER_SETTINGS';
export const ADD_ALERT_MESSAGE = 'ADD_ALERT_MESSAGE';
export const REMOVE_ALERT_MESSAGE = 'REMOVE_ALERT_MESSAGE';
export const SET_CANVAS_IS_LOADING = 'SET_CANVAS_IS_LOADING';
export const SET_FORMS_ARE_LOADING = 'SET_FORMS_ARE_LOADING';
export const SET_LIST_IS_LOADING = 'SET_LIST_IS_LOADING';
export const SET_SCREEN_IS_LOADING = 'SET_SCREEN_IS_LOADING';
export const SET_WINDOW_IS_LOADING = 'SET_WINDOW_IS_LOADING';
export const SET_PRICE_IS_LOADING = 'SET_PRICE_IS_LOADING';
export const SET_SEARCH_KEYWORD = 'SET_SEARCH_KEYWORD';
export const SET_SETTINGS = 'SET_SETTINGS';
export const SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED';
export const SET_REFRESH_MODAL_IS_ACTIVE = 'SET_REFRESH_MODAL_IS_ACTIVE';

export const SET_ACTIVE_OPERATION_MODAL = 'SET_ACTIVE_OPERATION_MODAL';
export const SET_ACTIVE_INFO_MODAL = 'SET_ACTIVE_INFO_MODAL';

export const SET_SHOULD_UPDATE_LOGO = 'SET_SHOULD_UPDATE_LOGO';

export const SET_SHOULD_UPDATE_PRICE = 'SET_SHOULD_UPDATE_PRICE';

export const SET_PRICE_TYPE = 'SET_PRICE_TYPE';

export const SET_CAN_EDIT = 'SET_CAN_EDIT';
export const SET_CONFIRMATION_MODAL = 'SET_CONFIRMATION_MODAL';
export const SET_CONFIGURATOR_UNSAVED_DIMENSION = 'SET_CONFIGURATOR_UNSAVED_DIMENSION';

export const SET_CURRENT_OFFER = 'SET_CURRENT_OFFER';

export const SET_CURRENT_CONFIGURATION = 'SET_CURRENT_CONFIGURATION';
export const SET_CURRENT_PIECE = 'SET_CURRENT_PIECE';
export const SET_CURRENT_OPERATION = 'SET_CURRENT_OPERATION';

export const SET_CURRENT_PHASE = 'SET_CURRENT_PHASE';
export const SET_ORDER_COMMENT = 'SET_ORDER_COMMENT';
export const SET_CURRENT_ORDER = 'SET_CURRENT_ORDER';
export const SET_ORDERS = 'SET_ORDERS';
export const SET_PRICE_VERSION = 'SET_PRICE_VERSION';
export const SET_ORDER_PAGINATION_SETTINGS = 'SET_ORDER_PAGINATION_SETTINGS';

// Shopping cart

export const ADD_TO_SHOPPING_CART = 'ADD_TO_SHOPPING_CART';
export const REMOVE_FROM_SHOPPING_CART = 'REMOVE_FROM_SHOPPING_CART';

// Manufacturers

export const SET_CURRENT_MANUFACTURER = 'SET_CURRENT_MANUFACTURER';

// GalleryImages

export const SET_IMAGES = 'SET_IMAGES';

// Companies

export const SET_COMPANY = 'SET_COMPANY';
export const SET_SELECTED_COMPANY = 'SET_SELECTED_COMPANY';
export const SET_CURRENT_COMPANY_BRANCH = 'SET_CURRENT_COMPANY_BRANCH';

// outdoorShop

export const SET_ARTICLES = 'SET_ARTICLES';
export const SET_ARTICLE_MODAL_ACTIVE = 'SET_ARTICLE_MODAL_ACTIVE';
export const SET_ACTIVE_ARTICLE = 'SET_ACTIVE_ARTICLE';
export const SET_ARTICLE_CONFIG = 'SET_ARTICLE_CONFIG';
export const ADD_ARTICLE_TO_SORTED = 'ADD_ARTICLE_TO_SORTED';

// Configurator

export const SET_CONFIGURATOR_STATUS = 'SET_CONFIGURATOR_STATUS';
export const SET_CONFIGURABLE_TYPE = 'SET_CONFIGURABLE_TYPE';
