import * as React from 'react';
import { AppContent } from '../../layout/AppContent';
import { OrderDetailAppHeader } from './OrderDetailAppHeader';
import PropTypes from 'prop-types';
import { Order } from '../../../classes/models/Order';
import { parseToCommaSeparated } from '../../../classes/helpers/StringHelper';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';

export const AdsolutOrderDetail = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'pages.orders.adsolutOrderDetail.';

	return (
		<AppContent>
			<OrderDetailAppHeader
				order={props.order}
				onEditClick={() => props.onEditOrderClick()}
				onDeliveryDateUpdateClick={() => props.onDeliveryDateUpdateClick()}
			/>

			<div className="p-l-4 p-r-4 p-t-2">
				<table className="table--bordered">
					<thead>
						<tr>
							<th>{t(prefix + 'productCodeTableHeader')}</th>
							<th>{t(prefix + 'descriptionTableHeader')}</th>
							<th>{t(prefix + 'amountTableHeader')}</th>
							<th>{t(prefix + 'basePriceTableHeader')}</th>
							<th>{t(prefix + 'discountTableHeader')}</th>
							<th>{t(prefix + 'unitPriceTableHeader')}</th>
							<th>{t(prefix + 'totalTableHeader')}</th>
							<th>{t(prefix + 'vatTableHeader')}</th>
						</tr>
					</thead>
					<tbody>
						{props.order.adsolutData.map((data, index) => {
							return (
								<tr key={index}>
									<td width="10%">{data.productCode}</td>
									<td width="50%" className="break-lines">
										{data.description}
									</td>
									<td width="5%">{`${parseToCommaSeparated(data.amount, 2)} ${data.unitType}`}</td>
									<td width="5%">€ {parseToCommaSeparated(data.basePrice, 2)}</td>
									<td width="5%">{data.discount}</td>
									<td width="10%">€ {parseToCommaSeparated(data.unitPrice, 2)}</td>
									<td width="10%">€ {parseToCommaSeparated(data.total, 2)}</td>
									<td width="5%">{props.order.vat} %</td>
								</tr>
							);
						})}

						<tr>
							<td colSpan={8} className="offer__table__divider" />
						</tr>

						<tr>
							<td colSpan={5}></td>
							<td>
								<strong>{t(prefix + 'totalTableHeader')}</strong>
							</td>
							<td colSpan={2}>€ {parseToCommaSeparated(props.order.getPrice(), 2)}</td>
						</tr>

						<tr>
							<td colSpan={5}></td>
							<td>
								<strong>{t(prefix + 'totalWithVat')}</strong>
							</td>
							<td colSpan={2}>€ {parseToCommaSeparated(props.order.getPrice() * (1 + props.order.vat / 100), 2)}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</AppContent>
	);
};

AdsolutOrderDetail.propTypes = {
	order: PropTypes.instanceOf(Order),
	onEditOrderClick: PropTypes.func.isRequired,
	onDeliveryDateUpdateClick: PropTypes.func.isRequired,
};
