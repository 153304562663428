import {
	CORNER_CUTOUT,
	COUPE,
	COUPE_OVER_LENGTH,
	DEBASING_ROUGH,
	DRILL_HOLE,
	GLUED_CUSHION,
	GROOVES,
	HEIGHT_COUPE,
	NOTCH,
	NOTCH_OVER_LENGTH,
	PROFILE,
	RABAT,
	RECTANGULAR_CUT_OUT,
	ROUNDED_CORNER,
	SANDBLASTING,
} from '../../constants/OperationTypes';
import { BACK, FRONT, LEFT, TOP } from '../../constants/ObjectSides';

export function getAspectsForOperationByPreset(preset, operation) {
	let aspects;

	switch (operation.type) {
		case NOTCH:
			aspects = getAspectsForNotch();
			break;
		case CORNER_CUTOUT:
			aspects = getAspectsForCornerCutout();
			break;
		case NOTCH_OVER_LENGTH:
			aspects = getAspectsForNotchOverLength();
			break;
		case COUPE:
			aspects = getAspectsForCoupe();
			break;
		case ROUNDED_CORNER:
			aspects = getAspectsForRoundedCoupe();
			break;
		case PROFILE:
			aspects = getAspectsForProfile(operation);
			break;
		case RABAT:
			aspects = getAspectsForRabat(operation);
			break;
		case COUPE_OVER_LENGTH:
			aspects = getAspectsForCoupeOverLength();
			break;
		case DRILL_HOLE:
			aspects = getAspectsForDrillHole();
			break;
		case HEIGHT_COUPE:
			aspects = getAspectsForHeightCoupe(operation);
			break;
		case GLUED_CUSHION:
			aspects = getAspectsForGluedCushion(operation);
			break;
		case DEBASING_ROUGH:
			aspects = getAspectsForDebasingRough();
			break;
		case GROOVES:
			aspects = getAspectsForGrooves();
			break;
		case RECTANGULAR_CUT_OUT:
			aspects = getAspectsForRectangularCutout();
			break;
		case SANDBLASTING:
			aspects = [TOP];
			break;
		default:
			aspects = [];
			break;
	}

	return aspects;
}

function getAspectsForNotch() {
	return [TOP];
}

function getAspectsForProfile(operation) {
	const aspects = [TOP];

	if ([FRONT, BACK].includes(operation.side)) {
		aspects.push(LEFT);
	} else {
		// LEFT or RIGHT
		aspects.push(FRONT);
	}

	return aspects;
}

function getAspectsForCornerCutout() {
	return [TOP];
}

function getAspectsForNotchOverLength() {
	return [TOP, LEFT];
}

function getAspectsForCoupe() {
	return [TOP];
}

function getAspectsForRoundedCoupe() {
	return [TOP];
}

function getAspectsForRabat({ side }) {
	const aspects = [TOP];

	if ([FRONT, BACK].includes(side)) {
		aspects.push(LEFT);
	} else {
		// LEFT or RIGHT
		aspects.push(FRONT);
	}

	return aspects;
}

function getAspectsForCoupeOverLength() {
	return [TOP];
}

function getAspectsForDrillHole() {
	return [TOP];
}

function getAspectsForHeightCoupe(operation) {
	const aspects = [TOP];

	if ([FRONT, BACK].includes(operation.side)) {
		aspects.push(LEFT);
	} else {
		// LEFT or RIGHT
		aspects.push(FRONT);
	}

	return aspects;
}

function getAspectsForGluedCushion(operation) {
	return [TOP, operation.side];
}

function getAspectsForDebasingRough() {
	return [LEFT];
}

function getAspectsForGrooves() {
	return [TOP];
}

function getAspectsForRectangularCutout() {
	return [TOP];
}
