import { VectorHelper } from './VectorHelper';
import * as ObjectSides from '../../constants/ObjectSides';
import { ArrowHelper, Mesh, MeshBasicMaterial, SphereGeometry, Vector3 } from 'three';
import * as ThreeConstants from '../../constants/ThreeConstants';
import {
	CHISELED_SIDE,
	FINISHED_SIDE,
	SANDBLASTING,
	SURFACE_OPERATIONS_TO_SHOW_MARKS_FOR,
} from '../../constants/OperationTypes';
import {
	BASEBOARDS,
	CUSHIONS,
	ENTREPORTS,
	FINISHED_SIDES_INCLUDED_IN_PRICE,
	STAIR_RISERS,
	WALL_SLABS,
} from '../../constants/Presets';
import { cloneDeep } from 'lodash';

const standardMarkSize = 3;
const minimumMarkSize = 2;

export function createMarkersForFinishedSides(piece, standardSides, markSize = null) {
	let markList = [];

	getSidesToMark(piece).forEach(operation => {
		const side = operation.side;
		const type = operation.additionalDimension.type;
		let markPosition = VectorHelper.getVectorForFinishedSideMark(piece, operation);
		let direction = getMarkDirectionBySide(side);

		if (direction) {
			const isStandard = !!standardSides.find(f => f.name === side && f.type === type);

			if (!markSize) markSize = calculateMarkSize(piece.dimensions.length);
			if (markSize < minimumMarkSize) markSize = minimumMarkSize;
			const mark = createMark(
				operation.type,
				markPosition,
				direction,
				markSize,
				getMarkColor(isStandard, operation.type),
			);

			markList.push(mark);
		}
	});

	return markList;
}

export function calculateMarkSize(length) {
	return standardMarkSize * (length / 120);
}

export function getSidesIncludedInPrice(piece, preset) {
	// Clone so we don't modify the original, otherwise the front side will not be marked as standard if it is the only
	// long side selected
	const standardIncludedSides = cloneDeep(getIncludedSidesPerPreset(preset));

	const longSides = piece.getFinishedSides.filter(s => s.name === ObjectSides.FRONT || s.name === ObjectSides.BACK);
	const isBackSideSelected = !!longSides.filter(s => s.name === ObjectSides.BACK).length;

	if (longSides.length === 1 && isBackSideSelected) {
		return standardIncludedSides.map(s => {
			if (s.name === ObjectSides.FRONT) {
				s.name = ObjectSides.BACK;
			}

			return s;
		});
	}

	return standardIncludedSides;
}

function createArrow(position, direction, color, size) {
	const arrow = new ArrowHelper(direction, direction, size + 0.01, color, size, size);
	arrow.position.add(position);

	return arrow;
}

function createSandblastingMark(position, size, color) {
	// Is more performant to reuse the mesh instead of creating a new one for every mark
	// 12 widthSegments so the sphere looks a bit smoother from the top view
	const mark = new Mesh(new SphereGeometry(size, 12), new MeshBasicMaterial({ color: color }));
	mark.position.add(position);

	return mark;
}

function getMarkDirectionBySide(side) {
	let direction;

	switch (side) {
		case ObjectSides.FRONT:
			direction = new Vector3(0, 0, -1);
			break;
		case ObjectSides.LEFT:
			direction = new Vector3(1, 0, 0);
			break;
		case ObjectSides.BACK:
			direction = new Vector3(0, 0, 1);
			break;
		case ObjectSides.RIGHT:
			direction = new Vector3(-1, 0, 0);
			break;
		case ObjectSides.TOP:
			// No arrow needed
			break;
		case ObjectSides.BOTTOM:
			// No arrow needed
			break;
		default:
			break;
	}

	return direction;
}

function getSidesToMark(piece) {
	return piece.operations.filter(o =>
		[...SURFACE_OPERATIONS_TO_SHOW_MARKS_FOR, FINISHED_SIDE, CHISELED_SIDE].includes(o.type),
	);
}

function getMarkColor(isStandard, operationType) {
	if (SURFACE_OPERATIONS_TO_SHOW_MARKS_FOR.includes(operationType)) {
		if (operationType === SANDBLASTING) return ThreeConstants.SANDBLASTING_MARKER_COLOR;

		return ThreeConstants.SURFACE_OPERATION_ARROW_COLOR;
	}

	return isStandard ? ThreeConstants.STANDARD_ARROW_COLOR : ThreeConstants.EXTRA_ARROW_COLOR;
}

function createMark(operationType, position, direction, size, color) {
	if (operationType === SANDBLASTING) {
		return createSandblastingMark(position, size, color);
	}

	return createArrow(position, direction, color, size);
}

function getIncludedSidesPerPreset(preset) {
	let includedInPrice;

	switch (preset) {
		case ENTREPORTS:
			includedInPrice = FINISHED_SIDES_INCLUDED_IN_PRICE.ENTREPORTS;
			break;
		case BASEBOARDS:
			includedInPrice = FINISHED_SIDES_INCLUDED_IN_PRICE.BASEBOARDS;
			break;
		case CUSHIONS:
			includedInPrice = FINISHED_SIDES_INCLUDED_IN_PRICE.CUSHIONS;
			break;
		case STAIR_RISERS:
			includedInPrice = FINISHED_SIDES_INCLUDED_IN_PRICE.STAIR_RISERS;
			break;
		case WALL_SLABS:
			includedInPrice = FINISHED_SIDES_INCLUDED_IN_PRICE.WALL_SLABS;
			break;
		default:
			includedInPrice = FINISHED_SIDES_INCLUDED_IN_PRICE.DEFAULT;
			break;
	}

	return includedInPrice;
}
