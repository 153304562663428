// @flow
import * as React from 'react';
import PropTypes from 'prop-types';
import { COLLECT } from '../../../constants/DeliveryOptions';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import moment from 'moment/moment';
import ReactTooltip from 'react-tooltip';
import { Order } from '../../../classes/models/Order';

export const DeliveryDateInfo = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'pages.orders.orderStatus.';

	const getDate = () => {
		if (props.order.deliveryDate.date) {
			if (props.order.deliveryType === COLLECT) {
				return moment(props.order.deliveryDate.date).format('DD/MM/YY - HH:mm');
			} else {
				return moment(props.order.deliveryDate.date).format('DD/MM/YY');
			}
		} else {
			return t(prefix + 'toBeDetermined');
		}
	};

	const getTooltipContent = () => {
		let content;

		if (props.order.deliveryDate.id) {
			content = t(prefix + 'tooltipDeliveryDate');
		} else {
			content = t(prefix + 'tooltipDeliveryDateToBeDetermined');
		}

		return content;
	};

	return (
		<div>
			<span className="order-status__content" data-tip={getTooltipContent()}>
				<strong>{getDate()}</strong> <i className="material-icons color--blue m-l-1">info</i>
			</span>
			<ReactTooltip />
		</div>
	);
};

DeliveryDateInfo.propTypes = {
	order: PropTypes.instanceOf(Order),
};
