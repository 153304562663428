import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { Select } from '../forms/selects/Select';
import PropTypes from 'prop-types';

export const UserSelect = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'forms.selects.userSelect.';

	const [selectedUser, setSelectedUser] = useState(null);

	useEffect(() => {
		if (props.users.length && !selectedUser) {
			updateSelectedUser(props.users[0].id);
		}
	}, [props.users]);

	const getOptions = () => {
		return props.users.map(user => {
			return { id: user.id, value: user.getFullName() };
		});
	};

	const updateSelectedUser = userId => {
		const user = props.users.find(u => u.id === parseInt(userId));

		setSelectedUser(user);
		props.onSelect(user);
	};

	return (
		<Select
			dataCy="user-select"
			label={!props.hideLabel ? t(prefix + 'label') : ''}
			name="users"
			options={getOptions()}
			value={selectedUser?.id}
			onChange={userId => updateSelectedUser(userId)}
		/>
	);
};

UserSelect.propTypes = {
	users: PropTypes.array.isRequired,
	hideLabel: PropTypes.bool,
};

UserSelect.defaultProps = {
	users: [],
};
