import * as React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';

export const Title = props => {
	return (
		<div data-cy={props.dataCy} className="title">
			{props.children}
			{props.title}
			{props.hasBadge ? (
				<Badge data-cy={`${props.dataCy}-badge`} className="badge--red m-l-2">
					{props.badgeContent}
				</Badge>
			) : null}
		</div>
	);
};

Title.propTypes = {
	title: PropTypes.string,
	dataCy: PropTypes.string,
	hasBadge: PropTypes.bool,
	badgeContent: PropTypes.string,
};

Title.defaultProps = {
	hasBadge: false,
	badgeContent: '',
};
