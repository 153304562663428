import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import Company from '../../classes/models/Company';
import ClientTypeService from '../../classes/services/ClientTypeService';
import { useDispatch } from 'react-redux';
import { addAlertMessage, setWindowIsLoading } from '../../actions/GeneralActions';
import { DANGER, SUCCESS } from '../../constants/Variants';
import { useForm } from 'react-hook-form';
import CompanyService from '../../classes/services/CompanyService';
import { Message } from '../messages/Message';
import { ValidatedInput } from '../forms/inputs/ValidatedInput';
import { Select } from '../forms/selects/Select';
import { ModalHolder } from './ModalHolder';
import { ALL_DELIVERY_OPTIONS, COLLECT, DELIVERY } from '../../constants/DeliveryOptions';
import { ArticleSubstitutionGroupService } from '../../classes/services/ArticleSubstitutionGroupService';

export const CompanyModal = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'modals.companyModal.';
	const constantsPrefix = 'constants.deliveryOptions.';

	const [clientType, setClientType] = useState({});
	const [formIsValid, setFormIsValid] = useState(false);
	const [message, setMessage] = useState('');

	const [clientTypes, setClientTypes] = useState([]);
	const [articleSubstitutionGroups, setArticleSubstitutionGroups] = useState([]);
	const [selectedArticleSubstitutionGroup, setSelectedArticleSubstitutionGroup] = useState();
	const [deliveryOption, setDeliveryOption] = useState(DELIVERY);

	const { errors, register, handleSubmit } = useForm();

	const clientTypeService = new ClientTypeService();
	const articleSubstitutionGroupService = new ArticleSubstitutionGroupService();
	const companyService = new CompanyService();

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setWindowIsLoading(true));

		Promise.all([getClientTypes(), getArticleSubstitutionGroups()]).finally(() => dispatch(setWindowIsLoading(false)));
	}, []);

	useEffect(() => {
		if (props.isActive && props.company) {
			setClientType(props.company.clientType);
			setDeliveryOption(props.company.defaultDeliveryOption);
			setSelectedArticleSubstitutionGroup(props.company.articleSubstitutionGroup);
		} else {
			setClientType(clientTypes[0]);
			setDeliveryOption(DELIVERY);
			setSelectedArticleSubstitutionGroup();
		}
	}, [props.isActive]);

	useEffect(() => {
		if (clientType && !formIsValid) {
			setFormIsValid(true);
		}

		if (!clientType && formIsValid) {
			setFormIsValid(false);
		}
	}, [clientType]);

	const getClientTypes = async () => {
		return await clientTypeService
			.getAll()
			.then(response => {
				if (response.success) {
					setClientTypes(response.data);
				} else {
					throw Error(response.message);
				}
			})
			.catch(error => {
				dispatch(addAlertMessage(DANGER, t(prefix + 'getClientTypesFailed')));

				throw error;
			});
	};

	const getArticleSubstitutionGroups = async () => {
		return await articleSubstitutionGroupService
			.getAll()
			.then(response => {
				setArticleSubstitutionGroups(response.data);
			})
			.catch(error => {
				dispatch(addAlertMessage(DANGER, t(prefix + 'getArticleSubstitutionGroupsFailed')));

				throw error;
			});
	};

	const createCompany = company => {
		clearMessage();
		dispatch(setWindowIsLoading(true));

		companyService
			.create(company)
			.then(response => {
				if (response.success) {
					props.onClose(response.data);

					dispatch(addAlertMessage(SUCCESS, t(prefix + 'createCompanySuccess')));
				} else {
					if (response.errors?.vat_number) {
						setMessage(t(prefix + 'vatNumberAlreadyTaken'));
					} else {
						throw Error(response.message);
					}
				}
			})
			.catch(error => {
				dispatch(addAlertMessage(DANGER, t(prefix + 'createCompanyFailed')));
				throw error;
			})
			.finally(() => dispatch(setWindowIsLoading(false)));
	};

	const updateCompany = companyToUpdate => {
		clearMessage();
		dispatch(setWindowIsLoading(true));

		companyService
			.update(companyToUpdate)
			.then(response => {
				if (response.success) {
					props.onClose(response.data);

					dispatch(addAlertMessage(SUCCESS, t(prefix + 'updateCompanySuccess')));
				} else {
					if (response.errors?.vat_number) {
						setMessage(t(prefix + 'vatNumberAlreadyTaken'));
					} else {
						throw Error(response.message);
					}
				}
			})
			.catch(error => {
				dispatch(addAlertMessage(DANGER, t(prefix + 'updateCompanyFailed')));

				throw error;
			})
			.finally(() => dispatch(setWindowIsLoading(false)));
	};

	const clearMessage = () => {
		if (message !== '') {
			setMessage('');
		}
	};

	const getDeliveryOptionId = option => {
		if (option === COLLECT) return 1;
		if (option === DELIVERY) return 2;

		return 0;
	};

	const getDeliveryOptionById = id => {
		id = parseInt(id);

		if (id === 1) return COLLECT;
		if (id === 2) return DELIVERY;

		return COLLECT;
	};

	const getArticleSubstitutionGroupById = id => {
		return articleSubstitutionGroups.find(g => g.id === parseInt(id));
	};

	const onClose = fields => {
		if (fields) {
			let tempCompany = Object.assign(new Company(), props.company);

			tempCompany.name = fields.name;
			tempCompany.address = fields.address;
			tempCompany.vatNumber = fields.vatNumber;
			tempCompany.clientType = clientType.id ? clientType : clientTypes[0];
			// tempCompany.deliveryType = deliveryType ? deliveryType : deliveryTypes[0];
			tempCompany.defaultDeliveryOption = deliveryOption;
			tempCompany.articleSubstitutionGroup = selectedArticleSubstitutionGroup;

			// Check if a call has to be made, if there is no difference, just close
			if (!compareCompanies(tempCompany, props.company)) {
				if (props.company) {
					updateCompany(tempCompany);
				} else {
					createCompany(tempCompany);
				}

				return;
			}
		}

		props.onClose();
	};

	const onClientTypeChange = clientTypeId => {
		setClientType(clientTypes.find(type => type.id === parseInt(clientTypeId)));
	};

	const compareCompanies = (companyA, companyB) => {
		return JSON.stringify(companyA) === JSON.stringify(companyB);
	};

	return (
		<ModalHolder isActive={props.isActive}>
			<Modal show={props.isActive} onHide={() => onClose()} animation className="modal">
				<Modal.Header>
					<Modal.Title>{t(prefix + 'header')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{message !== '' ? (
						<div className="m-b-2">
							<Message content={message} variant={DANGER} />
						</div>
					) : null}
					<form id="companyForm" onSubmit={handleSubmit(onClose)}>
						<ValidatedInput
							register={register}
							error={errors.name}
							name="name"
							label={t(prefix + 'nameLabel')}
							placeholder={t(prefix + 'namePlaceholder')}
							value={props.company?.name}
							required={true}
							minLength={1}
							maxLength={100}
						/>

						<ValidatedInput
							register={register}
							error={errors.vatNumber}
							name="vatNumber"
							label={t(prefix + 'vatNumberLabel')}
							placeholder={t(prefix + 'vatNumberPlaceholder')}
							value={props.company?.vatNumber}
							required={true}
							minLength={10}
							maxLength={16}
						/>

						<ValidatedInput
							register={register}
							error={errors.address}
							name="address"
							label={t(prefix + 'addressLabel')}
							placeholder={t(prefix + 'addressPlaceholder')}
							value={props.company?.address}
							required={true}
							minLength={1}
							maxLength={100}
						/>

						<Select
							name="clientType"
							value={clientType?.id}
							label={t(prefix + 'clientTypeLabel')}
							options={clientTypes.map(type => {
								return { id: type.id, value: type.description };
							})}
							onChange={value => onClientTypeChange(value)}
						/>

						<Select
							name="deliveryOption"
							value={getDeliveryOptionId(deliveryOption)}
							label={t(prefix + 'deliveryOptionLabel')}
							options={ALL_DELIVERY_OPTIONS.map(option => {
								return { id: getDeliveryOptionId(option), value: t(constantsPrefix + option) };
							})}
							onChange={id => setDeliveryOption(getDeliveryOptionById(id))}
						/>

						<Select
							name="articleSubstitutionGroups"
							value={selectedArticleSubstitutionGroup?.id}
							label={t(prefix + 'articleSubstitutionLabel')}
							options={articleSubstitutionGroups.map(group => {
								return { id: group.id, value: group.name };
							})}
							onChange={id => setSelectedArticleSubstitutionGroup(getArticleSubstitutionGroupById(id))}
							defaultOptionText={t(prefix + 'defaultArticleSubstitutionOption')}
						/>
					</form>
				</Modal.Body>
				<Modal.Footer>
					<button className="button button--primary" type="submit" form="companyForm">
						{props.company?.id ? t(prefix + 'editButton') : t(prefix + 'addButton')}
					</button>
					<button className="button button--outline" onClick={() => onClose()}>
						{t(prefix + 'cancelButton')}
					</button>
				</Modal.Footer>
			</Modal>
		</ModalHolder>
	);
};

CompanyModal.propTypes = {
	isActive: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	company: PropTypes.instanceOf(Company),
};
