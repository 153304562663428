import * as React from 'react';
import PropTypes from 'prop-types';
import { Order } from '../../classes/models/Order';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { HANDELED, NEEDS_REVIEW, UNHANDELED } from '../../constants/AdministrativeStatuses';
import { hasOneOfRoles } from '../../classes/helpers/UserHelper';
import { ADMIN } from '../../constants/RoleNames';

export const OrderHandledButton = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'shared.orderHandledButton.';

	const getButtonColor = () => {
		let color;

		switch (props.order.administrativeStatus) {
			case UNHANDELED:
				color = 'button--danger';
				break;
			case NEEDS_REVIEW:
				color = 'button--warning';
				break;
			case HANDELED:
				color = 'button--success';
				break;
		}

		return color;
	};

	const getIcon = () => {
		let icon;

		switch (props.order.administrativeStatus) {
			case UNHANDELED:
				icon = 'close';
				break;
			case NEEDS_REVIEW:
				icon = 'visibility';
				break;
			case HANDELED:
				icon = 'check';
				break;
		}

		return icon;
	};

	const getToolTipContent = () => {
		if (!hasOneOfRoles(ADMIN)) return;

		let tooltipContent;

		switch (props.order.administrativeStatus) {
			case UNHANDELED:
				tooltipContent = 'tooltipMarkAsHandled';
				break;
			case NEEDS_REVIEW:
				tooltipContent = 'tooltipMarkAsReviewed';
				break;
			case HANDELED:
				tooltipContent = 'tooltipMarkAsUnHandled';
				break;
		}

		return t(prefix + tooltipContent);
	};

	return (
		<div className="d-flex align-items-center justify-center">
			<div
				className={`button button--small button--icon ${getButtonColor()}`}
				onClick={event => props.onClick(event)}
				data-tip={getToolTipContent()}
			>
				<i className="material-icons">{getIcon()}</i>
			</div>
		</div>
	);
};

OrderHandledButton.propTypes = {
	order: PropTypes.instanceOf(Order),
	onClick: PropTypes.func.isRequired,
};
