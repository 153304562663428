import { MAX_DELIVERY_LENGTH, MIN_DELIVERY_THICKNESS } from '../../constants/DeliveryOptions';

class Part {
	constructor(id, length) {
		this.id = id;
		this.length = length;
	}

	isNotDeliverable(height) {
		return this.length > MAX_DELIVERY_LENGTH && height <= MIN_DELIVERY_THICKNESS;
	}
}

export { Part };
