import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { ValidatedInput } from '../forms/inputs/ValidatedInput';
import { parseNumber } from '../../classes/helpers/StringHelper';
import { SectionTitle } from '../headers/SectionTitle';
import { INVERTED_DIMENSION_TYPES, REAR_HEEL_CUSTOM, REAR_HEEL_TYPES } from '../../constants/ObjectTypes';
import { Message } from '../messages/Message';
import { WARNING } from '../../constants/Variants';
import DimensionValidator from '../../classes/helpers/DimensionValidator';
import { HEIGHT_UPRIGHT, LENGTH, WIDTH } from '../../constants/Dimensions';
import { UPRIGHT_PRESETS } from '../../constants/Presets';
import { ModalHolder } from './ModalHolder';
import { INVALID_VALUE } from '../../constants/ValidationErrors';

export const AddPieceModal = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'modals.addPieceModal.';
	const constantsPrefix = 'constants.dimensions.';

	const [maxLength, setMaxLength] = useState();
	const [maxWidth, setMaxWidth] = useState();
	const [minWidth, setMinWidth] = useState();

	const { currentConfiguration } = useSelector(state => state.offerReducer);
	const configurationOptions = useSelector(state => state.offerReducer.currentConfiguration?.options);

	const { errors, register, handleSubmit, getValues, setError } = useForm();

	const dimensionValidator = new DimensionValidator();

	useEffect(() => {
		if (props.isActive && currentConfiguration) {
			setMaxLength(
				dimensionValidator.getMaxValue(getDimensionName(LENGTH, configurationOptions.type), configurationOptions),
			);
			setMaxWidth(
				dimensionValidator.getMaxValue(getDimensionName(WIDTH, configurationOptions.type), configurationOptions),
			);
			setMinWidth(
				dimensionValidator.getMinValue(getDimensionName(WIDTH, configurationOptions.type), configurationOptions),
			);
		}
	}, [props.isActive, configurationOptions]);

	const getDimensionName = (dimension, type) => {
		let dimensionName;

		if (INVERTED_DIMENSION_TYPES.includes(type)) {
			if (dimension === LENGTH) dimensionName = WIDTH;
			if (dimension === WIDTH) dimensionName = LENGTH;
		}

		return dimensionName ?? dimension;
	};

	const onClose = fields => {
		if (fields) {
			if (configurationOptions.isConfiguredForStock()) {
				const lengthError = dimensionValidator.validateStockDimension(LENGTH, fields.length);
				const widthError = dimensionValidator.validateStockDimension(WIDTH, fields.width);

				if (lengthError === INVALID_VALUE)
					setError('length', {
						type: INVALID_VALUE,
						message: t(prefix + 'invalidValueStockLength'),
						shouldFocus: true,
					});

				if (!lengthError && widthError === INVALID_VALUE)
					setError('width', {
						type: INVALID_VALUE,
						message: t(prefix + 'invalidValueStockWidth'),
						shouldFocus: true,
					});

				if (lengthError || widthError) return;
			}

			let values = getValues();

			if (INVERTED_DIMENSION_TYPES.includes(currentConfiguration?.options.type)) {
				// Inverted width and length
				fields.length = parseNumber(values.width);
				fields.width = parseNumber(values.length);
			} else {
				fields.length = parseNumber(fields.length);
				fields.width = parseNumber(fields.width);
			}

			props.onClose(fields);
			return;
		}

		props.onClose();
	};

	const widthInputIsDisabled = () => {
		return (
			currentConfiguration &&
			REAR_HEEL_TYPES.includes(currentConfiguration.options.type) &&
			currentConfiguration.options.type !== REAR_HEEL_CUSTOM
		);
	};

	const getWidthLabel = () => {
		let label;

		if (UPRIGHT_PRESETS.includes(currentConfiguration?.options.preset)) {
			label = t(constantsPrefix + HEIGHT_UPRIGHT);
		} else {
			label = t(constantsPrefix + WIDTH);
		}

		return label;
	};

	const renderFooterButtons = () => {
		return (
			<>
				<button type="submit" form="addPieceForm" className="button button--primary" data-cy={'addPieceModal-submit'}>
					{t(prefix + 'submitButton')}
				</button>
				<button
					type="button"
					className="button button--outline"
					onClick={() => onClose()}
					data-cy="addPieceModal-cancel"
				>
					{t(prefix + 'cancelButton')}
				</button>
			</>
		);
	};

	return (
		<ModalHolder isActive={props.isActive}>
			<Modal
				show={props.isActive}
				onHide={() => onClose()}
				animation={true}
				className="modal--prompt"
				data-cy="add-piece-modal"
			>
				<Modal.Header>
					<Modal.Title>{t(prefix + 'title')}</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<p>{t(`${prefix}informativeDescription`)}</p>

					<SectionTitle content={t(`${prefix}sectionTitle`)} />

					<form id="addPieceForm" onSubmit={handleSubmit(onClose)}>
						<ValidatedInput
							dataCy="addPieceModal-nameInput"
							register={register}
							name="name"
							value={''}
							label={t(prefix + 'nameLabel')}
							placeholder={t(prefix + 'namePlaceholder')}
							maxLength={10}
							error={errors.name}
						/>

						<div className="row">
							<div className="col-6">
								<ValidatedInput
									register={register}
									name="length"
									label={t(constantsPrefix + LENGTH)}
									placeholder="100 cm"
									required={true}
									min={dimensionValidator.getMinValue(LENGTH, configurationOptions)}
									max={maxLength}
									error={errors.length}
									dataCy="addPieceModal-lengthInput"
								/>
							</div>
							<div className="col-6">
								{widthInputIsDisabled() ? (
									<ValidatedInput
										register={register}
										name="width"
										label={getWidthLabel()}
										placeholder="20 cm"
										readonly={true}
										value={2}
										required={true}
										min={0.5}
										max={100}
										error={errors.width}
										dataCy="addPieceModal-widthInput"
									/>
								) : (
									<ValidatedInput
										register={register}
										name="width"
										label={getWidthLabel()}
										placeholder="20 cm"
										required={true}
										min={minWidth}
										max={maxWidth}
										error={errors.width}
										dataCy="addPieceModal-widthInput"
									/>
								)}
							</div>
						</div>

						{widthInputIsDisabled() ? (
							<Message content={t(prefix + 'widthInputDisabledMessage')} variant={WARNING} />
						) : null}
					</form>
				</Modal.Body>
				<Modal.Footer>{renderFooterButtons()}</Modal.Footer>
			</Modal>
		</ModalHolder>
	);
};

AddPieceModal.propTypes = {
	isActive: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	type: PropTypes.string,
};
