import { Service } from './Service';
import { Parser } from '../helpers/Parser';

class CustomerService extends Service {
	async get(companyBranchId = null) {
		return await this.authenticatedRequest('get', 'customers', { companyBranchId }).then(response => {
			if (response.data.success) {
				response.data.data = response.data.data.map(customerData => Parser.parseCustomer(customerData));
			}

			return response.data;
		});
	}
}

export default CustomerService;
