import { Service } from './Service';
import { Parser } from '../helpers/Parser';

class CompanyService extends Service {
	async getAll() {
		return await this.authenticatedRequest('get', 'companies').then(response => {
			if (response.data.success) {
				response.data.data = response.data.data.map(companyData => {
					return Parser.parseCompany(companyData);
				});
			}

			return response.data;
		});
	}

	async getCompanyOfCurrentUser() {
		return await this.authenticatedRequest('get', 'companies/current').then(response => {
			if (response.data.success) {
				response.data.data = Parser.parseCompany(response.data.data);
			}

			return response.data;
		});
	}

	async getUsers(companyId) {
		return await this.authenticatedRequest('get', 'companies/' + companyId + '/users').then(response => {
			if (response.data.success) {
				response.data.data = response.data.data.map(userData => {
					return Parser.parseUser(userData);
				});
			}

			return response.data;
		});
	}

	async create(company) {
		const data = parseCompanyToData(company);

		return await this.authenticatedRequest('post', 'companies', data).then(response => {
			if (response.data.success) {
				response.data.data = Parser.parseCompany(response.data.data);
			}

			return response.data;
		});
	}

	async update(company) {
		const data = parseCompanyToData(company);

		return await this.authenticatedRequest('put', 'companies/' + company.id, data).then(response => {
			if (response.data.success) {
				response.data.data = Parser.parseCompany(response.data.data);
			}

			return response.data;
		});
	}
}

function parseCompanyToData(company) {
	return {
		name: company.name,
		address: company.address,
		vat_number: company.vatNumber,
		client_type_id: company.clientType.id,
		default_delivery_option: company.defaultDeliveryOption,
		article_substitution_group: company.articleSubstitutionGroup?.id,
	};
}

export default CompanyService;
