import * as React from 'react';
import PropTypes from 'prop-types';
import { SectionTitle } from '../../headers/SectionTitle';
import { ConfigOption } from '../../forms/selects/ConfigOption';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import * as Materials from '../../../constants/Materials';
import bluestone from '../../../assets/img/configuration/bluestone.jpg';
import marble from '../../../assets/img/configuration/marble.jpg';
import granite from '../../../assets/img/configuration/granite.jpg';

export const MaterialSelect = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'modals.configurationModal.';
	const constantsPrefix = 'constants.materials.';

	const getImage = material => {
		let image;

		if (material === Materials.BLUESTONE) {
			image = bluestone;
		} else if (material === Materials.MARBLE) {
			image = marble;
		} else {
			image = granite;
		}

		return image;
	};

	return (
		<div>
			{props.isVisible ? (
				<>
					<SectionTitle content={t(prefix + 'materialTitle')} />
					<div className="configuration__options">
						{props.materials.map((material, index) => {
							return (
								<ConfigOption
									dataCy={`materialSelect-${material.toLowerCase()}`}
									key={index}
									name="material"
									content={t(constantsPrefix + material)}
									selected={props.selectedMaterial === material}
									onClick={() => props.setMaterial(material)}
									image={getImage(material)}
								/>
							);
						})}
					</div>
				</>
			) : null}
		</div>
	);
};

MaterialSelect.propTypes = {
	isVisible: PropTypes.bool,
	materials: PropTypes.array,
	selectedMaterial: PropTypes.string,
	setMaterial: PropTypes.func.isRequired,
};
