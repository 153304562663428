import MeasurementPoints from '../../../models/MeasurementPoints';
import { BACK, BOTTOM, FRONT, LEFT, RIGHT, TOP } from '../../../../constants/ObjectSides';
import { Vector3 } from 'three';
import { distanceFromSide } from '../SideMeasurementLineHelper';

export function getPointsForRabat(pieceDimensions, rabat) {
	const horizontalPoints = new MeasurementPoints(
		getHorizontalPointA(pieceDimensions, rabat),
		getHorizontalPointB(pieceDimensions, rabat),
	);

	horizontalPoints.lineLength = rabat.dimensions.width;

	if (rabat.additionalDimension.type === TOP) horizontalPoints.textSide = BACK;

	const verticalPoints = new MeasurementPoints(
		getVerticalPointA(pieceDimensions, rabat),
		getVerticalPointB(pieceDimensions, rabat),
	);

	verticalPoints.lineLength = rabat.dimensions.height;
	if (rabat.side === FRONT) verticalPoints.textSide = BACK;

	return {
		horizontal: horizontalPoints,
		vertical: verticalPoints,
	};
}

function getHorizontalPointA(pieceDimensions, rabat) {
	const xPos = getXPosHorizontalPointA(pieceDimensions, rabat);
	const yPos = getYPosHorizontalPoint(pieceDimensions, rabat);
	const zPos = getZPosHorizontalPointA(pieceDimensions, rabat);

	return new Vector3(xPos, yPos, zPos);
}

function getHorizontalPointB(pieceDimensions, rabat) {
	const xPos = getXPosHorizontalPointB(pieceDimensions, rabat);
	const yPos = getYPosHorizontalPoint(pieceDimensions, rabat);
	const zPos = getZPosHorizontalPointB(pieceDimensions, rabat);

	return new Vector3(xPos, yPos, zPos);
}

function getVerticalPointA(pieceDimensions, rabat) {
	const xPos = getXPosVerticalPoint(pieceDimensions, rabat);
	const yPos = getYPosVerticalPointA(rabat);
	const zPos = getZPosVerticalPoint(pieceDimensions, rabat);

	return new Vector3(xPos, yPos, zPos);
}

function getVerticalPointB(pieceDimensions, rabat) {
	const xPos = getXPosVerticalPoint(pieceDimensions, rabat);
	const yPos = getYPosVerticalPointB(rabat);
	const zPos = getZPosVerticalPoint(pieceDimensions, rabat);

	return new Vector3(xPos, yPos, zPos);
}

function getXPosHorizontalPointA({ length }, rabat) {
	let xPos;

	if ([FRONT, BACK].includes(rabat.side)) {
		xPos = length / -2;
	} else {
		xPos = rabat.position.x - rabat.dimensions.width / 2;
	}

	return xPos;
}

function getXPosHorizontalPointB({ length }, rabat) {
	let xPos;

	if ([FRONT, BACK].includes(rabat.side)) {
		xPos = length / -2;
	} else {
		xPos = rabat.position.x + rabat.dimensions.width / 2;
	}

	return xPos;
}

function getYPosHorizontalPoint({ height }, rabat) {
	let yPos;

	if (rabat.additionalDimension.type === TOP) {
		yPos = height / 2 + distanceFromSide;
	} else {
		yPos = height / -2 - distanceFromSide;
	}

	return yPos;
}

function getZPosHorizontalPointA({ width }, rabat) {
	let zPos;

	if ([FRONT, BACK].includes(rabat.side)) {
		zPos = rabat.position.z - rabat.dimensions.width / 2;
	} else {
		zPos = width / 2;
	}

	return zPos;
}

function getZPosHorizontalPointB({ width }, rabat) {
	let zPos;

	if ([FRONT, BACK].includes(rabat.side)) {
		zPos = rabat.position.z + rabat.dimensions.width / 2;
	} else {
		zPos = width / 2;
	}

	return zPos;
}

function getXPosVerticalPoint({ length }, rabat) {
	let xPos;

	if ([FRONT, BACK].includes(rabat.side)) {
		xPos = length / -2;
	} else {
		if (rabat.side === LEFT) {
			xPos = rabat.position.x - rabat.dimensions.width / 2 - distanceFromSide;
		} else {
			xPos = rabat.position.x + rabat.dimensions.width / 2 + distanceFromSide;
		}
	}

	return xPos;
}

function getYPosVerticalPointA(rabat) {
	return rabat.position.y + rabat.dimensions.height / 2;
}

function getYPosVerticalPointB(rabat) {
	return rabat.position.y - rabat.dimensions.height / 2;
}

function getZPosVerticalPoint({ length, width }, rabat) {
	let zPos;

	switch (rabat.side) {
		case FRONT:
			zPos = width / 2 + distanceFromSide;
			break;
		case BACK:
			zPos = width / -2 - distanceFromSide;
			break;
		case LEFT:
		case RIGHT:
			zPos = length / 2;
			break;
	}

	return zPos;
}
