import { getYPosForSide } from '../SurfaceMeasurementLineHelper';
import MeasurementPoints from '../../../models/MeasurementPoints';
import { Vector3 } from 'three';
import { RECTANGULAR_CUT_OUT_TYPES } from '../../../../constants/OperationTypes';
import { BACK, FRONT } from '../../../../constants/ObjectSides';
import { getXFieldValueByPosition, getYFieldValueByPosition } from '../../VectorHelper';
import { getClosestHorizontalSide, getClosestVerticalSide } from '../../OperationHelper';

export function getPointsForRectangularCutOut(piece, rectangularCutOut) {
	const pieceDimensions = piece.dimensions;
	const lineYPos = getYPosForSide(pieceDimensions) + 0.05;

	const horizontalPoints = getHorizontalPoints(piece, rectangularCutOut, lineYPos);
	const verticalPoints = getVerticalPoints(piece, rectangularCutOut, lineYPos);
	const widthPoints = getWidthPoints(pieceDimensions, rectangularCutOut, lineYPos);

	const lengthPoints = new MeasurementPoints(
		getLengthPointA(pieceDimensions, rectangularCutOut, lineYPos),
		getLengthPointB(pieceDimensions, rectangularCutOut, lineYPos),
	);

	lengthPoints.textSide = getHorizontalPointSide(rectangularCutOut.position.z);
	lengthPoints.lineLength = rectangularCutOut.dimensions.length;

	return {
		horizontal: widthPoints ? [horizontalPoints, widthPoints] : horizontalPoints,
		vertical: [verticalPoints, lengthPoints],
	};
}

function getHorizontalPoints(piece, rectangularCutOut, lineYPos) {
	const horizontalPoints = new MeasurementPoints(
		getHorizontalPointA(piece.dimensions, rectangularCutOut, lineYPos),
		getHorizontalPointB(piece.dimensions, rectangularCutOut, lineYPos),
	);

	let dimensions = piece.dimensions;

	if (piece.originalDimensions.length) {
		dimensions = piece.originalDimensions;
	}

	horizontalPoints.textSide = getHorizontalPointSide(rectangularCutOut.position.z);
	horizontalPoints.lineLength = getXFieldValueByPosition(
		rectangularCutOut,
		dimensions,
		getClosestHorizontalSide(rectangularCutOut),
	);

	return horizontalPoints;
}

function getHorizontalPointA(pieceDimensions, rectangularCutOut, lineYPos) {
	let xPos;

	// Set the points on the side that is shortest
	if (rectangularCutOut.position.x > 0) {
		// Set on the right side
		xPos = rectangularCutOut.position.x;

		if (rectangularCutOut.additionalDimension.type === RECTANGULAR_CUT_OUT_TYPES.RECTANGULAR) {
			xPos += rectangularCutOut.dimensions.length / 2;
		}
	} else {
		// Set on the left side
		xPos = -pieceDimensions.length / 2;
	}

	return new Vector3(xPos, lineYPos, rectangularCutOut.position.z);
}

function getHorizontalPointB(pieceDimensions, rectangularCutOut, lineYPos) {
	let xPos;

	// Set the points on the side that is shortest
	if (rectangularCutOut.position.x > 0) {
		// Set on the right side
		xPos = pieceDimensions.length / 2;
	} else {
		// Set on the left side
		xPos = rectangularCutOut.position.x;

		if (rectangularCutOut.additionalDimension.type === RECTANGULAR_CUT_OUT_TYPES.RECTANGULAR) {
			xPos -= rectangularCutOut.dimensions.length / 2;
		}
	}

	return new Vector3(xPos, lineYPos, rectangularCutOut.position.z);
}

function getVerticalPoints(piece, rectangularCutOut, lineYPos) {
	const verticalPoints = new MeasurementPoints(
		getVerticalPointA(piece.dimensions, rectangularCutOut, lineYPos),
		getVerticalPointB(piece.dimensions, rectangularCutOut, lineYPos),
	);

	let lineLength = getYFieldValueByPosition(
		rectangularCutOut,
		piece.dimensions,
		getClosestVerticalSide(rectangularCutOut),
	);

	if (piece.originalDimensions.width) {
		lineLength += Math.abs(piece.dimensions.width - piece.originalDimensions.width) / 2;
	}

	verticalPoints.textSide = getVerticalPointSide(rectangularCutOut.position.x);
	verticalPoints.lineLength = getYFieldValueByPosition(
		rectangularCutOut,
		piece.dimensions,
		getClosestVerticalSide(rectangularCutOut),
	);

	return verticalPoints;
}

function getVerticalPointA(pieceDimensions, rectangularCutOut, yLinePos) {
	let zPos;
	const verticalSide = getClosestVerticalSide(rectangularCutOut);

	if (verticalSide === FRONT) {
		zPos = pieceDimensions.width / 2;
	} else {
		zPos = rectangularCutOut.position.z;

		if (rectangularCutOut.additionalDimension.type === RECTANGULAR_CUT_OUT_TYPES.RECTANGULAR) {
			zPos -= rectangularCutOut.dimensions.width / 2;
		}
	}

	return new Vector3(rectangularCutOut.position.x, yLinePos, zPos);
}

function getVerticalPointB(pieceDimensions, rectangularCutOut, yLinePos) {
	let zPos;

	if (rectangularCutOut.position.z > 0) {
		zPos = rectangularCutOut.position.z;

		if (rectangularCutOut.additionalDimension.type === RECTANGULAR_CUT_OUT_TYPES.RECTANGULAR) {
			zPos += rectangularCutOut.dimensions.width / 2;
		}
	} else {
		zPos = pieceDimensions.width / -2;
	}

	return new Vector3(rectangularCutOut.position.x, yLinePos, zPos);
}

function getWidthPoints(pieceDimensions, rectangularCutOut, lineYPos) {
	if (rectangularCutOut.additionalDimension.type === RECTANGULAR_CUT_OUT_TYPES.ROUND) return null;

	const widthPoints = new MeasurementPoints(
		getWidthPointA(pieceDimensions, rectangularCutOut, lineYPos),
		getWidthPointB(pieceDimensions, rectangularCutOut, lineYPos),
	);

	widthPoints.textSide = BACK;
	widthPoints.lineLength = rectangularCutOut.dimensions.width;

	return widthPoints;
}

function getWidthPointA(pieceDimensions, rectangularCutOut, lineYPos) {
	const zPos = rectangularCutOut.position.z + rectangularCutOut.dimensions.width / 2;

	return new Vector3(getXPosOfWidthPoint(rectangularCutOut), lineYPos, zPos);
}

function getWidthPointB(pieceDimensions, rectangularCutOut, lineYPos) {
	const zPos = rectangularCutOut.position.z - rectangularCutOut.dimensions.width / 2;

	return new Vector3(getXPosOfWidthPoint(rectangularCutOut), lineYPos, zPos);
}

function getLengthPointA(pieceDimensions, rectangularCutOut, lineYPos) {
	const xPos = rectangularCutOut.position.x - rectangularCutOut.dimensions.length / 2;

	return new Vector3(xPos, lineYPos, getZPosOfWidthPoint(rectangularCutOut));
}

function getLengthPointB(pieceDimensions, rectangularCutOut, lineYPos) {
	const xPos = rectangularCutOut.position.x + rectangularCutOut.dimensions.length / 2;

	return new Vector3(xPos, lineYPos, getZPosOfWidthPoint(rectangularCutOut));
}

function getHorizontalPointSide(zPos) {
	if (zPos > 0) {
		return BACK;
	} else {
		return FRONT;
	}
}

function getVerticalPointSide(xPos) {
	if (xPos > 0) {
		return BACK;
	} else {
		return FRONT;
	}
}

function getXPosOfWidthPoint({ position, dimensions }) {
	const distanceFromSide = 1;

	return position.x - dimensions.length / 2 + distanceFromSide;
}

function getZPosOfWidthPoint({ position, dimensions, additionalDimension }) {
	if (additionalDimension.type === RECTANGULAR_CUT_OUT_TYPES.ROUND) return position.z;

	const distanceFromSide = 1;

	return position.z + dimensions.width / 2 - distanceFromSide;
}
