import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { useTranslation } from 'react-i18next';

function Select(props) {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'forms.selects.select.';

	const [selectedValue, setSelectedValue] = useState('');

	useEffect(() => {
		if (props.value) {
			setSelectedValue(props.value);
		} else {
			setSelectedValue('');
		}
	}, [props.value]);

	const getOptions = () => {
		let options = [];

		if (props.defaultOptionText) {
			options.push(
				<option key={'none'} value={null}>
					{props.defaultOptionText}
				</option>,
			);
		}

		if (props.options?.length === 0) {
			return (
				<option selected hidden>
					{t(prefix + 'emptyOptionsItem')}
				</option>
			);
		}

		return options.concat(
			props.options?.map((option, index) => {
				return (
					<option key={props.name + index} value={option.id} data-cy={`${props.dataCy}-${option.id}`}>
						{option.emphasize && '❖'} {option.value}
					</option>
				);
			}),
		);
	};

	return (
		<div className="form-group">
			{props.label && <label>{props.label}</label>}
			<select
				data-cy={props.dataCy}
				data-test={props.dataTest}
				value={selectedValue}
				onChange={event => props.onChange(event.target.value)}
			>
				{getOptions()}
			</select>
		</div>
	);
}

Select.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	label: PropTypes.string,
	defaultOptionText: PropTypes.string,
	options: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
	dataCy: PropTypes.string,
};

export { Select };
