import * as React from 'react';
import PropTypes from 'prop-types';

export function SectionTitle(props) {
	return props.dataCy ? (
		<div className="sectiontitle" data-cy={props.dataCy}>
			{props.content}
		</div>
	) : (
		<div className="sectiontitle">{props.content}</div>
	);
}

SectionTitle.propTypes = {
	content: PropTypes.string.isRequired,
	dataCy: PropTypes.string,
};
