import * as React from 'react';
import PropTypes from 'prop-types';

export const DisabledInput = props => {
	return (
		<div className="form-group">
			<label>{props.label}</label>
			<input type="text" value={props.content} disabled />
		</div>
	);
};

DisabledInput.propTypes = {
	label: PropTypes.string.isRequired,
	content: PropTypes.string.isRequired,
};
