import { SURFACE_OPERATION_TYPES } from '../../constants/OperationTypes';
import { Color, FaceColors, Geometry, Mesh, MeshBasicMaterial, Object3D } from 'three';
import {
	DEFAULT_OBJECT_COLOR,
	HIGHLIGHTED_OBJECT_COLOR,
	SURFACE_OPERATION_COLOR,
	SURFACE_OPERATION_HIGHLIGHTED_COLOR,
} from '../../constants/ThreeConstants';
import { BACK, BOTTOM, FRONT, LEFT, RIGHT, TOP } from '../../constants/ObjectSides';
import { cloneDeep } from 'lodash';
import { TYPE_1, TYPE_2, TYPE_3, TYPE_4, TYPE_5, TYPE_6, TYPE_7, TYPE_8 } from '../../constants/ObjectTypes';

export default class MeshColorService {
	constructor() {
		this.piece = null;
	}

	addColorToMesh(piece, mesh, isSelected) {
		this.piece = piece;

		if (isSelected) {
			mesh.material.color.set(HIGHLIGHTED_OBJECT_COLOR);
		} else {
			mesh.material.color.set(DEFAULT_OBJECT_COLOR);
		}

		if (piece.hasAnyOfOperationTypes(SURFACE_OPERATION_TYPES)) {
			return this.addSurfaceOperationColorsToMesh(piece, mesh, isSelected);
		}

		const parent = new Object3D();
		parent.add(mesh);

		return parent;
	}

	addSurfaceOperationColorsToMesh(piece, mesh, isSelected) {
		const sides = piece.getOperationsByType(SURFACE_OPERATION_TYPES).map(s => s.side);
		const coloredMeshes = [];

		sides.forEach(side => {
			const verticesAndFacesToColor = this.getFacesToColor(mesh.geometry, side);
			const geometry = this.createGeometryWithFacesAndVertices(
				verticesAndFacesToColor.vertices,
				verticesAndFacesToColor.faces,
				isSelected,
			);

			const newMesh = new Mesh(geometry, new MeshBasicMaterial({ vertexColors: true }));

			newMesh.position.set(mesh.position.x, mesh.position.y, mesh.position.z);
			newMesh.rotation.set(mesh.rotation.x, mesh.rotation.y, mesh.rotation.z);
			newMesh.updateMatrix();

			coloredMeshes.push(newMesh);
		});

		const parent = new Object3D();
		parent.add(mesh, ...coloredMeshes);

		return parent;
	}

	/**
	 * Returns an object containing the vertices and faces to color.
	 *
	 * @param {Array} faces
	 * @param {Array} vertices
	 * @param {string} side
	 * @return {Object} An object containing the vertices and faces to color.
	 */
	getFacesToColor({ faces, vertices }, side) {
		const facesToColor = this.getFacesForSide(cloneDeep(faces), cloneDeep(side));
		const verticesToColor = [];

		facesToColor.forEach(f => {
			verticesToColor.push(vertices[f.a]);
			verticesToColor.push(vertices[f.b]);
			verticesToColor.push(vertices[f.c]);

			f.a = verticesToColor.length - 3;
			f.b = verticesToColor.length - 2;
			f.c = verticesToColor.length - 1;
		});

		return { vertices: verticesToColor, faces: facesToColor };
	}

	getVerticesForSide(vertices, side) {
		const axis = this.getAxisBySide(side);
		const outerMostValuesOnAxis = this.getOuterMostValuesOnAxis(vertices, axis);

		const verticesToColor = {};

		vertices.forEach((v, index) => {
			if (axis.isPositive) {
				if (v[axis.axis] === outerMostValuesOnAxis.max) {
					verticesToColor[index] = v;
				}
			} else {
				if (v[axis.axis] === outerMostValuesOnAxis.min) {
					verticesToColor[index] = v;
				}
			}
		});

		return verticesToColor;
	}

	createGeometryWithFacesAndVertices(vertices, faces, isSelected) {
		const geometry = new Geometry();
		const color = isSelected ? new Color(SURFACE_OPERATION_HIGHLIGHTED_COLOR) : new Color(SURFACE_OPERATION_COLOR);

		geometry.vertices = vertices;
		geometry.faces = faces.map(f => {
			f.color = color;

			return f;
		});

		return geometry;
	}

	getOuterMostValuesOnAxis(vertices, axis) {
		const valuesForAxis = vertices.map(v => v[axis.axis]);
		const outerMostValues = { min: Math.min(...valuesForAxis), max: Math.max(...valuesForAxis) };

		return this.updateOuterMostValuesByPieceType(outerMostValues, axis);
	}

	getAxisBySide(side) {
		const axis = { axis: null, isPositive: false };

		switch (side) {
			case TOP:
				axis.axis = 'y';
				axis.isPositive = true;
				break;
			case BOTTOM:
				axis.axis = 'y';
				break;
			case LEFT:
				axis.axis = 'x';
				break;
			case RIGHT:
				axis.axis = 'x';
				axis.isPositive = true;
				break;
			case FRONT:
				axis.axis = 'z';
				axis.isPositive = true;
				break;
			case BACK:
				axis.axis = 'z';
				break;
		}

		return axis;
	}

	getFacesForSide(faces, side) {
		const facesToColor = [];

		faces.forEach(f => {
			if (this.isFaceOnSide(f, side)) {
				facesToColor.push(f);
			}
		});

		return facesToColor;
	}

	isFaceOnSide(face, side) {
		const normal = face.normal.normalize();
		let isFaceOnSide;

		switch (side) {
			case TOP:
				isFaceOnSide = normal.y === 1;
				break;
			case BOTTOM:
				isFaceOnSide = normal.y === -1;
				break;
			case FRONT:
				isFaceOnSide = normal.z === 1;
				break;
			case BACK:
				isFaceOnSide = normal.z === -1;
				break;
			case LEFT:
				isFaceOnSide = normal.x === -1;
				break;
			case RIGHT:
				isFaceOnSide = normal.x === 1;
				break;
			default:
				isFaceOnSide = false;
		}

		return isFaceOnSide;
	}

	updateOuterMostValuesByPieceType(outerMostValues, axis) {
		if (!axis.isPositive) {
			return outerMostValues;
		}

		switch (this.piece.type) {
			case TYPE_1:
			case TYPE_2:
				break;
			case TYPE_3:
			case TYPE_4:
				if (axis.axis === 'y') {
					outerMostValues.max -= this.piece.dimensions.extrusion;
				}
				break;
			case TYPE_5:
			case TYPE_6:
				if (axis.axis === 'y') {
					outerMostValues.max -= this.piece.dimensions.extrusion;
				}
				break;
			case TYPE_7:
			case TYPE_8:
				if (axis.axis === 'y') {
					outerMostValues.max -= this.piece.dimensions.extrusion;
				}
				break;
		}

		return outerMostValues;
	}

	/**
	 * Adds a random color to each face of the given mesh.
	 * For debugging purposes
	 *
	 * @param {Object} mesh - The mesh object to modify.
	 * @return {Object} The modified mesh object with random colors added to each face.
	 */
	addRandomColorToFaces(mesh) {
		const faces = mesh.geometry.faces;

		mesh.geometry.faces = faces.map(face => {
			const color = new Color(Math.random() * 0xffffff);
			face.color.set(color);

			return face;
		});

		mesh.material = new MeshBasicMaterial({ vertexColors: FaceColors });

		return mesh;
	}
}
