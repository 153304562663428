import * as React from 'react';
import { DUTCH, DUTCH_SHORT, ENGLISH, ENGLISH_SHORT, FRENCH, FRENCH_SHORT } from '../../../constants/Languages';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';

export const LanguageSelect = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'constants.languages.';

	const onChange = value => {
		if (props.onChange) props.onChange(value);
	};

	return (
		<div className="m-b-2">
			<div className="form-group">
				{props.label && <label>{props.label}</label>}
				<select
					disabled={props.disabled}
					onChange={event => onChange(event.target.value)}
					value={props.value.toLowerCase()}
				>
					<option value={DUTCH_SHORT}>{t(prefix + DUTCH)}</option>
					<option value={FRENCH_SHORT}>{t(prefix + FRENCH)}</option>
				</select>
			</div>
		</div>
	);
};

LanguageSelect.propTypes = {
	label: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
};
