import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ModalHolder } from './ModalHolder';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { Type9And10ProfileForm } from './shared/Type9And10ProfileForm';
import { useSelector } from 'react-redux';
import { PROFILE } from '../../constants/OperationTypes';

export const Type9And10ProfileModal = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'modals.type9And10ProfileModal.';

	const [profile, setProfile] = useState(null);

	const { currentConfiguration, currentPiece } = useSelector(state => state.offerReducer);

	useEffect(() => {
		if (props.isActive) {
			const profiles = currentPiece.getOperationsByType(PROFILE);

			if (profiles.length) {
				// There should only be one profile on a type 9 or 10
				setProfile(profiles[0]);
			}
		} else {
			setProfile(null);
		}
	}, [props.isActive]);

	return (
		<ModalHolder isActive={props.isActive}>
			<Modal show onHide={() => props.onClose()} className="modal--prompt">
				<Modal.Header>
					<Modal.Title>{t(prefix + 'title')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>{t(prefix + 'disabledDescription')}</p>

					<Type9And10ProfileForm
						dimensions={profile?.dimensions}
						configurationHeight={currentConfiguration?.options.height}
						disabled
					/>
				</Modal.Body>
				<Modal.Footer>
					<button type="button" className="button button--outline" onClick={() => props.onClose()}>
						{t(prefix + 'cancelButton')}
					</button>
				</Modal.Footer>
			</Modal>
		</ModalHolder>
	);
};

Type9And10ProfileModal.propTypes = {
	isActive: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};
