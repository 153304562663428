import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { EDITING_OFFER_ON, EDITING_ORDER_OFF, EDITING_ORDER_ON, SAVING } from '../../../constants/ConfiguratorStatuses';
import { useDispatch, useSelector } from 'react-redux';
import { setConfiguratorStatus } from '../../../actions/ConfiguratorActions';

export const SaveButton = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'constants.configuratorStatuses.';

	const { status: configuratorStatus } = useSelector(state => state.configuratorReducer);

	const dispatch = useDispatch();

	const onClick = () => {
		if (configuratorStatus === EDITING_OFFER_ON) {
			props.onSave();
		} else if (configuratorStatus === EDITING_ORDER_OFF) {
			dispatch(setConfiguratorStatus(EDITING_ORDER_ON));
		} else if (configuratorStatus === EDITING_ORDER_ON) {
			dispatch(setConfiguratorStatus(SAVING));
		}

		// State is set as EDITING_ORDER_OFF in the Configurator component
	};

	const getContent = () => {
		let content;

		switch (configuratorStatus) {
			case EDITING_ORDER_ON:
				content = EDITING_ORDER_ON;
				break;
			case EDITING_ORDER_OFF:
				content = EDITING_ORDER_OFF;
				break;
			case SAVING:
				content = SAVING;
				break;
			case EDITING_OFFER_ON:
			default:
				content = EDITING_OFFER_ON;
		}

		return t(prefix + `${content}`);
	};

	const getStyling = () => {
		let styling;

		switch (configuratorStatus) {
			case EDITING_ORDER_ON:
				styling = 'button--danger';
				break;
			case EDITING_ORDER_OFF:
				styling = '';
				break;
			case SAVING:
				styling = 'button--outline';
				break;
			case EDITING_OFFER_ON:
			default:
				styling = 'button--success';
		}

		return `button ${styling}`;
	};

	return (
		<button data-cy={props.dataCy} type="button" className={getStyling()} onClick={onClick} disabled={props.disabled}>
			{getContent()}
		</button>
	);
};

SaveButton.propTypes = {
	onSave: PropTypes.func,
	onUpdateButtonState: PropTypes.func.isRequired,
	dataCy: PropTypes.string,
	disabled: PropTypes.bool,
};
