import {
	SET_CURRENT_ORDER,
	SET_CURRENT_PHASE,
	SET_ORDER_COMMENT,
	SET_ORDERS,
	SET_ORDER_PAGINATION_SETTINGS,
} from '../constants/ActionTypes';

export function setCurrentPhase(phase) {
	return {
		type: SET_CURRENT_PHASE,
		payload: phase,
	};
}

export function setOrderComment(comment) {
	return {
		type: SET_ORDER_COMMENT,
		payload: comment,
	};
}

export function setCurrentOrder(order) {
	return {
		type: SET_CURRENT_ORDER,
		payload: order,
	};
}

export function setOrders(orders) {
	return {
		type: SET_ORDERS,
		orders: orders,
	};
}

export function setOrderPaginationSettings(paginationSettings) {
	return {
		type: SET_ORDER_PAGINATION_SETTINGS,
		payload: paginationSettings,
	};
}
