import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

export const NumberSelect = props => {
	const [value, setValue] = useState(null);

	useEffect(() => {
		setValue(props.value);
	}, [props.value]);

	useEffect(() => {
		if (!props.rangeStart && !props.rangeEnd) return;

		if (props.rangeStart > props.rangeEnd) {
			throw Error('Range start cannot be higher than range end');
		}
	}, [props.rangeStart, props.rangeEnd]);

	const onChange = value => {
		setValue(value);

		if (props.onUpdate) {
			props.onUpdate(value);
		}
	};

	const getOptions = () => {
		let options = [];

		for (let i = props.rangeStart; i <= props.rangeEnd; i++) {
			options.push(
				<option key={`numberSelect${i}`} value={i}>
					{i}
				</option>,
			);
		}

		return options;
	};

	const getStyle = () => {
		const defaultStyle = 'm-r-1';

		if (props.size === 'lg') {
			return defaultStyle + ' w-100';
		}

		return defaultStyle;
	};

	return (
		<span>
			{props.tooltipContent ? <ReactTooltip /> : null}

			<select
				data-cy={props.dataCy}
				className={getStyle()}
				data-tip={props.tooltipContent}
				value={value ?? ''}
				onChange={event => onChange(event.target.value)}
				disabled={props.disabled}
			>
				{props.message && (
					<option value="0" disabled>
						{props.message}
					</option>
				)}
				{getOptions()}
			</select>
		</span>
	);
};

NumberSelect.propTypes = {
	value: PropTypes.number,
	rangeStart: PropTypes.number.isRequired,
	rangeEnd: PropTypes.number.isRequired,
	onUpdate: PropTypes.func,
	tooltipContent: PropTypes.string,
	size: PropTypes.string,
	message: PropTypes.string,
	disabled: PropTypes.bool,
	dataCy: PropTypes.string,
};
