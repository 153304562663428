import * as React from 'react';
import PropTypes from 'prop-types';

export const EmptyPage = props => {
	const renderContent = () => {
		if (props.content) {
			return <p>{props.content}</p>;
		}
	};

	const renderButton = () => {
		if (props.buttonText && props.onButtonClick) {
			return (
				<div className="message__actions">
					<button
						data-cy={props.buttonDataCy}
						type="button"
						className="button button--icon-before"
						onClick={() => props.onButtonClick()}
					>
						<i className="material-icons">add</i> {props.buttonText}
					</button>
				</div>
			);
		}
	};

	return (
		<div className="message message--empty" data-cy="emptyPage">
			<h4>{props.title}</h4>
			{renderContent()}

			{renderButton()}
		</div>
	);
};

EmptyPage.propTypes = {
	title: PropTypes.string.isRequired,
	content: PropTypes.string,
	buttonText: PropTypes.string,
	onButtonClick: PropTypes.func,
	buttonDataCy: PropTypes.string,
};
