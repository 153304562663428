import * as ActionTypes from '../constants/ActionTypes';

export function setConfiguratorStatus(status) {
	return {
		type: ActionTypes.SET_CONFIGURATOR_STATUS,
		status: status,
	};
}

export function setConfigurableType(type) {
	return {
		type: ActionTypes.SET_CONFIGURABLE_TYPE,
		configurableType: type,
	};
}
