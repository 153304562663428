// Colors
export const DEFAULT_OBJECT_COLOR = 0xd7dee6;
export const HIGHLIGHTED_OBJECT_COLOR = 0x7fb6ff;
export const SURFACE_OPERATION_COLOR = 0x519932;
export const SURFACE_OPERATION_HIGHLIGHTED_COLOR = 0x3abc54;
export const HIGHLIGHTED_LINE_COLOR = 0x006eff;
export const DEFAULT_LINE_COLOR = 0x000000;
export const MEASUREMENT_LINE = 0x333333;
export const BACKGROUND_COLOR = 0xf8f9fc;
export const STANDARD_ARROW_COLOR = 0x7d807c;
export const EXTRA_ARROW_COLOR = 0xde4d2b;
export const SURFACE_OPERATION_ARROW_COLOR = SURFACE_OPERATION_HIGHLIGHTED_COLOR;
export const SANDBLASTING_MARKER_COLOR = 0xddc7a0;

export const HIGHLIGHT = 0xebc634;
export const PICKED = 0xeb4034;

export const TEXT = 0x1d1e1e;
export const HIGHLIGHTED_TEXT = 0xffffff;

// Fontsizes
export const PIECE_NAME_FONT_SIZE = 2;
