import i18n from '../../i18n';
import {
	BASEBOARD_SIDES,
	BASEBOARDS,
	CHIMNEY_COVER_SIDES,
	CHIMNEY_COVERS,
	CONFIGURABLE_OPERATIONS,
	CUSHION_SIDES,
	CUSHIONS,
	CUSTOM_TILE,
	CUSTOM_TILE_SIDES,
	ENTREPORT_SIDES,
	ENTREPORTS,
	EXTERIOR_PRESETS_WITHOUT_WATERLISTS,
	FINISHED_SIDES_INCLUDED_IN_PRICE,
	LINTEL_SIDES,
	LINTELS,
	PILLAR_SIDES,
	PILLARS,
	PRESETS_WITH_STANDARD_WIDTHS,
	REAR_HEEL_SIDES,
	REAR_HEELS,
	SILL_SIDES,
	SILLS,
	STAIR_RISER_SIDES,
	STAIR_RISERS,
	STAIR_STEP_SIDES,
	STAIR_STEPS,
	WALL_COVER_SIDES,
	WALL_COVERS,
	WALL_SLAB_SIDES,
	WALL_SLABS,
	WINDOW_SLAB_SIDES,
	WINDOW_SLABS,
} from '../../constants/Presets';
import { BOTTOM, TOP } from '../../constants/ObjectSides';
import { PARTIALLY } from '../../constants/FinishedSideStates';
import { TYPE_10, TYPE_3, TYPE_4, TYPE_7, TYPE_8, TYPE_9 } from '../../constants/ObjectTypes';
import { DEBASING_ROUGH, GLUED_CUSHION, PROFILE } from '../../constants/OperationTypes';
import { captureMessage } from '@sentry/react';
import { BLUESTONE, GRANITE, MARBLE } from '../../constants/Materials';
import { STANDARD_WIDTHS } from '../../constants/StandardMeasurements';
import { PETIT_GRANIT_HONED_G300, PETIT_GRANIT_POLI_SHINY } from '../../constants/MaterialColors';

class Configuration {
	constructor(id, type = null, options = null) {
		this.id = id;
		this.name = '';

		if (options == null) {
			this.options = { decor: null, preset: null, material: null, materialColor: null, type: null, height: null };
		} else {
			this.options = options;
		}

		this.pieces = [];
		this.amount = 1;
		this.price = { total: 0, vat: 0, discountCustomWork: 0, discountSpecialOperations: 0 };
	}

	setOptions(options) {
		this.options = options;

		this.pieces.forEach(piece => {
			piece.height = options.height;
		});
	}

	getOptionsSummary() {
		let prefix = 'app:constants.';
		let summary = '';
		let options = this.options;

		if (options == null) return '';

		Object.keys(options).forEach(key => {
			if (options[key] != null) {
				if (typeof options[key] != 'string') {
					options[key] = options[key].toString();
				}

				switch (key) {
					case 'decor':
						summary += i18n.t(prefix + 'values.' + options[key]);
						break;
					case 'preset':
						summary += ' - ' + i18n.t(prefix + 'presets.' + options[key]);
						break;
					case 'material':
						summary += ' - ' + i18n.t(prefix + 'materials.' + options[key]);
						break;
					case 'quality':
						summary += ' - ' + i18n.t(prefix + 'materialQualities.' + options[key]);
						break;
					case 'materialColor':
						summary += ' - ' + i18n.t(prefix + 'materialColors.' + options[key]);
						break;
					case 'type':
						if (this.shouldAddTypeInSummary()) summary += ' - ' + i18n.t(prefix + 'types.' + options[key]);
						break;
					case 'height':
						summary += ' - ' + options[key] + ' cm';
						break;
					default:
						break;
				}
			}
		});

		return summary;
	}

	shouldAddTypeInSummary() {
		return (
			(this.options.material === BLUESTONE &&
				![PETIT_GRANIT_HONED_G300, PETIT_GRANIT_POLI_SHINY].includes(this.options.materialColor)) ||
			(this.options.material === GRANITE && this.options.height === 5)
		);
	}

	sortPieces() {
		let collections = [];

		this.pieces.forEach(piece => {
			let shouldCreateNewCollection = true;

			collections.forEach(collection => {
				collection.forEach(collectionPiece => {
					collectionPiece.connectedObjects.forEach(connectedPiece => {
						if (connectedPiece.id === piece.id) {
							shouldCreateNewCollection = false;
							collection.push(piece);
						}
					});
				});
			});

			if (shouldCreateNewCollection) collections.push([piece]);
		});

		let pieces = [];

		collections.forEach(collection => {
			pieces = pieces.concat(...collection);
		});

		return pieces;
	}

	getStandardFinishedSides() {
		let sides = [];

		switch (this.options.preset) {
			case WINDOW_SLABS:
				sides = WINDOW_SLAB_SIDES;
				break;
			case ENTREPORTS:
				sides = ENTREPORT_SIDES;
				break;
			case WALL_SLABS:
				sides = WALL_SLAB_SIDES;
				break;
			case SILLS:
				sides = SILL_SIDES;
				break;
			case WALL_COVERS:
				sides = WALL_COVER_SIDES;
				break;
			case CHIMNEY_COVERS:
				sides = CHIMNEY_COVER_SIDES;
				break;
			case BASEBOARDS:
				sides = BASEBOARD_SIDES;
				break;
			case STAIR_STEPS:
				sides = STAIR_STEP_SIDES;
				break;
			case STAIR_RISERS:
				sides = STAIR_RISER_SIDES;
				break;
			case LINTELS:
				sides = LINTEL_SIDES;
				break;
			case PILLARS:
				sides = PILLAR_SIDES;
				break;
			case CUSTOM_TILE:
				sides = CUSTOM_TILE_SIDES;
				break;
			case REAR_HEELS:
				sides = REAR_HEEL_SIDES;
				break;
			case CUSHIONS:
				sides = CUSHION_SIDES;
				break;
			default:
				sides = [];
				break;
		}

		return sides;
	}

	getStandardWaterlists() {
		if (EXTERIOR_PRESETS_WITHOUT_WATERLISTS.includes(this.options.preset)) return [];

		let waterlists = [];

		this.getStandardFinishedSides().forEach(side => {
			if (![TOP, BOTTOM].includes(side.name) && side.type !== PARTIALLY) {
				waterlists.push(side.name);
			}
		});

		return waterlists;
	}

	getFinishedSidesIncludedInPrice(preset = null) {
		preset = preset ?? this.options.preset;
		let includedInPrice;

		switch (preset) {
			case ENTREPORTS:
				includedInPrice = FINISHED_SIDES_INCLUDED_IN_PRICE.ENTREPORTS;
				break;
			case BASEBOARDS:
				includedInPrice = FINISHED_SIDES_INCLUDED_IN_PRICE.BASEBOARDS;
				break;
			case CUSHIONS:
				includedInPrice = FINISHED_SIDES_INCLUDED_IN_PRICE.CUSHIONS;
				break;
			case STAIR_RISERS:
				includedInPrice = FINISHED_SIDES_INCLUDED_IN_PRICE.STAIR_RISERS;
				break;
			case WALL_SLABS:
				includedInPrice = FINISHED_SIDES_INCLUDED_IN_PRICE.WALL_SLABS;
				break;
			default:
				includedInPrice = FINISHED_SIDES_INCLUDED_IN_PRICE.DEFAULT;
				break;
		}

		return includedInPrice;
	}

	hasEmptyPrices() {
		if (!this.price) return true;

		return this.pieces.some(piece => !piece.materialPrice);
	}

	getSelectableOperationTypes(pieceType) {
		let selectableOperationTypes = CONFIGURABLE_OPERATIONS[this.options.decor][this.options.preset];

		if (!selectableOperationTypes) {
			captureMessage(
				`No configurable operations found for decor ${this.options.decor} and preset ${this.options.preset}`,
			);
			return [];
		}

		selectableOperationTypes = selectableOperationTypes.concat(CONFIGURABLE_OPERATIONS.ALL);

		if ([TYPE_3, TYPE_4].includes(pieceType)) {
			selectableOperationTypes = selectableOperationTypes.filter(operationType => operationType !== DEBASING_ROUGH);
		}

		if ([TYPE_7, TYPE_8, TYPE_9, TYPE_10].includes(pieceType)) {
			selectableOperationTypes = selectableOperationTypes.filter(operationType => operationType !== GLUED_CUSHION);

			if ([TYPE_9, TYPE_10].includes(pieceType)) {
				selectableOperationTypes.push(PROFILE);
			}
		}

		return selectableOperationTypes;
	}

	getTotalSquareMeter() {
		let amount = 0;

		this.pieces.forEach(piece => {
			const squareMeter = piece.dimensions.length * piece.dimensions.width;
			amount += squareMeter * piece.amount;
		});

		return amount / 10000;
	}

	getHallNumber(width) {
		let hallNumber;
		const { material, preset } = this.options;
		const height = parseInt(this.options.height);

		const isStandardWidth = STANDARD_WIDTHS.includes(width);

		if (material === MARBLE || material === GRANITE) {
			hallNumber = 2;
		}

		if (material === BLUESTONE) {
			if (height < 4) {
				hallNumber = 2;
			} else {
				if (height === 5) {
					if (isStandardWidth && PRESETS_WITH_STANDARD_WIDTHS.includes(preset)) {
						hallNumber = 4;
					} else {
						hallNumber = 3;
					}
				} else {
					hallNumber = 3;
				}
			}
		}

		return hallNumber;
	}
}

export { Configuration };
