class Manufacturer {
	constructor(id, name, links = [], catalogs = []) {
		this.id = id;
		this.name = name;
		this.links = links;
		this.catalogs = catalogs;
	}
}

export default Manufacturer;
