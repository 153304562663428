import axios from 'axios';
import { Service } from './Service';
import { Parser } from '../helpers/Parser';

class AuthService extends Service {
	async login(formData) {
		return await this.anonymousRequest('post', 'auth/login', formData)
			.then(response => {
				response.data.status = response.status;

				return response.data;
			})
			.catch(error => {
				return error.data;
			});
	}

	async register(formData) {
		return await axios
			.post(this.baseUrl + 'auth/register', formData)
			.then((response, error) => {
				return response.data;
			})
			.catch(error => {
				return error.response.data;
			});
	}

	async isAuthenticated() {
		return await this.authenticatedRequest('post', 'auth').then(response => {
			if (!response || !response.data.data) {
				return null;
			}

			return Parser.parseUser(response.data.data);
		});
	}

	async logOut() {
		return await this.authenticatedRequest('post', 'auth/logout').then(response => {
			return response.data;
		});
	}

	async forgotPassword(formData) {
		return await this.authenticatedRequest('post', 'auth/forgot', formData)
			.then((response, error) => {
				return response.data;
			})
			.catch(error => {
				return error.response.data;
			});
	}

	async resetPassword(formData) {
		return await this.authenticatedRequest('post', 'auth/password/reset', formData)
			.then((response, error) => {
				return response.data;
			})
			.catch(error => {
				return error.response.data;
			});
	}

	async checkMaintenance() {
		return await this.anonymousRequest('get', 'maintenance')
			.then(response => {
				return !response.data.success;
			})
			.catch(() => {
				return true;
			});
	}
}

export { AuthService };
