import { Vector3 } from 'three';
import { ChiseledPiece } from '../../internal';

export class Type8Piece extends ChiseledPiece {
	constructor(
		id,
		dimensions = {
			frontHeight: 0,
			height: 0,
			width: 0,
			length: 0,
			extrusion: 0,
			barWidth: 0,
			cushionWidth: 0,
			protrusion: 0,
		},
		operations = [],
		position = new Vector3(),
	) {
		super(id, dimensions, operations, position);
	}
}
