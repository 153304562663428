import * as React from 'react';
import ManufacturerService from '../../../classes/services/ManufacturerService';
import { useEffect, useState } from 'react';
import { SingleInputModal } from '../../modals/SingleInputModal';
import { addAlertMessage, setWindowIsLoading } from '../../../actions/GeneralActions';
import { useDispatch } from 'react-redux';
import { DANGER } from '../../../constants/Variants';
import { SearchBar } from '../../navigation/SearchBar';
import { ActionsDropdown } from '../../dropdowns/ActionsDropdown';
import { ActionsDropdownItem } from '../../dropdowns/items/ActionsDropdownItem';
import { ConfirmationModal } from '../../modals/ConfirmationModal';
import { AppHeader } from '../../headers/AppHeader';
import { setCurrentManufacturer } from '../../../actions/ManufacturerActions';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';

export const ManufacturersList = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'pages.admin.manufacturerList.';

	const [manufacturers, setManufacturers] = useState([]);
	const [manufacturersToDisplay, setManufacturersToDisplay] = useState([]);
	const [selectedManufacturer, setSelectedManufacturer] = useState();
	const [searchValue, setSearchValue] = useState('');
	const [manufacturerModalIsActive, setManufacturerModalIsActive] = useState(false);

	const manufacturerService = new ManufacturerService();

	const dispatch = useDispatch();

	useEffect(() => {
		getAllManufacturers();
	}, []);

	useEffect(() => {
		if (searchValue.length > 0) {
			setManufacturersToDisplay(
				manufacturers.filter(manufacturer => manufacturer.name.toLowerCase().includes(searchValue.toLowerCase())),
			);
		} else {
			setManufacturersToDisplay(manufacturers);
		}
	}, [searchValue]);

	const getAllManufacturers = () => {
		dispatch(setWindowIsLoading(true));

		manufacturerService
			.getAll()
			.then(response => {
				if (response.success) {
					setManufacturers(response.data);
					setManufacturersToDisplay(response.data);
				} else {
					throw Error(response.message);
				}
			})
			.finally(() => dispatch(setWindowIsLoading(false)));
	};

	const onCloseNewManufacturerModal = name => {
		if (name && name !== '') {
			dispatch(setWindowIsLoading(true));

			manufacturerService
				.create(name)
				.then(response => {
					if (response.success) {
						dispatch(setCurrentManufacturer(response.data));
					} else {
						throw Error(response.message);
					}
				})
				.catch(error => {
					let message = t(prefix + 'createManufacturerFailedMessage');

					if (error.response.status === 409) message = t(prefix + 'createManufacturerFailedAlreadyExistsMessage');

					dispatch(addAlertMessage(DANGER, message));
					dispatch(setWindowIsLoading(false));

					throw error;
				})
				.finally(() => dispatch(setWindowIsLoading(false)));
		}

		setManufacturerModalIsActive(false);
	};

	const closeConfirmationModal = accepted => {
		if (accepted) {
			dispatch(setWindowIsLoading(true));

			manufacturerService
				.delete(selectedManufacturer.id)
				.then(response => {
					if (response.success) {
						getAllManufacturers();
					} else {
						throw Error(response.message);
					}
				})
				.catch(error => {
					dispatch(addAlertMessage(DANGER, t(prefix + 'deleteManufacturerFailedMessage')));
					dispatch(setWindowIsLoading(false));

					throw error;
				});
		}

		setSelectedManufacturer(null);
	};

	const getCatalogsText = length => {
		if (length > 1 || length < 1) {
			return length + ' ' + t(prefix + 'catalogs');
		}

		return length + ' ' + t(prefix + 'catalog');
	};

	return (
		<div>
			<SingleInputModal
				isActive={manufacturerModalIsActive}
				onClose={name => onCloseNewManufacturerModal(name)}
				content={t(prefix + 'addManufacturerModalContent')}
				header={t(prefix + 'addManufacturerModalHeader')}
			/>

			<ConfirmationModal
				isActive={selectedManufacturer != null}
				onClose={accepted => closeConfirmationModal(accepted)}
				content={t(prefix + 'deleteManufacturerModalContent')}
			/>

			<AppHeader title={t(prefix + 'title')} />

			<SearchBar onInput={value => setSearchValue(value)} />

			<button type="button" className="button button--icon-before" onClick={() => setManufacturerModalIsActive(true)}>
				<i className="material-icons">add</i> {t(prefix + 'addButton')}
			</button>

			<div className="list">
				{manufacturersToDisplay.map(manufacturer => {
					return (
						<div className="list__item" key={manufacturer.id}>
							<div className="list__item__content" onClick={() => dispatch(setCurrentManufacturer(manufacturer))}>
								<span>{manufacturer.name}</span>
								<small>{getCatalogsText(manufacturer.catalogs.length)}</small>
							</div>

							<ActionsDropdown>
								<ActionsDropdownItem
									content={t(prefix + 'dropdownDelete')}
									variant={DANGER}
									onClick={() => setSelectedManufacturer(manufacturer)}
								/>
							</ActionsDropdown>
						</div>
					);
				})}
			</div>
		</div>
	);
};

ManufacturersList.propTypes = {};
