import { Vector3 } from 'three';
import { Piece } from '../../internal';

class Type3Piece extends Piece {
	constructor(
		id,
		dimensions = { length: 0, height: 0, width: 0, extrusion: 0, barWidth: 0 },
		operations = [],
		position = new Vector3(),
	) {
		super(id, dimensions, operations, position);
	}
}

export { Type3Piece };
