import * as React from 'react';
import PropTypes from 'prop-types';
import { Title } from './Title';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import history from '../../config/history';
import { useSelector } from 'react-redux';

export const AppHeader = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'headers.appHeader.';

	const currentOffer = useSelector(state => state.offerReducer.currentOffer);

	const toOrderPage = () => {
		if (currentOffer.id < 1) return;

		history.push('/order/' + props.currentOffer.id);
	};

	const renderOrderButton = () => {
		if (props.variant === 'offer') {
			return (
				<button
					className="button button--danger disabled"
					disabled={currentOffer.configurations?.length < 1}
					onClick={() => toOrderPage()}
				>
					{t(prefix + 'orderButton')}
				</button>
			);
		}
	};

	return (
		<div className="app__content__header">
			<Title
				dataCy={props.dataCy}
				title={props.title}
				hasBadge={props.hasBadge}
				badgeContent={props.badgeContent}
				badgeVariant={props.badgeVariant}
			>
				{props.onBackClick ? (
					<i
						data-cy={`${props.dataCy}-backButton`}
						className="pointer material-icons"
						onClick={() => props.onBackClick()}
					>
						arrow_back
					</i>
				) : null}
			</Title>

			<div className="app__content__header__actions">
				{props.children}

				{renderOrderButton()}
			</div>
		</div>
	);
};

AppHeader.propTypes = {
	title: PropTypes.string,
	onBackClick: PropTypes.func,
	onCTAClick: PropTypes.func,
	dataCy: PropTypes.string,
	hasBadge: PropTypes.bool,
	badgeContent: PropTypes.string,
};

AppHeader.defaultProps = {
	hasBadge: false,
	badgeContent: '',
};
