import * as Angles from '../../constants/Angles';
import { INNER } from '../../constants/Angles';
import * as CameraAngles from '../../constants/CameraAngles';
import * as ConnectionStyles from '../../constants/ConnectObjectStyles';
import { BAR_DEPTH, BAR_EXTRUSION, MAX_HEIGHT } from '../../constants/Values';
import { COMPLETELY, PARTIALLY } from '../../constants/FinishedSideStates';

import { Vector3 } from 'three';
import { MeshCreator } from './MeshCreator';
import {
	BASEBOARDS,
	LINTELS,
	PILLARS,
	STAIR_RISERS,
	UPRIGHT_PRESETS,
	VERTICAL_DISPLAYED_PRESETS,
	WALL_SLABS,
} from '../../constants/Presets';
import { MASSIVE_TYPES, TYPE_3, TYPE_4, TYPE_5, TYPE_6, TYPE_7, TYPE_8 } from '../../constants/ObjectTypes';
import {
	DEBASE_ROUGH_TYPES,
	GLUED_CUSHION,
	HEIGHT_COUPE,
	OPERATION_SHAPES,
	RECTANGULAR_CUT_OUT_TYPES,
} from '../../constants/OperationTypes';
import { BACK, BOTTOM, FRONT, LEFT, OTHER, RIGHT, TOP } from '../../constants/ObjectSides';
import { getDistanceFromFront, getDistanceFromLeft } from './OperationHelper';
import { createBoxMesh } from './ThreeHelper';

class VectorHelper {
	static getVectorForNewObject(mainObject, newObject, connectionType, alignment, alignmentDistance) {
		let newPosition = new Vector3();
		let mainDimensions = mainObject.dimensions;
		let newDimensions = newObject.dimensions;
		const meshCreator = new MeshCreator();
		let calcObject = meshCreator.createType1ForCalculating(mainObject, null, false);

		switch (connectionType) {
			case ConnectionStyles.LEFT_UP:
				calcObject.translateX(-(mainDimensions.length / 2 + newDimensions.width / 2));
				calcObject.translateZ(-(newDimensions.length / 2 - mainDimensions.width / 2));

				newPosition = calcObject.position;
				break;
			case ConnectionStyles.LEFT_DOWN:
				calcObject.translateX(-(mainDimensions.length / 2 + newDimensions.width / 2));
				calcObject.translateZ(Math.abs(newDimensions.length / 2 - mainDimensions.width / 2));

				newPosition = calcObject.position;
				break;
			case ConnectionStyles.LEFT_BACK_UP:
				calcObject.translateX(-(mainDimensions.length / 2 - newDimensions.width / 2));
				calcObject.translateZ(-(newDimensions.length / 2 + mainDimensions.width / 2));

				newPosition = calcObject.position;
				break;
			case ConnectionStyles.LEFT_FRONT_DOWN:
				calcObject.translateX(-(mainDimensions.length / 2 - newDimensions.width / 2));
				calcObject.translateZ(Math.abs(newDimensions.length / 2 + mainDimensions.width / 2));

				newPosition = calcObject.position;
				break;
			case ConnectionStyles.LEFT_HORIZONTAL:
				calcObject.translateX(-mainObject.dimensions.length / 2 - newDimensions.length / 2);

				if (alignment) {
					calcObject = translateCalculationObjectToAlignToMainObject(
						calcObject,
						mainObject,
						newObject,
						alignment,
						alignmentDistance,
					);
				}

				newPosition.x = calcObject.position.x;
				newPosition.y = 0;
				newPosition.z = calcObject.position.z;
				break;
			case ConnectionStyles.RIGHT_UP:
				calcObject.translateX(Math.abs(mainDimensions.length / 2 + newDimensions.width / 2));
				calcObject.translateZ(-(newDimensions.length / 2 - mainDimensions.width / 2));

				newPosition = calcObject.position;
				break;
			case ConnectionStyles.RIGHT_DOWN:
				calcObject.translateX(Math.abs(mainDimensions.length / 2 + newDimensions.width / 2));
				calcObject.translateZ(Math.abs(newDimensions.length / 2 - mainDimensions.width / 2));

				newPosition = calcObject.position;
				break;
			case ConnectionStyles.RIGHT_BACK_UP:
				calcObject.translateX(Math.abs(mainDimensions.length / 2 - newDimensions.width / 2));
				calcObject.translateZ(-(newDimensions.length / 2 + mainDimensions.width / 2));

				newPosition = calcObject.position;
				break;
			case ConnectionStyles.RIGHT_FRONT_DOWN:
				calcObject.translateX(Math.abs(mainDimensions.length / 2 - newDimensions.width / 2));
				calcObject.translateZ(Math.abs(newDimensions.length / 2 + mainDimensions.width / 2));

				newPosition = calcObject.position;
				break;
			case ConnectionStyles.RIGHT_HORIZONTAL:
				calcObject.translateX(mainObject.dimensions.length / 2 + newDimensions.length / 2);

				if (alignment) {
					calcObject = translateCalculationObjectToAlignToMainObject(
						calcObject,
						mainObject,
						newObject,
						alignment,
						alignmentDistance,
					);
				}

				newPosition.x = calcObject.position.x;
				newPosition.y = 0;
				newPosition.z = calcObject.position.z;
				break;
			default:
				break;
		}

		newPosition.y = mainObject.dimensions.height / -2 + newObject.dimensions.height / 2;

		return newPosition;
	}

	static getVectorForObjectConnectedByCoupe(anchorObject, anchorCoupe, newObject) {
		let newPosition = new Vector3();
		const coupeType = anchorCoupe.type;

		// Get the vector depending on the side on which the coupe is located
		// The piece will move to this point in space
		let vectorToMoveTo = this.getSideVectorByCoupe(anchorObject, anchorCoupe);

		let newCoupe;

		if (newObject.getOperationsByType(coupeType).length > 0) {
			newCoupe = newObject.getOperationsByType(coupeType)[0];
		} else newCoupe = new Vector3();

		let maxVector = this.getSideVectorByCoupe(newObject, newCoupe);

		if (anchorCoupe.type === HEIGHT_COUPE) {
			if ([LEFT, RIGHT].includes(anchorCoupe.side)) {
				// Set the zPosition so that the bottom side of the new piece will align with the
				// bottom side of the existing piece
				const zPos = anchorObject.dimensions.width / 2 - newObject.dimensions.width / 2;
				vectorToMoveTo.setZ(zPos);

				// Set zPosition to be 0, the middle of the piece
				maxVector.setZ(0);
			}
		}
		// Calculate the actual vector that the object will move to
		let vectorShift = new Vector3().subVectors(vectorToMoveTo, maxVector);
		newPosition.add(vectorShift);

		if (anchorCoupe.type !== HEIGHT_COUPE) {
			newPosition.y = anchorObject.dimensions.height / -2 + newObject.dimensions.height / 2;
		}

		return newPosition;
	}

	static getVectorForNotch(pieceDimensions, operation) {
		let selectedSide = operation.side;

		if (selectedSide == null) return null;

		// Get the dimensions for the operation that we calculate the position of
		let operationDimensions = operation.dimensions;
		let operationDistance = operation.additionalDimension.value;

		let position = new Vector3();

		// Set the new position, depending on what side is selected
		switch (selectedSide) {
			case FRONT:
				position.x = -pieceDimensions.length / 2 + operationDimensions.length / 2 + operationDistance;
				position.z = pieceDimensions.width / 2 - operationDimensions.width / 2;
				break;
			case LEFT:
				// Inverted depth and length here because when the notch is on this side the length and depth are inverted when drawing
				position.x = -pieceDimensions.length / 2 + operationDimensions.width / 2;
				position.z = -pieceDimensions.width / 2 + operationDimensions.length / 2 + operationDistance;
				break;
			case BACK:
				position.x = pieceDimensions.length / 2 - operationDistance - operationDimensions.length / 2;
				// Will do this for all types, which isn't ideal
				position.y = pieceDimensions.extrusion ? pieceDimensions.extrusion / 2 : 0;
				position.z = -pieceDimensions.width / 2 + operationDimensions.width / 2;
				break;
			case RIGHT:
				// Inverted depth and length here because when the notch is on this side the length and depth are inverted when drawing
				position.x = pieceDimensions.length / 2 - operationDimensions.width / 2;
				position.z = pieceDimensions.width / 2 - operationDimensions.length / 2 - operationDistance;
				break;
			default:
				return new Vector3();
		}

		return position;
	}

	static getVectorForCoupe(objectDimensions, { dimensions, side, angle }) {
		let newPosition = null;

		switch (side) {
			case LEFT:
				if (angle === INNER) {
					newPosition = new Vector3(
						-objectDimensions.length / 2,
						MAX_HEIGHT,
						objectDimensions.width / 2 - dimensions.width,
					);
				} else {
					newPosition = new Vector3(-objectDimensions.length / 2, MAX_HEIGHT, -objectDimensions.width / 2);
				}
				break;
			case RIGHT:
				if (angle === INNER) {
					newPosition = new Vector3(objectDimensions.length / 2, MAX_HEIGHT, objectDimensions.width / 2);
				} else {
					newPosition = new Vector3(
						objectDimensions.length / 2,
						MAX_HEIGHT,
						-objectDimensions.width / 2 + dimensions.width,
					);
				}
				break;
			default:
				break;
		}

		return newPosition;
	}

	static getWaterListVector(mainDimensions, side) {
		let newVector = new Vector3();
		const waterListDimensions = { length: mainDimensions.length, width: 1, height: 0.8 };
		let height = mainDimensions.height;

		const distanceFromSide = 3;

		newVector.y = height / -2 + waterListDimensions.height / 2;

		switch (side) {
			case FRONT:
				newVector.z = mainDimensions.width / 2 - distanceFromSide;
				break;
			case BACK:
				newVector.z = -(mainDimensions.width / 2) + distanceFromSide;
				break;
			case LEFT:
				newVector.x = -(mainDimensions.length / 2) + distanceFromSide;
				newVector.z = 0;
				break;
			case RIGHT:
				newVector.x = mainDimensions.length / 2 - distanceFromSide;
				newVector.z = 0;
				break;
			default:
				break;
		}

		return newVector;
	}

	static getVectorForFinishedSideMark(piece, finishedSide) {
		let position = new Vector3();
		// Distance from z axis has to be higher when the piece gets longer, that way the arrow will stay visible
		let distanceZAxis = (piece.dimensions.width / 2) * (piece.dimensions.length / 100);
		let distanceXAxis = piece.dimensions.length / 6;

		const side = finishedSide.side;
		const type = finishedSide.additionalDimension.type;

		switch (side) {
			case FRONT:
				position.z = piece.dimensions.width / 2 + distanceZAxis;
				break;
			case LEFT:
				if (type === PARTIALLY) {
					position.z = piece.dimensions.width / 2 - 5;
				}

				position.x = -(piece.dimensions.length / 2) - distanceXAxis;
				break;
			case BACK:
				position.z -= piece.dimensions.width / 2 + distanceZAxis;
				break;
			case RIGHT:
				if (type === PARTIALLY) {
					position.z = piece.dimensions.width / 2 - 5;
				}

				position.x += piece.dimensions.length / 2 + distanceXAxis;
				break;
			case TOP:
				position.y += 10;
				break;
			case BOTTOM:
				position.y -= 10;
				break;
			default:
				break;
		}

		return position;
	}

	static getVectorForChiseledSide(dimensions, side) {
		let newPosition = new Vector3();

		switch (side) {
			case FRONT:
				newPosition.z = dimensions.width / 2;
				break;
			case LEFT:
				newPosition.x = -(dimensions.length / 2);
				break;
			case BACK:
				newPosition.z = -(dimensions.width / 2);
				break;
			case RIGHT:
				newPosition.x = dimensions.length / 2;
				break;
			default:
				break;
		}

		return newPosition;
	}

	// Compare two vectors, returns true when they are the same and false when they are not
	static compareVectors(vectorA, vectorB) {
		return vectorA.x === vectorB.x && vectorA.y === vectorB.y && vectorA.z === vectorB.z;
	}

	static getRotationValueByAngle(angle) {
		// The value of a complete rotation
		let pi = Math.PI;

		let percentage = angle / 360;

		return pi * percentage * 2;
	}

	static getAngleByOppositeAndAdjacentSides(opposite, adjacent) {
		// Calculate the arc-tangent with the given opposite and adjacent
		let atan = Math.atan(opposite / adjacent);

		// Calculate which percentage the arc-tangent is of PI
		let percentage = atan / Math.PI;

		// Calculate the angle, knowing that a triangle has 3 corners which the sum is 180 of
		let angle = 180 * percentage;

		// We now know the angle, we did know another angle which is always 90 degrees in this situation
		// Subtract the two known angles from the sum of all angles to find the last unknown angle
		// This is the one we need
		let secondAngle = 180 - 90 - angle;

		// Return an array with the two calculated angles
		return [angle, secondAngle];
	}

	static calculateAngleDegrees(mainCoupe, secondCoupe) {
		let angleDegreesMainCoupe = VectorHelper.getAngleByOppositeAndAdjacentSides(
			mainCoupe.dimensions.length,
			mainCoupe.dimensions.width,
		);
		let angleDegreesSecondCoupe = VectorHelper.getAngleByOppositeAndAdjacentSides(
			secondCoupe.dimensions.length,
			secondCoupe.dimensions.width,
		);

		let angleDegrees;

		if (mainCoupe.side === LEFT) {
			angleDegrees = -(angleDegreesMainCoupe[1] + angleDegreesSecondCoupe[1] - 180);
		} else {
			// side === RIGHT
			angleDegrees = -Math.abs(angleDegreesMainCoupe[1] + angleDegreesSecondCoupe[1] - 180);
		}

		return angleDegrees;
	}

	static calculateAngleDegreesHeightCoupe(mainCoupe, secondCoupe) {
		const angleDegreesMainCoupe = VectorHelper.getAngleByOppositeAndAdjacentSides(
			mainCoupe.dimensions.length,
			mainCoupe.dimensions.width,
		);
		const angleDegreesSecondCoupe = VectorHelper.getAngleByOppositeAndAdjacentSides(
			secondCoupe.dimensions.length,
			secondCoupe.dimensions.width,
		);
		let angleDegrees;

		switch (mainCoupe.side) {
			case LEFT:
				angleDegrees = angleDegreesMainCoupe[0] + angleDegreesSecondCoupe[1] - 180;
				break;
			case RIGHT:
				angleDegrees = Math.abs(angleDegreesMainCoupe[0] + angleDegreesSecondCoupe[1] - 180);
				break;
			case FRONT:
				angleDegrees = angleDegreesMainCoupe[0] + angleDegreesSecondCoupe[1] - 180;
				break;
			case BACK:
				angleDegrees = angleDegreesMainCoupe[0] + angleDegreesSecondCoupe[1] - 180;
				break;
		}

		return angleDegrees;
	}

	static getSideVectorByCoupe(object, operation) {
		let vectorToMoveTo = new Vector3();

		object.calculateSideVectors();

		switch (operation.side) {
			case LEFT:
				switch (operation.angle) {
					case Angles.INNER:
						if (operation.type === HEIGHT_COUPE) {
							// Use the location of the bottom side of the piece
							vectorToMoveTo = object.sideVectors[LEFT][2];
						} else {
							let point = createBoxMesh(object.sideVectors[LEFT][3], object.rotation);
							point.translateX(operation.dimensions.length);

							vectorToMoveTo = new Vector3(point.position.x, 0, point.position.z);
						}
						break;
					case Angles.OUTER:
						if (operation.type === HEIGHT_COUPE) {
							vectorToMoveTo = object.sideVectors[LEFT][1];
						} else {
							if (operation.dimensions.width === object.dimensions.width) {
								vectorToMoveTo = object.sideVectors[LEFT][1];
							} else {
								let point = createBoxMesh(object.sideVectors[LEFT][1], object.rotation);
								point.translateZ(-(object.dimensions.width - operation.dimensions.width));

								vectorToMoveTo = new Vector3(point.position.x, 0, point.position.z);
							}
						}
						break;
					default:
						break;
				}
				break;
			case RIGHT:
				switch (operation.angle) {
					case Angles.INNER:
						if (operation.type === HEIGHT_COUPE) {
							vectorToMoveTo = object.sideVectors[RIGHT][2];
						} else {
							let point = createBoxMesh(object.sideVectors[RIGHT][2], object.rotation);
							point.translateX(-operation.dimensions.length);

							vectorToMoveTo = new Vector3(point.position.x, 0, point.position.z);
						}
						break;
					case Angles.OUTER:
						if (operation.type === HEIGHT_COUPE) {
							vectorToMoveTo = object.sideVectors[RIGHT][0];
						} else {
							if (operation.dimensions.width === object.dimensions.width) {
								vectorToMoveTo = object.sideVectors[RIGHT][0];
							} else {
								let point = createBoxMesh(object.sideVectors[RIGHT][1], object.rotation);
								point.translateZ(operation.dimensions.width);

								vectorToMoveTo = new Vector3(point.position.x, 0, point.position.z);
							}
						}
						break;
					default:
						break;
				}
				break;
			case FRONT:
				if (operation.angle === Angles.INNER) {
					vectorToMoveTo = object.sideVectors[FRONT][0];
				} else {
					vectorToMoveTo = object.sideVectors[FRONT][3];
				}
				break;
			case BACK:
				if (operation.angle === Angles.INNER) {
					vectorToMoveTo = object.sideVectors[BACK][1];
				} else {
					vectorToMoveTo = object.sideVectors[BACK][3];
				}
				break;
			default:
				break;
		}

		if (operation.type !== HEIGHT_COUPE) {
			vectorToMoveTo.setY(0);
		}

		return vectorToMoveTo;
	}

	static getGlobalCameraPosition(configuration, cameraAngle) {
		if (configuration == null || configuration.pieces.length === 0) {
			return new Vector3(0, 200, 100);
		}

		let xValues = [];
		let zValues = [];
		let numberSort = (a, b) => {
			return a - b;
		};

		let height;
		const defaultHeight = 200;

		for (let i = 0; i < configuration.pieces.length; i++) {
			xValues.push(configuration.pieces[i].position.x);
			zValues.push(configuration.pieces[i].position.z);
		}

		xValues.sort(numberSort);
		zValues.sort(numberSort);

		//region Bounding box
		const maxX = xValues[xValues.length - 1];
		const minX = xValues[0];

		const maxZ = zValues[zValues.length - 1];
		const minZ = zValues[0];
		//endregion

		let xCenter = Math.abs(minX - maxX) / 2 + minX;
		let zCenter = Math.abs(minZ - maxZ) / 2 + minZ;

		if (xValues.length >= 2) {
			height = Math.abs(xValues[0] - xValues[xValues.length - 1]);
			if (height < defaultHeight) height = defaultHeight;
		} else {
			if (configuration.pieces[0].dimensions.length > 0) {
				height = configuration.pieces[0].dimensions.length * 0.8;
			} else {
				height = 150 * 0.8;
			}
		}

		if (cameraAngle === CameraAngles.BOTTOM) height = -height;

		return new Vector3(xCenter, height, zCenter);
	}

	static getStartAndEndPointForChiseledSide(finishedSideOperation, dimensions, coupes, type) {
		let startingPoint = new Vector3();
		let endingPoint = new Vector3();
		let coupeLengthLeft = 0;
		let coupeLengthRight = 0;
		const side = finishedSideOperation.side;
		const offset = 0.01;

		for (let i = 0; i < coupes.length; i++) {
			if (coupes[i].side === LEFT) {
				if (
					(coupes[i].angle === Angles.OUTER && side === BACK) ||
					(coupes[i].angle === Angles.INNER && side === FRONT)
				) {
					coupeLengthLeft = coupes[i].dimensions.length;
				}
			} else if (coupes[i].side === RIGHT) {
				if (
					(coupes[i].angle === Angles.OUTER && side === BACK) ||
					(coupes[i].angle === Angles.INNER && side === FRONT)
				) {
					coupeLengthRight = coupes[i].dimensions.length;
				}
			} else {
				//
			}
		}

		// In this switch the position is calculated differently than in other
		switch (side) {
			case FRONT:
				startingPoint.x = -(dimensions.length / 2) + coupeLengthLeft;
				startingPoint.z = dimensions.width / 2 + offset;

				endingPoint.x = dimensions.length / 2 - coupeLengthRight;
				endingPoint.z = dimensions.width / 2 + offset;
				break;
			case LEFT:
				if (finishedSideOperation.additionalDimension.type === COMPLETELY) {
					startingPoint.z = -(dimensions.width / 2);
				} else if (finishedSideOperation.additionalDimension.type === PARTIALLY) {
					startingPoint.z = dimensions.width / 2 - 5;
				}

				startingPoint.x = -(dimensions.length / 2);

				endingPoint.x = dimensions.length / 2;
				endingPoint.z = dimensions.width / 2;

				if (type === TYPE_3 || type === TYPE_4 || type === TYPE_5 || type === TYPE_6) {
					startingPoint.z += BAR_DEPTH;
					endingPoint.z += BAR_EXTRUSION;
				}
				break;
			case BACK:
				startingPoint.x = -(dimensions.length / 2) + coupeLengthLeft;
				startingPoint.z = -(dimensions.width / 2);

				endingPoint.x = dimensions.length / 2 - coupeLengthRight;
				endingPoint.z = -(dimensions.width / 2);

				if (type === TYPE_3 || type === TYPE_4 || type === TYPE_5 || type === TYPE_6) {
					startingPoint.y += BAR_EXTRUSION;
					endingPoint.y += BAR_EXTRUSION;
				}
				break;
			case RIGHT:
				if (finishedSideOperation.additionalDimension.type === COMPLETELY) {
					startingPoint.z = -(dimensions.width / 2);
				} else if (finishedSideOperation.additionalDimension.type === PARTIALLY) {
					startingPoint.z = dimensions.width / 2 - 5;
				}

				startingPoint.x = dimensions.length / 2;

				endingPoint.x = dimensions.length / 2;
				endingPoint.z = dimensions.width / 2;

				if (type === TYPE_3 || type === TYPE_4 || type === TYPE_5 || type === TYPE_6) {
					startingPoint.z += BAR_DEPTH;
					endingPoint.z += BAR_EXTRUSION;
				}
				break;
			default:
				break;
		}

		return { startingPoint: startingPoint, endingPoint: endingPoint };
	}

	/**
	 * Returns all angles of the triangle given two sides and the lower left angle
	 *
	 * @param b The left side of the triangle
	 * @param c The bottom side of the triangle
	 * @param A The lower left corner of the triangle
	 * @returns {{A: number, B: number, C: number}}
	 */
	static __getAnglesOfTriangle(b, c, A) {
		const round = number => {
			return Math.round((number + Number.EPSILON) * 1000) / 1000;
		};

		const toRad = number => {
			return number * (Math.PI / 180);
		};

		const toDeg = number => {
			return number * (180 / Math.PI);
		};

		// Convert A to radians instead of degrees
		const ARadians = toRad(A);

		const a = Math.sqrt(Math.pow(b, 2) + Math.pow(c, 2) - 2 * b * c * Math.cos(ARadians));

		const B = round(toDeg(Math.asin((b * Math.sin(ARadians)) / a)));

		const C = round(180 - 90 - B);

		return { A, B, C };
	}

	/**
	 * Returns the height of a triangle when given the A, c and B variables
	 *
	 * @param A The corner on the lower left
	 * @param B The corner on the lower right
	 * @param c The bottom line of the triangle
	 * @returns {number}
	 */
	static __getHeightOfTriangle(A, B, c) {
		const round = number => {
			return Math.round((number + Number.EPSILON) * 1000) / 1000;
		};

		const C = 180 - A - B;

		const BRadians = B * (Math.PI / 180);

		const CRadians = C * (Math.PI / 180);

		return round((c * Math.sin(BRadians)) / Math.sin(CRadians));
	}

	/**
	 * Gets the position on the surface of a slope of a cushion given the distance from the front
	 *
	 * @param dimensions of the object -> Type 8
	 * @param distanceFromFront Distance from the front of the object
	 * @returns number The position on the Y-axis
	 */
	static getPositionOnSurfaceOfCushionSlopingSide(dimensions, distanceFromFront) {
		const angles = this.__getAnglesOfTriangle(
			dimensions.height + dimensions.extrusion - dimensions.frontHeight - dimensions.extrusion,
			dimensions.protrusion,
			90,
		);

		// Y-axis position of the front at the most Z point
		const frontYPos = -(dimensions.height / 2) + dimensions.frontHeight;

		// Add the height of the imaginary triangle
		return frontYPos + this.__getHeightOfTriangle(angles.A, angles.B, distanceFromFront);
	}

	/**
	 * Returns the position of the surface on the Y-axis.
	 *
	 * This should only be used for the type 5 or 6
	 *
	 * @param dimensions
	 * @param distanceFromFront The distance that the position should have from the side
	 * @returns {*}
	 */
	static getPositionOnSurfaceByDistanceFromFront(
		{ height, width, frontHeight, barWidth, extrusion },
		distanceFromFront,
	) {
		frontHeight = frontHeight ?? height;
		barWidth = barWidth ?? 0;
		extrusion = extrusion ?? 0;

		const angles = this.__getAnglesOfTriangle(height - frontHeight - extrusion, width - barWidth, 90);

		// Y-axis position of the front at the most Z point
		const frontYPos = height / -2 + frontHeight;

		// Add the height of the imaginary triangle
		return frontYPos + this.__getHeightOfTriangle(angles.A, angles.B, distanceFromFront);
	}

	static getVectorForSlopingSideCushions(dimensions, side) {
		if (dimensions.frontHeight == null) return null;
		let position;

		if (side === LEFT) {
			position = new Vector3(
				-(dimensions.length / 2),
				-(dimensions.height / 2) + dimensions.frontHeight,
				dimensions.width / 2,
			);
		} else {
			position = new Vector3(
				dimensions.length / 2 - dimensions.cushionWidth,
				-(dimensions.height / 2) + dimensions.frontHeight,
				dimensions.width / 2,
			);
		}

		return position;
	}

	static getCornerCutoutPosition(pieceType, pieceDimensions, operation) {
		let xPos;
		let yPos;
		let zPos;

		if ([TYPE_3, TYPE_4].includes(pieceType) && pieceDimensions.extrusion > 0) {
			yPos = pieceDimensions.extrusion / 2;
		} else {
			yPos = 0;
		}

		if (operation.side === LEFT) {
			if (operation.additionalDimension.type === FRONT) {
				xPos = -(pieceDimensions.length / 2) + operation.dimensions.length / 2;
				zPos = pieceDimensions.width / 2 - operation.dimensions.width / 2;
			} else {
				// BACK
				xPos = -(pieceDimensions.length / 2) + operation.dimensions.length / 2;
				zPos = -(pieceDimensions.width / 2) + operation.dimensions.width / 2;
			}
		} else {
			// RIGHT
			if (operation.additionalDimension.type === FRONT) {
				xPos = pieceDimensions.length / 2 - operation.dimensions.length / 2;
				zPos = pieceDimensions.width / 2 - operation.dimensions.width / 2;
			} else {
				// BACK
				xPos = pieceDimensions.length / 2 - operation.dimensions.length / 2;
				zPos = -(pieceDimensions.width / 2) + operation.dimensions.width / 2;
			}
		}

		return new Vector3(xPos, yPos, zPos);
	}

	static getRoundedCornerPosition(pieceDimensions, operation) {
		let position;
		const radius = operation.dimensions.length;

		if (operation.side === LEFT) {
			if (operation.additionalDimension.type === FRONT) {
				position = new Vector3(-(pieceDimensions.length / 2) + radius, 0, pieceDimensions.width / 2 - radius);
			} else {
				// BACK
				position = new Vector3(-(pieceDimensions.length / 2) + radius, 0, -(pieceDimensions.width / 2) + radius);
			}
		} else {
			// RIGHT
			if (operation.additionalDimension.type === FRONT) {
				position = new Vector3(pieceDimensions.length / 2 - radius, 0, pieceDimensions.width / 2 - radius);
			} else {
				// BACK
				position = new Vector3(pieceDimensions.length / 2 - radius, 0, -(pieceDimensions.width / 2) + radius);
			}
		}

		return position;
	}

	static getRoundProfileBottomPosition(side, operationLength, pieceDimensions) {
		let position;
		const radius = 2;
		let yPos = pieceDimensions.height / -2 + radius;

		const sideZPos = operationLength ? pieceDimensions.width / 2 - operationLength / 2 : 0;

		switch (side) {
			case FRONT:
				position = new Vector3(0, yPos, pieceDimensions.width / 2 - radius);
				break;
			case LEFT:
				position = new Vector3(-(pieceDimensions.length / 2) + radius, yPos, sideZPos);
				break;
			case BACK:
				position = new Vector3(0, yPos, -(pieceDimensions.width / 2) + radius);
				break;
			case RIGHT:
				position = new Vector3(pieceDimensions.length / 2 - radius, yPos, sideZPos);
				break;
			default:
				position = new Vector3();
				break;
		}

		return position;
	}

	static getProfile3Position(side, operationLength, piece) {
		let position;
		const radius = 2;
		const height = [TYPE_5, TYPE_6].includes(piece.type)
			? piece.dimensions.height / 2 - piece.dimensions.extrusion
			: piece.dimensions.height / 2;

		const sideZPos = operationLength ? piece.dimensions.width / 2 - operationLength / 2 : 0;

		switch (side) {
			case FRONT:
				position = new Vector3(getProfileType3XPosition(piece), height - radius, piece.dimensions.width / 2 - radius);
				break;
			case LEFT:
				position = new Vector3(-(piece.dimensions.length / 2) + radius, height - radius, sideZPos);
				break;
			case BACK:
				position = new Vector3(0, height - radius, -(piece.dimensions.width / 2) + radius);
				break;
			case RIGHT:
				position = new Vector3(piece.dimensions.length / 2 - radius, height - radius, sideZPos);
				break;
			default:
				position = new Vector3();
				break;
		}

		return position;
	}

	static getProfile4Position(operation, piece) {
		let position = new Vector3();
		const side = operation.side;
		let yPos;
		let zPos;

		if (operation.data === TOP) {
			if ([TYPE_5, TYPE_6].includes(piece.type)) {
				yPos = piece.dimensions.height / 2 - operation.dimensions.height - piece.dimensions.extrusion;
			} else {
				yPos = piece.dimensions.height / 2 - operation.dimensions.height;
			}
		} else {
			// BOTTOM
			yPos = piece.dimensions.height / -2 + operation.dimensions.height;
		}

		switch (side) {
			case FRONT:
				let xPos = -(piece.dimensions.length / 2);

				if ([TYPE_7, TYPE_8].includes(piece.type)) {
					xPos += piece.dimensions.cushionWidth;
				} else {
					const leftCushion = piece.getOperationsByType(GLUED_CUSHION).find(c => c.side === LEFT);

					if (leftCushion) {
						xPos += leftCushion.dimensions.length;
					}
				}

				position = new Vector3(xPos, yPos, piece.dimensions.width / 2);
				break;
			case LEFT:
				zPos = operation.dimensions.length
					? piece.dimensions.width / 2 - operation.dimensions.length
					: -(piece.dimensions.width / 2);

				position = new Vector3(-(piece.dimensions.length / 2), yPos, zPos);
				break;
			case BACK:
				position = new Vector3(piece.dimensions.length / 2, yPos, -(piece.dimensions.width / 2));
				break;
			case RIGHT:
				position = new Vector3(piece.dimensions.length / 2, yPos, piece.dimensions.width / 2);
				break;
			default:
				break;
		}

		return position;
	}

	static getDrillHolePosition(pieceDimensions, distanceFromLeft, distanceFromFront) {
		return new Vector3(
			-(pieceDimensions.length / 2) + distanceFromLeft,
			0,
			pieceDimensions.width / 2 - distanceFromFront,
		);
	}

	static getCoupeOverLengthPosition(pieceDimensions, operation) {
		let position;

		if (operation.side === FRONT) {
			if (operation.additionalDimension.type === LEFT) {
				position = new Vector3(0, pieceDimensions.height / 2, pieceDimensions.width / 2);
			} else {
				position = new Vector3(0, -(pieceDimensions.height / 2), pieceDimensions.width / 2);
			}
		} else {
			if (operation.additionalDimension.type === LEFT) {
				position = new Vector3(0, -(pieceDimensions.height / 2), -(pieceDimensions.width / 2));
			} else {
				position = new Vector3(0, pieceDimensions.height / 2, -(pieceDimensions.width / 2));
			}
		}

		return position;
	}

	static getVectorForGluedCushion(pieceDimensions, operation, pieceType = '') {
		let position;
		pieceDimensions = Object.assign({}, pieceDimensions);
		// Added 0.01 to make the cushion hover slightly, that way the renderer will draw a line between
		// the cushion and the piece showing that it is glued rather than a massive piece of stone
		let yPos = pieceDimensions.height / 2 + operation.dimensions.height / 2 + 0.01;
		let zPos;

		if (MASSIVE_TYPES.includes(pieceType) && pieceDimensions.extrusion) {
			yPos -= pieceDimensions.extrusion;
		}

		if (operation.additionalDimension.type === FRONT) {
			zPos = pieceDimensions.width / 2 - operation.dimensions.width / 2;
		} else {
			zPos = -(pieceDimensions.width / 2) + operation.dimensions.width / 2;

			if (pieceDimensions.barWidth) {
				zPos += pieceDimensions.barWidth;
			}
		}

		if (operation.side === LEFT) {
			position = new Vector3(-(pieceDimensions.length / 2) + operation.dimensions.length / 2, yPos, zPos);
		} else {
			position = new Vector3(pieceDimensions.length / 2 - operation.dimensions.length / 2, yPos, zPos);
		}

		return position;
	}

	static getVectorForDebasingRough(pieceDimensions, operation) {
		let xPos, yPos, zPos;

		switch (operation.additionalDimension.type) {
			case DEBASE_ROUGH_TYPES.FRONT_TO_BACK:
				xPos = pieceDimensions.length / -2;
				zPos = pieceDimensions.width / -2;

				if (operation.side === TOP) {
					yPos = pieceDimensions.height / 2;
				} else {
					yPos = pieceDimensions.height / -2;
				}
				break;
			case DEBASE_ROUGH_TYPES.BACK_TO_FRONT:
				xPos = pieceDimensions.length / -2;
				zPos = pieceDimensions.width / 2;

				if (operation.side === TOP) {
					yPos = pieceDimensions.height / 2;
				} else {
					yPos = pieceDimensions.height / -2;
				}
				break;
			case DEBASE_ROUGH_TYPES.MIDDLE_TO_SIDES:
				xPos = 0;
				yPos = 0;
				zPos = 0;
				break;
			default:
				break;
		}

		return new Vector3(xPos, yPos, zPos);
	}

	static getVectorForRectangularCutOut(
		pieceDimensions,
		operation,
		{ horizontalSide, horizontalValue },
		{ verticalSide, verticalValue },
	) {
		let distanceFromLeft = getDistanceFromLeft(pieceDimensions, horizontalSide, horizontalValue);
		let distanceFromFront = getDistanceFromFront(pieceDimensions, verticalSide, verticalValue);

		if (operation.additionalDimension.type === RECTANGULAR_CUT_OUT_TYPES.ROUND) {
			return this.getDrillHolePosition(pieceDimensions, distanceFromLeft, distanceFromFront);
		}

		if (horizontalSide === RIGHT) distanceFromLeft -= operation.dimensions.length;
		if (verticalSide === BACK) distanceFromFront -= operation.dimensions.width;

		return new Vector3(
			-(pieceDimensions.length / 2) + distanceFromLeft + operation.dimensions.length / 2,
			0,
			pieceDimensions.width / 2 - distanceFromFront - operation.dimensions.width / 2,
		);
	}

	static getAnchorHolePosition(pieceDimensions, operation, verticalInformation, horizontalInformation) {
		let xPos;
		let yPos;
		let zPos;

		switch (operation.side) {
			case LEFT:
				xPos = pieceDimensions.length / -2 + operation.dimensions.width / 2;
				if (verticalInformation.side === TOP) {
					yPos = pieceDimensions.height / 2 - verticalInformation.value;
				} else {
					yPos = pieceDimensions.height / -2 + verticalInformation.value;
				}
				if (horizontalInformation.side === BACK) {
					zPos = pieceDimensions.width / -2 + horizontalInformation.value;
				} else {
					zPos = pieceDimensions.width / 2 - horizontalInformation.value;
				}
				break;
			case BACK:
				if (horizontalInformation.side === LEFT) {
					xPos = -(pieceDimensions.length / 2) + horizontalInformation.value;
				} else {
					xPos = pieceDimensions.length / 2 - horizontalInformation.value;
				}
				break;
			case RIGHT:
				xPos = pieceDimensions.length / 2 - operation.width / 2;
				break;
		}

		return new Vector3(xPos, yPos, zPos);
	}

	static getHeightCoupePosition(pieceDimensions, operation, preset) {
		let position;

		switch (operation.side) {
			case FRONT:
				if (operation.angle === Angles.OUTER) {
					position = new Vector3(-(pieceDimensions.length / 2), pieceDimensions.height / 2, pieceDimensions.width / 2);
				} else {
					position = new Vector3(pieceDimensions.length / 2, -(pieceDimensions.height / 2), pieceDimensions.width / 2);
				}
				break;
			case LEFT:
				if (UPRIGHT_PRESETS.includes(preset)) {
					// Invert the position
					if (operation.angle === Angles.OUTER) {
						position = new Vector3(
							-(pieceDimensions.length / 2),
							-(pieceDimensions.height / 2),
							pieceDimensions.width / 2,
						);
					} else {
						position = new Vector3(
							-(pieceDimensions.length / 2),
							pieceDimensions.height / 2,
							-(pieceDimensions.width / 2),
						);
					}
				} else {
					if (operation.angle === Angles.OUTER) {
						position = new Vector3(
							-(pieceDimensions.length / 2),
							pieceDimensions.height / 2,
							-(pieceDimensions.width / 2),
						);
					} else {
						position = new Vector3(
							-(pieceDimensions.length / 2),
							-(pieceDimensions.height / 2),
							pieceDimensions.width / 2,
						);
					}
				}
				break;
			case BACK:
				if (operation.angle === Angles.INNER) {
					position = new Vector3(
						-(pieceDimensions.length / 2),
						-(pieceDimensions.height / 2),
						-(pieceDimensions.width / 2),
					);
				} else {
					position = new Vector3(pieceDimensions.length / 2, pieceDimensions.height / 2, -(pieceDimensions.width / 2));
				}
				break;
			case RIGHT:
				if (UPRIGHT_PRESETS.includes(preset)) {
					// Invert the position
					if (operation.angle === Angles.INNER) {
						position = new Vector3(pieceDimensions.length / 2, pieceDimensions.height / 2, pieceDimensions.width / 2);
					} else {
						position = new Vector3(
							pieceDimensions.length / 2,
							-(pieceDimensions.height / 2),
							-(pieceDimensions.width / 2),
						);
					}
				} else {
					if (operation.angle === Angles.INNER) {
						position = new Vector3(
							pieceDimensions.length / 2,
							-(pieceDimensions.height / 2),
							-(pieceDimensions.width / 2),
						);
					} else {
						position = new Vector3(pieceDimensions.length / 2, pieceDimensions.height / 2, pieceDimensions.width / 2);
					}
				}
				break;
			default:
				position = new Vector3();
				break;
		}

		return position;
	}

	static getGroovesPosition(pieceDimensions) {
		const distanceFromSide = 3;
		const yPos = this.getPositionOnSurfaceByDistanceFromFront(pieceDimensions, distanceFromSide) - 0.05;
		const zPos = pieceDimensions.width / 2 - distanceFromSide;

		return new Vector3(0, yPos, zPos);
	}

	static getEyeSpritePosition(preset, pieceWidth) {
		let distance;
		let position;

		if (VERTICAL_DISPLAYED_PRESETS.includes(preset) || UPRIGHT_PRESETS.includes(preset)) {
			position = new Vector3(0, pieceWidth / 2 + 20, 0);
		} else {
			if (pieceWidth > 0 && pieceWidth < 3) {
				distance = pieceWidth * 10;
			} else if (pieceWidth >= 3 && pieceWidth < 6) {
				distance = pieceWidth * 5;
			} else if (pieceWidth >= 6 && pieceWidth < 12) {
				distance = pieceWidth * 2.5;
			} else if (pieceWidth >= 12 && pieceWidth < 20) {
				distance = pieceWidth * 2;
			} else if (pieceWidth >= 20 && pieceWidth < 30) {
				distance = pieceWidth * 1.4;
			} else if (pieceWidth >= 30 && pieceWidth < 40) {
				distance = pieceWidth * 1.2;
			} else {
				distance = pieceWidth * 0.95;
			}

			position = new Vector3(0, 0, distance);
		}

		return position;
	}

	static getRabatPosition(pieceDimensions, rabat) {
		let xPos = 0;
		const yPos =
			rabat.additionalDimension.type === TOP
				? pieceDimensions.height / 2 - rabat.dimensions.height / 2
				: pieceDimensions.height / -2 + rabat.dimensions.height / 2;
		let zPos = 0;

		if (rabat.side === LEFT) {
			xPos = pieceDimensions.length / -2 + rabat.dimensions.width / 2;
		} else if (rabat.side === RIGHT) {
			xPos = pieceDimensions.length / 2 - rabat.dimensions.width / 2;
		}

		if (rabat.side === FRONT) {
			zPos = pieceDimensions.width / 2 - rabat.dimensions.width / 2;
		} else if (rabat.side === BACK) {
			zPos = pieceDimensions.width / -2 + rabat.dimensions.width / 2;
		}

		return new Vector3(xPos, yPos, zPos);
	}

	static getNotchOverLengthPosition(pieceDimensions, notchOverLength) {
		let xPos = 0;
		const yPos =
			notchOverLength.additionalDimension.type === TOP
				? pieceDimensions.height / 2 - notchOverLength.dimensions.height / 2
				: pieceDimensions.height / -2 + notchOverLength.dimensions.height / 2;
		let zPos = 0;
		const distanceFromSide = notchOverLength.additionalDimension.value;

		switch (notchOverLength.side) {
			case LEFT:
				xPos = pieceDimensions.length / -2 + notchOverLength.dimensions.width / 2;
				xPos += distanceFromSide;
				break;
			case RIGHT:
				xPos = pieceDimensions.length / 2 - notchOverLength.dimensions.width / 2;
				xPos -= distanceFromSide;
				break;
			case FRONT:
				zPos = pieceDimensions.width / 2 - notchOverLength.dimensions.width / 2;
				zPos -= distanceFromSide;
				break;
			case BACK:
				zPos = pieceDimensions.width / -2 + notchOverLength.dimensions.width / 2;
				zPos += distanceFromSide;
				break;
		}

		return new Vector3(xPos, yPos, zPos);
	}
}

const getXFieldValueByPosition = (currentOperation, pieceDimensions, horizontalSide) => {
	if (!currentOperation) return;

	let value;
	let operationPosition = currentOperation.originalPosition ?? currentOperation.position;

	if (horizontalSide === LEFT) {
		value = pieceDimensions.length / -2 - operationPosition.x;
	} else {
		value = pieceDimensions.length / 2 - operationPosition.x;
	}

	value = Math.abs(value);

	if (currentOperation.additionalDimension.type === OPERATION_SHAPES.RECTANGULAR) {
		const operationLength = currentOperation ? currentOperation.dimensions.length : 0;
		value -= operationLength / 2;
	}

	if (isNaN(value)) {
		value = 0;
	} else {
		if (value % 1 > 0) {
			value = value.toFixed(2);
			value = parseFloat(value);
		}
	}

	return value;
};

const getYFieldValueByPosition = (currentOperation, pieceDimensions, verticalSide) => {
	if (!currentOperation) return;

	let operationPosition = currentOperation.originalPosition ?? currentOperation.position;
	const zPosition = operationPosition.z;
	let value;

	if (verticalSide === FRONT) {
		value = pieceDimensions.width / 2 - zPosition;
	} else {
		value = pieceDimensions.width / -2 - zPosition;
	}

	value = Math.abs(value);

	if (currentOperation.additionalDimension.type === OPERATION_SHAPES.RECTANGULAR) {
		const operationWidth = currentOperation.dimensions.width;
		value -= operationWidth / 2;
	}

	if (isNaN(value)) {
		value = 0;
	} else {
		if (value % 1 > 0) {
			value = value.toFixed(2);
			value = parseFloat(value);
		}
	}

	return value;
};

function getRotationByPreset(preset) {
	let rotationVector;

	switch (preset) {
		case BASEBOARDS:
		case STAIR_RISERS:
		case WALL_SLABS:
		case LINTELS:
			rotationVector = new Vector3(90, 0, 0);
			break;
		case PILLARS:
			rotationVector = new Vector3(0, 90, 90);
			break;
		default:
			rotationVector = new Vector3();
			break;
	}

	return rotationVector;
}

function getProfileType3XPosition(piece) {
	let xPos = 0;

	const cushions = piece.getOperationsByType(GLUED_CUSHION);

	if (cushions.length === 1) {
		if (cushions[0].side === LEFT) {
			xPos += cushions[0].dimensions.length / 2;
		} else if (cushions[0].side === RIGHT) {
			xPos -= cushions[0].dimensions.length / 2;
		}
	} else if (cushions.length === 2) {
		const leftCushion = cushions.find(c => c.side === LEFT);
		const rightCushion = cushions.find(c => c.side === RIGHT);

		const lengthDifference = Math.abs(leftCushion.dimensions.length - rightCushion.dimensions.length);

		if (leftCushion.dimensions.length > rightCushion.dimensions.length) {
			xPos += lengthDifference / 2;
		} else {
			xPos -= lengthDifference / 2;
		}
	}

	return xPos;
}

function translateCalculationObjectToAlignToMainObject(
	calcObject,
	mainObject,
	newObject,
	alignment,
	alignmentDistance,
) {
	if (!alignment) return;

	let zTranslation;

	if ([FRONT, OTHER].includes(alignment)) {
		zTranslation = Math.abs(mainObject.dimensions.width - newObject.dimensions.width) / 2;

		if (alignment === OTHER) {
			zTranslation += parseFloat(alignmentDistance);
		}

		if (mainObject.dimensions.width < newObject.dimensions.width) zTranslation = -zTranslation;
	}

	if (alignment === BACK) {
		zTranslation = Math.abs(mainObject.dimensions.width - newObject.dimensions.width) / 2;

		if (mainObject.dimensions.width > newObject.dimensions.width) zTranslation = -zTranslation;
	}

	return calcObject.translateZ(zTranslation);
}

export { VectorHelper, getXFieldValueByPosition, getYFieldValueByPosition, getRotationByPreset };
