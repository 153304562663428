import * as ThreeColors from '../../constants/ThreeConstants';
import {
	BufferAttribute,
	BufferGeometry,
	EdgesGeometry,
	Geometry,
	Line,
	LineBasicMaterial,
	LineDashedMaterial,
	LineSegments,
	Vector3,
} from 'three';
import { BAR_EXTRUSION, WATERLIST_DISTANCE_FROM_SIDE } from '../../constants/Values';
import { OutlinesGeometry } from '../../threeJsPlugins/OutlinesGeometry';
import { VectorHelper } from './VectorHelper';

import { PROFILE } from '../../constants/OperationTypes';
import { TYPE_2, TYPE_3, TYPE_4, TYPE_5, TYPE_6, TYPE_8, TYPE_10, TYPE_9 } from '../../constants/ObjectTypes';
import { LEFT, RIGHT, BACK, FRONT } from '../../constants/ObjectSides';

export class LineCreator {
	static createStandardLines(object) {
		if (object == null) return null;
		const edges = new EdgesGeometry(object.geometry);
		const lines = new LineSegments(edges, new LineBasicMaterial({ color: ThreeColors.DEFAULT_LINE_COLOR }));
		lines.position.add(object.position);

		return lines;
	}

	static createOutlines(object) {
		if (object == null) return null;
		let outlines = new OutlinesGeometry(object.geometry);
		const lines = new LineSegments(outlines, new LineBasicMaterial({ color: ThreeColors.DEFAULT_LINE_COLOR }));
		lines.position.add(object.position);

		return lines;
	}

	static createPartLines(dimensions, parts, type) {
		if (parts.length <= 1) return [];

		let pieceLines = [];
		let nextPiecePosition = -(dimensions.length / 2);

		parts.forEach((part, index) => {
			if (index !== 0) {
				if (type === TYPE_3 || type === TYPE_4) {
					pieceLines.push(this.__createPieceLineType3(nextPiecePosition, dimensions));
				} else if (type === TYPE_5 || type === TYPE_6) {
					pieceLines.push(this.__createPieceLineType5(nextPiecePosition, dimensions));
				} else {
					pieceLines.push(this.__createPieceLineType1(nextPiecePosition, dimensions));
				}
			}

			nextPiecePosition += parseFloat(part.length);
		});

		return pieceLines;
	}

	static __createPieceLineType1(xPoint, dimensions) {
		// geometry
		let geometry = new BufferGeometry();

		// attributes
		let positions = new Float32Array(5 * 3); // 3 vertices per point
		// X, Y, Z
		positions[0] = xPoint;
		positions[1] = dimensions.height / 2 + 0.1;
		positions[2] = -(dimensions.width / 2);

		positions[3] = xPoint;
		positions[4] = dimensions.height / 2;
		positions[5] = dimensions.width / 2;

		positions[6] = xPoint;
		positions[7] = -(dimensions.height / 2);
		positions[8] = dimensions.width / 2;

		positions[9] = xPoint;
		positions[10] = -(dimensions.height / 2);
		positions[11] = -(dimensions.width / 2);

		positions[12] = xPoint;
		positions[13] = dimensions.height / 2;
		positions[14] = -(dimensions.width / 2);

		geometry.setAttribute('position', new BufferAttribute(positions, 3));

		// drawcalls
		geometry.setDrawRange(0, positions.length);

		// material
		let material = new LineBasicMaterial({ color: 0x000000 });

		// line
		return new Line(geometry, material);
	}

	static __createPieceLineType3(xPoint, dimensions) {
		// geometry
		let geometry = new BufferGeometry();
		const extrusion = dimensions.extrusion / 2 + BAR_EXTRUSION;

		// attributes
		let positions = new Float32Array(9 * 3); // 3 vertices per point
		// X, Y, Z
		positions[0] = xPoint;
		positions[1] = dimensions.height / 2;
		positions[2] = -(dimensions.width / 2) + 1;

		positions[3] = xPoint;
		positions[4] = dimensions.height / 2;
		positions[5] = dimensions.width / 2;

		positions[6] = xPoint;
		positions[7] = -(dimensions.height / 2);
		positions[8] = dimensions.width / 2;

		positions[9] = xPoint;
		positions[10] = -(dimensions.height / 2);
		positions[11] = -(dimensions.width / 2) + 1;

		positions[12] = xPoint;
		positions[13] = -(dimensions.height / 2) + 1;
		positions[14] = -(dimensions.width / 2) + 1;

		positions[15] = xPoint;
		positions[16] = -(dimensions.height / 2) + 1;
		positions[17] = -(dimensions.width / 2);

		positions[18] = xPoint;
		positions[19] = dimensions.height / 2 + extrusion;
		positions[20] = -(dimensions.width / 2);

		positions[21] = xPoint;
		positions[22] = dimensions.height / 2 + extrusion;
		positions[23] = -(dimensions.width / 2) + 1;

		positions[24] = xPoint;
		positions[25] = dimensions.height / 2;
		positions[26] = -(dimensions.width / 2) + 1;

		geometry.setAttribute('position', new BufferAttribute(positions, 3));

		// drawcalls
		geometry.setDrawRange(0, positions.length);

		// material
		let material = new LineBasicMaterial({ color: 0x000000 });

		// line
		return new Line(geometry, material);
	}

	static __createPieceLineType5(xPoint, dimensions) {
		// geometry
		let geometry = new BufferGeometry();
		const frontHeightPosition = -(dimensions.height / 2) + dimensions.frontHeight;

		// attributes
		let positions = new Float32Array(7 * 3); // 3 vertices per point
		// X, Y, Z
		positions[0] = xPoint;
		positions[1] = dimensions.height / 2;
		positions[2] = -(dimensions.width / 2) + dimensions.barWidth;

		positions[3] = xPoint;
		positions[4] = frontHeightPosition;
		positions[5] = dimensions.width / 2;

		positions[6] = xPoint;
		positions[7] = -(dimensions.height / 2);
		positions[8] = dimensions.width / 2;

		positions[9] = xPoint;
		positions[10] = -(dimensions.height / 2);
		positions[11] = -(dimensions.width / 2);

		positions[12] = xPoint;
		positions[13] = dimensions.height / 2 + dimensions.extrusion;
		positions[14] = -(dimensions.width / 2);

		positions[15] = xPoint;
		positions[16] = dimensions.height / 2 + dimensions.extrusion;
		positions[17] = -(dimensions.width / 2) + dimensions.barWidth;

		positions[18] = xPoint;
		positions[19] = dimensions.height / 2;
		positions[20] = -(dimensions.width / 2) + dimensions.barWidth;

		geometry.setAttribute('position', new BufferAttribute(positions, 3));

		// drawcalls
		geometry.setDrawRange(0, positions.length);

		// material
		let material = new LineBasicMaterial({ color: 0x000000 });

		// line
		return new Line(geometry, material);
	}

	static createChiseledSideLineByType(type, side, piece, position) {
		let geometry = new BufferGeometry();

		let positions = new Float32Array(2 * 3);

		const topPoint = this.getTopPointForChiseledLineByObjectType(type, piece, position, side);
		const bottomPoint = [TYPE_9, TYPE_10].includes(type) ? piece.dimensions.width / 2 : piece.dimensions.height / -2;
		const surfacePosY = piece.dimensions.height / 2;

		if (type === TYPE_10) {
			const profile = piece.operations.find(o => o.type === PROFILE && o.side === BACK);
			if (profile) positions = new Float32Array(4 * 3);
			positions[0] = position.x;
			positions[1] = surfacePosY;
			positions[2] = topPoint;

			positions[3] = position.x;
			positions[4] = surfacePosY;
			positions[5] = bottomPoint;

			if (profile) {
				positions[6] = position.x;
				positions[7] = surfacePosY;
				positions[8] = topPoint;

				positions[9] = position.x;
				positions[10] = piece.dimensions.height / 2 - profile.dimensions.height;
				positions[11] = piece.dimensions.width / -2;
			}
		} else {
			positions[0] = position.x;
			positions[1] = topPoint;
			positions[2] = position.z;

			positions[3] = position.x;
			positions[4] = bottomPoint;
			positions[5] = position.z;
		}

		geometry.setAttribute('position', new BufferAttribute(positions, 3));

		// drawcalls
		geometry.setDrawRange(0, positions.length);

		// material
		let material = new LineBasicMaterial({ color: 0x000000 });

		// line
		return new Line(geometry, material);
	}

	static getTopPointForChiseledLineByObjectType(type, piece, position, side) {
		let topPoint;

		switch (type) {
			case TYPE_2:
			case TYPE_4:
				topPoint = piece.dimensions.height / 2;
				break;
			case TYPE_6:
				topPoint = getTopPointForType6(type, piece.dimensions, position, side);
				break;
			case TYPE_8:
				topPoint = getTopPointForType8(type, piece.dimensions, position, side);
				break;
			case TYPE_10:
				const profile = piece.operations.find(o => o.type === PROFILE && o.side === BACK);

				if (profile) {
					topPoint = piece.dimensions.width / -2 + profile.dimensions.width;
				} else {
					topPoint = piece.dimensions.width / -2;
				}
				break;
			default:
				break;
		}

		return topPoint;
	}

	static createType5Lines(dimensions) {
		let geometry = new BufferGeometry();

		let positions = new Float32Array(30 * 3);
		const frontHeightPosition = -(dimensions.height / 2) + dimensions.frontHeight;

		// backHeight - frontHeight

		positions[0] = -(dimensions.length / 2);
		positions[1] = dimensions.height / 2;
		positions[2] = -(dimensions.width / 2) + dimensions.barWidth;

		positions[3] = -(dimensions.length / 2);
		positions[4] = frontHeightPosition;
		positions[5] = dimensions.width / 2;

		positions[6] = -(dimensions.length / 2);
		positions[7] = -(dimensions.height / 2);
		positions[8] = dimensions.width / 2;

		positions[9] = -(dimensions.length / 2);
		positions[10] = -(dimensions.height / 2);
		positions[11] = -(dimensions.width / 2);

		positions[12] = -(dimensions.length / 2);
		positions[13] = dimensions.height / 2 + dimensions.extrusion;
		positions[14] = -(dimensions.width / 2);

		positions[15] = dimensions.length / 2;
		positions[16] = dimensions.height / 2 + dimensions.extrusion;
		positions[17] = -(dimensions.width / 2);

		positions[18] = dimensions.length / 2;
		positions[19] = -(dimensions.height / 2);
		positions[20] = -(dimensions.width / 2);

		positions[21] = -(dimensions.length / 2);
		positions[22] = -(dimensions.height / 2);
		positions[23] = -(dimensions.width / 2);

		positions[24] = -(dimensions.length / 2);
		positions[25] = dimensions.height / 2 + dimensions.extrusion;
		positions[26] = -(dimensions.width / 2);

		positions[27] = -(dimensions.length / 2);
		positions[28] = dimensions.height / 2 + dimensions.extrusion;
		positions[29] = -(dimensions.width / 2) + dimensions.barWidth;

		positions[30] = dimensions.length / 2;
		positions[31] = dimensions.height / 2 + dimensions.extrusion;
		positions[32] = -(dimensions.width / 2) + dimensions.barWidth;

		positions[33] = dimensions.length / 2;
		positions[34] = dimensions.height / 2 + dimensions.extrusion;
		positions[35] = -(dimensions.width / 2);

		positions[36] = dimensions.length / 2;
		positions[37] = -(dimensions.height / 2);
		positions[38] = -(dimensions.width / 2);

		positions[39] = dimensions.length / 2;
		positions[40] = -(dimensions.height / 2);
		positions[41] = dimensions.width / 2;

		positions[42] = dimensions.length / 2;
		positions[43] = frontHeightPosition;
		positions[44] = dimensions.width / 2;

		positions[45] = -(dimensions.length / 2);
		positions[46] = frontHeightPosition;
		positions[47] = dimensions.width / 2;

		positions[48] = -(dimensions.length / 2);
		positions[49] = dimensions.height / 2;
		positions[50] = -(dimensions.width / 2) + dimensions.barWidth;

		positions[51] = -(dimensions.length / 2);
		positions[52] = dimensions.height / 2 + dimensions.extrusion;
		positions[53] = -(dimensions.width / 2) + dimensions.barWidth;

		positions[54] = dimensions.length / 2;
		positions[55] = dimensions.height / 2;
		positions[56] = -(dimensions.width / 2) + dimensions.barWidth;

		positions[57] = dimensions.length / 2;
		positions[58] = dimensions.height / 2 + dimensions.extrusion;
		positions[59] = -(dimensions.width / 2) + dimensions.barWidth;

		positions[60] = dimensions.length / 2;
		positions[61] = dimensions.height / 2;
		positions[62] = -(dimensions.width / 2) + dimensions.barWidth;

		// positions[63] = dimensions.length / 2;
		// positions[64] = frontHeightPosition;
		// positions[65] = dimensions.width / 2;

		geometry.setAttribute('position', new BufferAttribute(positions, 3));

		// drawcalls
		geometry.setDrawRange(0, positions.length);

		// material
		let material = new LineBasicMaterial({ color: 0x000000 });

		// line
		return new Line(geometry, material);
	}

	static createWaterlistLines(side, boundingBox, dimensions, position) {
		let dashedLineGeometry = new Geometry();
		let startingPoint;
		let endingPoint;

		const dashedLineMaterial = new LineDashedMaterial({
			color: 0x000000,
			linewidth: 1,
			dashSize: 3,
			gapSize: 1,
		});

		if (side === FRONT || side === BACK) {
			let yPosition;

			if (dimensions.frontHeight != null) {
				if (side === FRONT) {
					yPosition = VectorHelper.getPositionOnSurfaceByDistanceFromFront(dimensions, WATERLIST_DISTANCE_FROM_SIDE);
				} else {
					yPosition = VectorHelper.getPositionOnSurfaceByDistanceFromFront(
						dimensions,
						dimensions.width - WATERLIST_DISTANCE_FROM_SIDE,
					);
				}
			} else {
				yPosition = dimensions.height / 2 + 0.01;
			}

			startingPoint = new Vector3(boundingBox.min.x, yPosition + 0.1, position.z);
			endingPoint = new Vector3(boundingBox.max.x, yPosition + 0.1, position.z);
		} else {
			let startingYPos = dimensions.height / 2;
			let endingYPos = dimensions.height / 2;

			// Checking if barWidth is undefined, will result in NaN if it is
			const startingZPos =
				dimensions.barWidth != null ? -(dimensions.width / 2) + dimensions.barWidth : -(dimensions.width / 2);

			if (dimensions.frontHeight != null) {
				startingYPos = VectorHelper.getPositionOnSurfaceByDistanceFromFront(
					dimensions,
					dimensions.width - dimensions.barWidth,
				);
				endingYPos = VectorHelper.getPositionOnSurfaceByDistanceFromFront(dimensions, WATERLIST_DISTANCE_FROM_SIDE);
			}

			startingPoint = new Vector3(position.x, startingYPos + 0.04, startingZPos + WATERLIST_DISTANCE_FROM_SIDE);
			endingPoint = new Vector3(position.x, endingYPos + 0.04, dimensions.width / 2 - WATERLIST_DISTANCE_FROM_SIDE);
		}

		dashedLineGeometry.vertices.push(startingPoint, endingPoint);

		let dashedLine = new Line(dashedLineGeometry, dashedLineMaterial);
		dashedLine.frustumCulled = false;
		dashedLine.computeLineDistances();
		dashedLine.geometry.computeBoundingSphere();

		return dashedLine;
	}
}

function getDistanceFromFront(position, dimensions) {
	if (position.z < 0) {
		return dimensions.width / 2 + Math.abs(position.z);
	} else {
		return dimensions.width / 2 - position.z;
	}
}

function getTopPointForType6(type, dimensions, position, side) {
	let topPoint;
	const distanceFromFront = getDistanceFromFront(position, dimensions);

	if (side === LEFT || side === RIGHT) {
		// The chiseled line should go all the way to the top of the object when there is no sloping side
		// When there is, the top point of the line should be calculated
		if (position.z < -(dimensions.width / 2) + dimensions.barWidth) {
			// No sloping side (like at the back of the object, where the bar is added)
			topPoint = dimensions.height / 2 + dimensions.extrusion;
		} else {
			// On a sloping side
			topPoint = VectorHelper.getPositionOnSurfaceByDistanceFromFront(dimensions, distanceFromFront);
		}
	} else {
		if (side === FRONT) {
			// The front side can be lower than the actual height of the object, so the top point should be
			// as high as the front side is.
			topPoint = -(dimensions.height / 2) + dimensions.frontHeight;
		} else {
			// The back side cannot be lower than the height, so for this side the
			// top point is fixed
			topPoint = dimensions.height / 2;
		}
	}

	return topPoint;
}

function getTopPointForType8(type, dimensions, position, side) {
	let topPoint;
	const distanceFromFront = getDistanceFromFront(position, dimensions);

	if (side === FRONT) {
		// When the objects has no protrusion there should be lines on the full front side of the cushions
		// If it has protrusion, then the sloping side should not have 'chiseled lines'
		if (dimensions.protrusion === 0) {
			// The two conditions in this if-statement checks if the current position is 'inside' the front surface of a cushion
			if (
				position.x < -(dimensions.length / 2) + dimensions.cushionWidth ||
				position.x > dimensions.length / 2 - dimensions.cushionWidth
			) {
				// When the position is in the surface of the cushion, the line should be as high as the
				// full height of the object, so we add the extrusion to the height
				topPoint = dimensions.height / 2;
			} else {
				// When it is not, the line should only be as high as the frontHeight
				topPoint = -(dimensions.height / 2) + dimensions.frontHeight;
			}
		} else {
			topPoint = -(dimensions.height / 2) + dimensions.frontHeight;
		}
	} else if (side === BACK) {
		// The back side cannot be lower than the height, so for this side the
		// top point is fixed
		topPoint = dimensions.height / 2;
	} else if (side === LEFT || side === RIGHT) {
		if (dimensions.protrusion >= 1 && distanceFromFront < dimensions.protrusion) {
			topPoint = VectorHelper.getPositionOnSurfaceOfCushionSlopingSide(dimensions, distanceFromFront);
		} else {
			topPoint = dimensions.height / 2;
		}
	}

	return topPoint;
}
