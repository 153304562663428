import { SILLS, SILLS_STOCK } from '../../constants/Presets';
import { TYPE_1, TYPE_2 } from '../../constants/ObjectTypes';
import { STANDARD_HEIGHTS } from '../../constants/StandardMeasurements';

export class ConfigurationOptions {
	constructor(optionsData) {
		this.decor = optionsData.decor;
		this.preset = optionsData.preset;
		this.subPreset = optionsData.sub_preset ?? optionsData.subPreset;
		this.material = optionsData.material;
		this.materialColor = optionsData.material_color ?? optionsData.materialColor;
		this.quality = optionsData.quality;
		this.type = optionsData.type;
		this.height = optionsData.height;
		this.bevelHeight = optionsData.bevel_height ?? optionsData.bevelHeight;
		this.bevelWidth = optionsData.bevel_width ?? optionsData.bevelWidth;

		if (typeof this.height === 'string') this.height = parseFloat(this.height);
	}

	isConfiguredForStock() {
		return this.preset === SILLS && this.subPreset === SILLS_STOCK;
	}
}
