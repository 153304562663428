import * as React from 'react';
import PropTypes from 'prop-types';
import { ActionsDropdown } from '../../dropdowns/ActionsDropdown';
import { ActionsDropdownItem } from '../../dropdowns/items/ActionsDropdownItem';
import { DANGER } from '../../../constants/Variants';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';

export const CatalogList = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'pages.admin.manufacturerDetail.';

	return (
		<div className="list">
			{props.catalogs.map(catalog => {
				return (
					<div className="list__item" key={catalog.id}>
						<div className="list__item__content">{catalog.name + ' - ' + catalog.fileName}</div>

						<ActionsDropdown>
							<ActionsDropdownItem content={t(prefix + 'dropdownEdit')} onClick={() => props.onEdit(catalog)} />
							<ActionsDropdownItem
								content={t(prefix + 'dropdownDelete')}
								variant={DANGER}
								onClick={() => props.onDelete(catalog)}
							/>
						</ActionsDropdown>
					</div>
				);
			})}
		</div>
	);
};

CatalogList.propTypes = {
	catalogs: PropTypes.array.isRequired,
	onEdit: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
};
