import { LANGUAGE_KEY } from '../../constants/Values';
import i18n from 'i18next';
import { ENGLISH_SHORT } from '../../constants/Languages';

class LocalStorageHelper {
	static setLanguage(language) {
		language = language.toLowerCase();

		i18n.changeLanguage(language).then(() => {
			localStorage.setItem(LANGUAGE_KEY, language);
		});
	}

	static getLanguage(lowerCase) {
		let language = localStorage.getItem(LANGUAGE_KEY);

		if (!language) {
			language = ENGLISH_SHORT;

			this.setLanguage(language);
		}

		if (lowerCase) return language;

		return language.toUpperCase();
	}
}

export { LocalStorageHelper };
