import * as React from 'react';
import PropTypes from 'prop-types';
import { SectionTitle } from '../../headers/SectionTitle';
import { ConfigOption } from '../../forms/selects/ConfigOption';
import { EXTERIOR, INTERIOR } from '../../../constants/Values';
import iconOutdoor from '../../../assets/img/icon-out.svg';
import iconIndoor from '../../../assets/img/icon-in.svg';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';

export const DecorSelect = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'modals.configurationModal.';

	return (
		<div>
			<SectionTitle content={t(prefix + 'decorTitle')} />
			<ConfigOption
				dataCy="decorSelect-exterior"
				name="decor"
				content={t(prefix + 'exterior')}
				selected={props.selectedDecor === EXTERIOR}
				onClick={() => props.setDecor(EXTERIOR)}
				image={iconOutdoor}
			/>

			<ConfigOption
				dataCy="decorSelect-interior"
				name="decor"
				content={t(prefix + 'interior')}
				selected={props.selectedDecor === INTERIOR}
				onClick={() => props.setDecor(INTERIOR)}
				image={iconIndoor}
			/>
		</div>
	);
};

DecorSelect.propTypes = {
	selectedDecor: PropTypes.string,
	setDecor: PropTypes.func.isRequired,
};
