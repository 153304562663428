import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import Catalog from '../../classes/models/Catalog';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';

export const CatalogModal = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'modals.catalogModal.';

	const [catalogName, setCatalogName] = useState('');
	const [fileToUpload, setFileToUpload] = useState();
	const [formIsValid, setFormIsValid] = useState(false);

	useEffect(() => {
		if (props.isActive && props.catalog) {
			setCatalogName(props.catalog.name);
		} else {
			setCatalogName('');
		}
	}, [props.isActive]);

	useEffect(() => {
		if (catalogName && catalogName.length > 0 && fileToUpload && !formIsValid) {
			setFormIsValid(true);
		}

		if ((!catalogName || catalogName.length <= 0 || !fileToUpload) && formIsValid) {
			setFormIsValid(false);
		}
	}, [catalogName, fileToUpload]);

	const onCloseHandler = submit => {
		if (submit && formIsValid) {
			props.onClose(catalogName, fileToUpload);
		}

		props.onClose();
	};

	return (
		<Modal className="modal--prompt" show={props.isActive} onHide={() => onCloseHandler()} animation={true}>
			<Modal.Header>
				<Modal.Title>{t(prefix + 'title')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="form-group">
					<label>{t(prefix + 'nameLabel')}</label>
					<input
						name="catalogName"
						type="text"
						value={catalogName}
						placeholder={t(prefix + 'namePlaceholder')}
						onChange={event => setCatalogName(event.target.value)}
					/>
				</div>

				<div className="form-group">
					<label>
						{t(prefix + 'fileLabel')} {props.catalog?.fileName}
					</label>
					<input name="fileUploader" type="file" onChange={event => setFileToUpload(event.target.files[0])} />
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button
					type="button"
					className="button button--primary"
					disabled={!formIsValid}
					onClick={submit => onCloseHandler(true)}
				>
					{props.catalog ? t(prefix + 'updateButton') : t(prefix + 'createButton')}
				</button>

				<button type="button" className="button button--outline" onClick={() => onCloseHandler(false)}>
					{t(prefix + 'cancelButton')}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

CatalogModal.propTypes = {
	catalog: PropTypes.instanceOf(Catalog),
	isActive: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};
