export const FRONT_RIGHT = 'FRONT_RIGHT';
export const FRONT_LEFT = 'FRONT_LEFT';
export const BACK_LEFT = 'BACK_LEFT';
export const BACK_RIGHT = 'BACK_RIGHT';

export const TOP_LEFT = 'TOP_LEFT';
export const TOP_RIGHT = 'TOP_RIGHT';
export const BOTTOM_LEFT = 'BOTTOM_LEFT';
export const BOTTOM_RIGHT = 'BOTTOM_RIGHT';

export const CORNERS = [FRONT_RIGHT, FRONT_LEFT, BACK_LEFT, BACK_RIGHT];

export const CORNERS_UPRIGHT = [TOP_LEFT, TOP_RIGHT, BOTTOM_LEFT, BOTTOM_RIGHT];
