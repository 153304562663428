import { getXPosForSide, getYPosForSide, getZPosForSide } from '../SurfaceMeasurementLineHelper';
import { Vector3 } from 'three';
import MeasurementPoints from '../../../models/MeasurementPoints';
import { VectorHelper } from '../../VectorHelper';
import { BACK, FRONT, LEFT, RIGHT } from '../../../../constants/ObjectSides';

export function getPointsForCornerCutout(pieceType, pieceDimensions, cornerCutout) {
	let horizontalPoints;
	let verticalPoints;

	const linePosition = {
		xPos: getXPosForSide(pieceDimensions, cornerCutout.additionalDimension.type),
		yPos: getYPosForSide(pieceDimensions) + 0.05,
		zPos: getZPosForSide(pieceDimensions, cornerCutout.additionalDimension.type),
	};

	cornerCutout.position = VectorHelper.getCornerCutoutPosition(pieceType, pieceDimensions, cornerCutout);

	horizontalPoints = new MeasurementPoints(
		getHorizontalPointA(cornerCutout, linePosition),
		getHorizontalPointB(cornerCutout, linePosition),
	);

	verticalPoints = new MeasurementPoints(
		getVerticalPointA(cornerCutout, linePosition),
		getVerticalPointB(cornerCutout, linePosition),
	);

	// When the cutout is on the right side of the piece the line should be on the left side
	// Otherwise the line would be rendered inside the cutout
	if (cornerCutout.side === RIGHT) {
		verticalPoints.lineSide = LEFT;
		if (cornerCutout.additionalDimension.type === FRONT) {
			verticalPoints.textSide = BACK;
		} else {
			verticalPoints.textSide = FRONT;
		}
	}

	return {
		horizontal: horizontalPoints,
		vertical: verticalPoints,
	};
}

function getHorizontalPointA(cornerCutout, { xPos, yPos, zPos }) {
	if (cornerCutout.additionalDimension.type === FRONT) {
		xPos = cornerCutout.position.x - cornerCutout.dimensions.length / 2;
	} else {
		// BACK
		xPos = cornerCutout.position.x + cornerCutout.dimensions.length / 2;
	}

	return new Vector3(xPos, yPos, zPos);
}

function getHorizontalPointB(cornerCutout, { xPos, yPos, zPos }) {
	if (cornerCutout.additionalDimension.type === FRONT) {
		xPos = cornerCutout.position.x + cornerCutout.dimensions.length / 2;
	} else {
		// BACK
		xPos = cornerCutout.position.x - cornerCutout.dimensions.length / 2;
	}

	return new Vector3(xPos, yPos, zPos);
}

function getVerticalPointA(cornerCutout, { xPos, yPos, zPos }) {
	if (cornerCutout.side === LEFT) {
		// Add points/line on right side of the cut out
		xPos = cornerCutout.position.x + cornerCutout.dimensions.length / 2;
	} else {
		// Add points/line on left side of the cut out
		xPos = cornerCutout.position.x - cornerCutout.dimensions.length / 2;
	}

	return new Vector3(xPos, yPos, zPos);
}

function getVerticalPointB(cornerCutout, { xPos, yPos, zPos }) {
	if (cornerCutout.side === LEFT) {
		// Add points/line on right side of the cut out
		xPos = cornerCutout.position.x + cornerCutout.dimensions.length / 2;
	} else {
		// Add points/line on left side of the cut out
		xPos = cornerCutout.position.x - cornerCutout.dimensions.length / 2;
	}

	if (cornerCutout.additionalDimension.type === FRONT) {
		zPos = cornerCutout.position.z - cornerCutout.dimensions.width / 2;
	} else {
		// BACK
		zPos = cornerCutout.position.z + cornerCutout.dimensions.width / 2;
	}

	return new Vector3(xPos, yPos, zPos);
}
