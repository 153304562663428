import MeasurementPoints from '../../../models/MeasurementPoints';
import { BACK, LEFT } from '../../../../constants/ObjectSides';
import { Vector3 } from 'three';

export function getPointsForGluedCushionsOnSide(pieceDimensions, gluedCushion) {
	// Points for the cushion itself
	const cushionPoints = new MeasurementPoints(
		getCushionPointA(pieceDimensions, gluedCushion),
		getCushionPointB(pieceDimensions, gluedCushion),
	);

	// Points for displaying the height of the piece
	const piecePoints = new MeasurementPoints(
		getPiecePointA(pieceDimensions, gluedCushion),
		getPiecePointB(pieceDimensions, gluedCushion),
	);

	if (gluedCushion.side === LEFT) {
		cushionPoints.textSide = BACK;
		piecePoints.textSide = BACK;
	}

	return {
		horizontal: null,
		vertical: [cushionPoints, piecePoints],
	};
}

const getCushionPointA = (pieceDimensions, gluedCushion) => {
	const xPos = getXPos(pieceDimensions, gluedCushion);
	const yPos = gluedCushion.position.y - gluedCushion.dimensions.height / 2;
	const zPos = gluedCushion.position.z;

	return new Vector3(xPos, yPos, zPos);
};

const getCushionPointB = (pieceDimensions, gluedCushion) => {
	const xPos = getXPos(pieceDimensions, gluedCushion);
	const yPos = gluedCushion.position.y + gluedCushion.dimensions.height / 2;
	const zPos = gluedCushion.position.z;

	return new Vector3(xPos, yPos, zPos);
};

const getPiecePointA = (pieceDimensions, gluedCushion) => {
	const xPos = getXPos(pieceDimensions, gluedCushion);
	const yPos = pieceDimensions.height / -2;
	const zPos = gluedCushion.position.z;

	return new Vector3(xPos, yPos, zPos);
};

const getPiecePointB = (pieceDimensions, gluedCushion) => {
	const xPos = getXPos(pieceDimensions, gluedCushion);
	const yPos = pieceDimensions.height / 2;
	const zPos = gluedCushion.position.z;

	return new Vector3(xPos, yPos, zPos);
};

const getXPos = (pieceDimensions, gluedCushion) => {
	return gluedCushion.side === LEFT ? pieceDimensions.length / -2 : pieceDimensions.length / 2;
};
