import {
	BoxGeometry,
	CylinderGeometry,
	ExtrudeGeometry,
	MathUtils,
	Mesh,
	MeshBasicMaterial,
	MeshStandardMaterial,
	Shape,
	Vector3,
} from 'three';
import { BAR_EXTRUSION, MAX_HEIGHT } from '../../constants/Values';
import { getHeightCoupeWidth, OperationHelper } from '../helpers/OperationHelper';
import { VectorHelper } from '../helpers/VectorHelper';
import { LineCreator } from '../helpers/LineCreator';
import * as Angles from '../../constants/Angles';
import * as GrooveTypes from '../../constants/GrooveTypes';
import CSG from '../../threeJsPlugins/csg';
import _ from 'lodash';
import * as ProfileTypes from '../../constants/ProfileOperationTypes';
import { DEBASE_ROUGH_TYPES, GLUED_CUSHION, RECTANGULAR_CUT_OUT_TYPES } from '../../constants/OperationTypes';
import * as ThreeConstants from '../../constants/ThreeConstants';
import { TYPE_3, TYPE_4, TYPE_7, TYPE_8 } from '../../constants/ObjectTypes';
import { BACK, BOTTOM, FRONT, LEFT, RIGHT, TOP } from '../../constants/ObjectSides';
import { PRESETS_WITH_SHORTENED_WATERLISTS, UPRIGHT_PRESETS } from '../../constants/Presets';

class OperationMeshCreator {
	createWaterList(piece, side, { type, preset }) {
		let length = 0;
		const width = 1;
		const height = 0.8;

		switch (side) {
			case LEFT:
			case RIGHT:
				length = piece.dimensions.width;

				// These waterlists should not run continuously through the whole stone,
				// they should have a "margin"
				length -= 2;
				break;
			default:
				// Front and Back
				length = piece.dimensions.length;

				if ([TYPE_7, TYPE_8].includes(type) || PRESETS_WITH_SHORTENED_WATERLISTS.includes(preset)) {
					// 3 cm distance from left and right
					length -= 6;
				}
				break;
		}

		let mesh = new Mesh(
			new BoxGeometry(length, height, width),
			new MeshBasicMaterial({ color: ThreeConstants.DEFAULT_OBJECT_COLOR }),
		);

		if ([LEFT, RIGHT].includes(side)) mesh.rotateY(MathUtils.degToRad(90));

		mesh.position.add(VectorHelper.getWaterListVector(piece.dimensions, side));
		mesh.updateMatrix();

		return mesh;
	}

	generateCoupe(operation) {
		// Return null when the position of the operation is null
		// this probably means that the wrong side is selected and no position is set because of that
		if (operation.position == null || VectorHelper.compareVectors(new Vector3(), operation.position)) {
			return;
		}

		let dimensions = operation.dimensions;
		let position = operation.position;
		let angle = operation.angle;

		// Dimensions length width height, distance
		// Set the dimensions of the shape
		let shape = new Shape();
		shape.moveTo(0, 0);
		shape.lineTo(0, parseFloat(dimensions.width));
		shape.lineTo(parseFloat(dimensions.length), parseFloat(dimensions.width) + 0.1);
		shape.lineTo(0, 0);

		// Add some settings for the shape. I added 0.1, so it would extrude from the main object a bit.
		// That way the coupe will cut through the whole object
		const extrudeSettings = {
			steps: 2,
			depth: MAX_HEIGHT * 2,
			bevelEnabled: false,
		};

		let triangle = new Mesh(new ExtrudeGeometry(shape, extrudeSettings));

		// Rotate the new triangle so that it's horizontally flat. Otherwise, the triangle would stand upright
		triangle.position.add(position);

		if (angle === Angles.INNER && operation.side === LEFT) {
			triangle.rotateX(1.57079632679);
			// Add a correction to the position, because of the rotation the coupe will have a position that lies next to the object in stead of inside of it.
			// triangle.position.add(new Vector3(0, MAX_HEIGHT * 2, 0.1));
		} else if (angle === Angles.OUTER && operation.side === LEFT) {
			triangle.rotateX(-1.57079632679);
			triangle.position.add(new Vector3(0, -MAX_HEIGHT * 2, dimensions.width));
		} else if (angle === Angles.OUTER && operation.side === RIGHT) {
			triangle.rotateX(1.57079632679);
			triangle.rotateZ(3.14159265359);
		} else if (angle === Angles.INNER && operation.side === RIGHT) {
			triangle.rotateX(-1.57079632679);
			triangle.rotateZ(3.14159265359);
			triangle.position.add(new Vector3(0, -MAX_HEIGHT * 2 - 0.1, -dimensions.width + 0.1));
		}

		triangle.updateMatrix();

		return triangle;
	}

	createNotchMeshAndLines(objectDimensions, notchOperation) {
		let notchMesh = null;
		let notchLinesMesh = null;

		const notchLength = parseFloat(notchOperation.dimensions.length);
		const notchDepth = parseFloat(notchOperation.dimensions.width);
		const notchDistance = parseFloat(notchOperation.additionalDimension.value);

		if (notchLength === 0 || notchDepth === 0) return;
		if (notchDistance < 0) return;

		let shouldDrawNotch = false;
		let shouldRotateNotch = false;

		if (notchOperation.side === LEFT || notchOperation.side === RIGHT) {
			shouldRotateNotch = true;
			if (objectDimensions.width >= notchLength + notchDistance) shouldDrawNotch = true;
		} else {
			if (objectDimensions.length >= notchLength + notchDistance && notchLength > 0 && notchDepth > 0)
				shouldDrawNotch = true;
		}

		if (shouldDrawNotch) {
			const height = objectDimensions.extrusion
				? objectDimensions.height + objectDimensions.extrusion * 2
				: objectDimensions.height;

			// The notch should be rotated when it is on the left or right side.
			// I invert the length and depth because that is simpler than rotating the mesh and the lines,
			// which should otherwise both be parented in a parent object
			if (shouldRotateNotch) {
				notchMesh = new Mesh(new BoxGeometry(notchDepth, height, notchLength));

				notchLinesMesh = new Mesh(new BoxGeometry(notchDepth, objectDimensions.height, notchLength));
			} else {
				notchMesh = new Mesh(new BoxGeometry(notchLength, height, notchDepth));

				notchLinesMesh = new Mesh(new BoxGeometry(notchLength, objectDimensions.height, notchDepth));
			}

			notchMesh.position.add(notchOperation.position);
			notchMesh.updateMatrix();

			// The showMesh is a mesh created for drawing lines.
			// The actual mesh of the notch should be higher for cutting through the bar of a type 3 or 5,
			// but when this mesh is used for drawing the lines there will be lines that are higher than the
			// actual object, which does not look good
			if (notchMesh.position.y !== 0) {
				notchLinesMesh.position.add(new Vector3().subVectors(notchMesh.position, new Vector3(0, BAR_EXTRUSION / 2, 0)));
			} else {
				notchLinesMesh.position.add(notchMesh.position);
			}
			notchLinesMesh.updateMatrix();
		}

		return { mesh: notchMesh, linesMesh: notchLinesMesh };
	}

	createChiseledSides(finishedSideOperation, piece, type) {
		const dimensions = piece.dimensions;
		const space = 1;
		const coupes = OperationHelper.getCoupesOfPiece(piece);
		const side = finishedSideOperation.side;
		let lineCollection = [];

		//Don't draw any lines when there is a coupe on the side that should be chiseled
		for (let i = 0; i < coupes.length; i++) {
			if (coupes[i].side === side) return [];
		}

		const points = VectorHelper.getStartAndEndPointForChiseledSide(finishedSideOperation, dimensions, coupes, type);
		const totalLength =
			side === FRONT || side === BACK
				? Math.abs(points.startingPoint.x - points.endingPoint.x)
				: Math.abs(points.startingPoint.z - points.endingPoint.z);

		const lineCount = totalLength / space;

		for (let j = 0; j < lineCount; j++) {
			let line;

			line = LineCreator.createChiseledSideLineByType(type, side, piece, points.startingPoint);

			lineCollection.push(line);
			if (side === FRONT || side === BACK) {
				points.startingPoint.add(new Vector3(space, 0, 0));
			} else if (side === LEFT || side === RIGHT) {
				points.startingPoint.add(new Vector3(0, 0, space));
			}
		}

		return lineCollection;
	}

	createCornerCutoutMesh(pieceType, operation, pieceDimensions) {
		let height;

		if ([TYPE_3, TYPE_4].includes(pieceType)) {
			height = pieceDimensions.height + pieceDimensions.extrusion;
		} else {
			height = pieceDimensions.height;
		}

		let mesh = new Mesh(
			new BoxGeometry(operation.dimensions.length, height, operation.dimensions.width),
			new MeshStandardMaterial(),
		);

		mesh.position.add(VectorHelper.getCornerCutoutPosition(pieceType, pieceDimensions, operation));
		mesh.updateMatrix();

		return mesh;
	}

	createGrooves(operation, piece) {
		let length;
		let mesh;
		let cushionWidth = 0;
		const pieceDimensions = piece.dimensions;

		// Get the width of the cushions, or glued cushions if they are present on the piece
		if (pieceDimensions.cushionWidth) {
			cushionWidth = pieceDimensions.cushionWidth;
		} else {
			const gluedCushions = piece.getOperationsByType(GLUED_CUSHION);

			if (gluedCushions.length) {
				gluedCushions.forEach(gluedCushion => {
					if (gluedCushion.dimensions.length > cushionWidth) cushionWidth = gluedCushion.dimensions.length;
				});
			} else {
				cushionWidth = 0;
			}
		}

		switch (operation.additionalDimension.type) {
			case GrooveTypes.STOPPING_RIGHT:
				length = pieceDimensions.length - 10;
				break;
			case GrooveTypes.STOPPING_ROUND:
				length = pieceDimensions.length - 11;
				break;
			case GrooveTypes.CONTINUOUS:
			case GrooveTypes.CHISELED:
			case GrooveTypes.LUPATO:
			default:
				length = pieceDimensions.length;
				break;
		}

		// Make sure that the grooves are not too wide, otherwise they would be under the cushions if there are any
		length -= cushionWidth * 2;

		switch (operation.additionalDimension.type) {
			case GrooveTypes.STOPPING_ROUND:
				mesh = this.__createRoundStoppingGrooves(length, pieceDimensions);
				break;
			case GrooveTypes.CHISELED:
				mesh = this.__createChiseledGrooves(length);
				break;
			case GrooveTypes.LUPATO:
				mesh = this.__createLupatoGrooves(length);
				break;
			case GrooveTypes.CONTINUOUS:
			case GrooveTypes.STOPPING_RIGHT:
			default:
				mesh = this.__createRightGrooves(length);
				break;
		}

		mesh.position.add(VectorHelper.getGroovesPosition(pieceDimensions));
		mesh.updateMatrix();

		return mesh;
	}

	createRoundedCorner(operation, pieceDimensions) {
		const radius = operation.dimensions.length;
		let longBoxPosition = this.__getLongBoxPosition(operation.additionalDimension.type, operation.dimensions.length);
		let squareBoxPosition = this.__getSquareBoxPosition(
			operation.side,
			operation.additionalDimension.type,
			operation.dimensions.length,
		);

		let cutoutBoxMesh = new Mesh(new BoxGeometry(radius * 2, pieceDimensions.height, radius * 2));

		let longBoxMesh = new Mesh(new BoxGeometry(radius * 2, pieceDimensions.height, radius));

		let squareBoxMesh = new Mesh(new BoxGeometry(radius, pieceDimensions.height, radius));

		let cylinderMesh = new Mesh(new CylinderGeometry(radius, radius, pieceDimensions.height, 30));

		longBoxMesh.position.add(longBoxPosition);
		longBoxMesh.updateMatrix();

		squareBoxMesh.position.add(squareBoxPosition);
		squareBoxMesh.updateMatrix();

		let mainBsp = CSG.fromMesh(cutoutBoxMesh);
		let cylinderBsp = CSG.fromMesh(cylinderMesh);
		const longBoxBsp = CSG.fromMesh(longBoxMesh);
		const squareBoxBsp = CSG.fromMesh(squareBoxMesh);

		cylinderBsp = cylinderBsp.union(longBoxBsp);
		cylinderBsp = cylinderBsp.union(squareBoxBsp);

		mainBsp = mainBsp.subtract(cylinderBsp);

		let mesh = CSG.toMesh(mainBsp, cutoutBoxMesh.matrix);
		mesh.position.add(VectorHelper.getRoundedCornerPosition(pieceDimensions, operation));
		mesh.updateMatrix();

		return mesh;
	}

	createProfile(operation, piece, pieceType) {
		let profile;

		switch (operation.additionalDimension.type) {
			case ProfileTypes.TYPE_1:
				profile = this.createProfileType1(operation, piece);
				break;
			case ProfileTypes.TYPE_3:
				profile = this.createProfileType3(operation, piece, operation.data);
				break;
			case ProfileTypes.TYPE_4:
			case ProfileTypes.TYPE_5:
				profile = this.createProfileType4And5(operation, piece);
				break;
			default:
				break;
		}

		return profile;
	}

	createProfileType1(operation, piece) {
		let profile3A = this.createProfileType3(operation, piece, TOP);
		let profile3B = this.createProfileType3(operation, piece, BOTTOM);

		let bspA = CSG.fromMesh(profile3A);
		let bspB = CSG.fromMesh(profile3B);

		bspA = bspA.union(bspB);

		let mainMesh = CSG.toMesh(bspA, profile3A.matrix);
		mainMesh.updateMatrix();

		return mainMesh;
	}

	createProfileType3(operation, piece, surface = TOP) {
		const radius = 2;
		const length = this.__getProfileLength(piece, piece.type, operation.side);

		let cylinderMesh = new Mesh(new CylinderGeometry(radius, radius, length, 20));

		let squareMesh = new Mesh(new BoxGeometry(length, radius, radius));

		let longBoxMesh = new Mesh(new BoxGeometry(length, radius, radius * 2));

		cylinderMesh.rotateZ(MathUtils.degToRad(90));
		cylinderMesh.updateMatrix();

		squareMesh.position.add(new Vector3(0, radius / 2, radius / 2));
		squareMesh.updateMatrix();

		longBoxMesh.position.add(new Vector3(0, -(radius / 2), 0));
		longBoxMesh.updateMatrix();

		let cylinderBsp = CSG.fromMesh(cylinderMesh);
		let squareBsp = CSG.fromMesh(squareMesh);
		let longBoxBsp = CSG.fromMesh(longBoxMesh);

		cylinderBsp = cylinderBsp.union(squareBsp);
		cylinderBsp = cylinderBsp.union(longBoxBsp);

		let combinedMesh = CSG.toMesh(cylinderBsp, cylinderMesh.matrix);
		let remainingMesh = new Mesh(new BoxGeometry(length, radius * 2, radius * 2));

		let remainingBsp = CSG.fromMesh(remainingMesh);
		remainingBsp = remainingBsp.subtract(CSG.fromMesh(combinedMesh));

		let mainMesh = CSG.toMesh(remainingBsp, remainingMesh.matrix);

		if (surface === TOP) {
			mainMesh.rotateX(MathUtils.degToRad(90));
			mainMesh.rotateZ(this.__getProfileType1And3Rotation(operation.side));
			mainMesh.position.add(VectorHelper.getProfile3Position(operation.side, operation.dimensions.length, piece));
		} else {
			// BOTTOM
			mainMesh.rotateX(MathUtils.degToRad(180));
			mainMesh.rotateY(this.__getProfileType1And3Rotation(operation.side));
			mainMesh.position.sub(mainMesh.position);
			mainMesh.position.add(
				VectorHelper.getRoundProfileBottomPosition(operation.side, operation.dimensions.length, piece.dimensions),
			);
		}

		mainMesh.updateMatrix();

		return mainMesh;
	}

	createProfileType4And5(operation, piece) {
		let shapeDepth;

		if ([LEFT, RIGHT].includes(operation.side)) {
			shapeDepth = operation.dimensions.length ? operation.dimensions.length : piece.dimensions.width;
		} else {
			shapeDepth = this.__getProfileLength(piece, piece.type);
		}

		let shape = new Shape();
		if (operation.data === TOP) {
			shape.moveTo(0, 0);
			shape.lineTo(0, operation.dimensions.height);
			shape.lineTo(operation.dimensions.width, operation.dimensions.height);
			shape.lineTo(0, 0);
		} else {
			// BOTTOM
			shape.moveTo(0, 0);
			shape.lineTo(0, -operation.dimensions.height);
			shape.lineTo(operation.dimensions.width, -operation.dimensions.height);
			shape.lineTo(0, 0);
		}

		const extrudeSettings = {
			depth: shapeDepth,
			steps: 2,
			bevelEnabled: false,
		};

		let profile = new Mesh(new ExtrudeGeometry(shape, extrudeSettings), new MeshStandardMaterial({ color: 0x0000ff }));

		const rotation = this.__getProfileType4Rotation(operation.side, operation.data);
		profile.rotateX(MathUtils.degToRad(rotation.x));
		profile.rotateY(MathUtils.degToRad(rotation.y));
		profile.rotateZ(MathUtils.degToRad(rotation.z));
		profile.position.add(VectorHelper.getProfile4Position(operation, piece));
		profile.updateMatrix();

		return profile;
	}

	createDrillHole(operation, height) {
		const radius = operation.dimensions.length / 20;
		let cylinderMesh = new Mesh(new CylinderGeometry(radius, radius, height, 30));

		cylinderMesh.position.add(operation.position);
		cylinderMesh.updateMatrix();

		return cylinderMesh;
	}

	createCoupeOverLength(operation, pieceDimensions) {
		let shape = new Shape();
		let xRotation;
		let yRotation;

		shape.moveTo(0, 0);
		shape.lineTo(pieceDimensions.length / 2, 0);
		shape.lineTo(pieceDimensions.length / 2, pieceDimensions.width - operation.dimensions.width);
		shape.lineTo(-(pieceDimensions.length / 2), 0);
		shape.lineTo(0, 0);

		const extrudeSettings = {
			depth: pieceDimensions.height,
			steps: 2,
			bevelEnabled: false,
		};

		let coupeOverLength = new Mesh(
			new ExtrudeGeometry(shape, extrudeSettings),
			new MeshStandardMaterial({ color: 0x0000ff }),
		);

		if (operation.side === FRONT) {
			xRotation = -90;
		} else {
			xRotation = 90;
		}

		if (operation.additionalDimension.type === LEFT) {
			yRotation = -180;
		} else {
			yRotation = 0;
		}

		coupeOverLength.rotateX(MathUtils.degToRad(xRotation));
		coupeOverLength.rotateY(MathUtils.degToRad(yRotation));

		coupeOverLength.position.add(VectorHelper.getCoupeOverLengthPosition(pieceDimensions, operation));

		coupeOverLength.updateMatrix();

		return coupeOverLength;
	}

	createGluedCushion(pieceType, operation, piece) {
		let mainMesh = new Mesh(
			new BoxGeometry(operation.dimensions.length, operation.dimensions.height, operation.dimensions.width),
		);

		mainMesh.position.add(VectorHelper.getVectorForGluedCushion(piece.dimensions, operation, pieceType));
		mainMesh.updateMatrix();

		return mainMesh;
	}

	createHeightCoupe(operation, piece, preset) {
		const { length, width, height } = piece.dimensions;
		const rotation = this.__getRotationForHeightCoupe(operation, preset);

		const depth = [LEFT, RIGHT].includes(operation.side) ? width : length;
		const coupeWidth = getHeightCoupeWidth(operation, piece.dimensions) * piece.dimensionRatio.length;

		let shape = new Shape();
		shape.moveTo(0, 0);
		shape.lineTo(coupeWidth, 0);
		shape.lineTo(0, -height);
		shape.lineTo(0, 0);

		const extrudeSettings = {
			depth: depth,
			bevelEnabled: false,
		};

		let triangle = new Mesh(new ExtrudeGeometry(shape, extrudeSettings));
		triangle.rotateX(MathUtils.degToRad(rotation.x));
		triangle.rotateY(MathUtils.degToRad(rotation.y));
		triangle.rotateZ(MathUtils.degToRad(rotation.z));
		triangle.position.add(VectorHelper.getHeightCoupePosition(piece.dimensions, operation, preset));
		triangle.updateMatrix();

		return triangle;
	}

	createDebasingRough(operation, pieceDimensions) {
		let triangle;

		if (operation.additionalDimension.type === DEBASE_ROUGH_TYPES.FRONT_TO_BACK) {
			triangle = this.__createFrontToBackDebasing(pieceDimensions, operation);
		} else if (operation.additionalDimension.type === DEBASE_ROUGH_TYPES.BACK_TO_FRONT) {
			triangle = this.__createBackToFrontDebasing(pieceDimensions, operation);
		} else {
			triangle = this.__createMiddleToSideDebasing(pieceDimensions, operation);
		}

		triangle.position.add(operation.position);
		triangle.updateMatrix();

		return triangle;
	}

	createRectangularCutOut(operation, { height }) {
		let leadThrough;

		if (operation.additionalDimension.type === RECTANGULAR_CUT_OUT_TYPES.ROUND) {
			leadThrough = this.__createRoundCutOut(operation, height);
		} else {
			leadThrough = this.__createRectangularCutOut(operation, height);
		}

		return leadThrough;
	}

	createAnchorHole(operation) {
		const radius = operation.dimensions.length / 2;
		let anchorHoleMesh = new Mesh(
			new CylinderGeometry(radius, radius, operation.dimensions.width, 20),
			new MeshStandardMaterial({ color: 'red' }),
		);

		anchorHoleMesh.rotateZ(MathUtils.degToRad(90));
		anchorHoleMesh.position.add(operation.position);
		anchorHoleMesh.updateMatrix();

		return anchorHoleMesh;
	}

	createRabat(rabat, piece) {
		// Invert the length and width if the rabat is on the left or right, this way we don't have to
		// rotate the rabat
		const length = [FRONT, BACK].includes(rabat.side) ? piece.dimensions.length : rabat.dimensions.width;
		const width = [FRONT, BACK].includes(rabat.side) ? rabat.dimensions.width : piece.dimensions.length;

		const geometry = new BoxGeometry(length, rabat.dimensions.height, width);
		const mesh = new Mesh(geometry, new MeshStandardMaterial());

		mesh.position.add(VectorHelper.getRabatPosition(piece.dimensions, rabat));
		mesh.updateMatrix();

		return mesh;
	}

	createNotchOverLength(notchOverLength, piece) {
		// Invert the length and width if the notch is on the left or right, this way we don't have to
		// rotate the notch
		const length = [FRONT, BACK].includes(notchOverLength.side)
			? piece.dimensions.length
			: notchOverLength.dimensions.width;
		const width = [FRONT, BACK].includes(notchOverLength.side)
			? notchOverLength.dimensions.width
			: piece.dimensions.length;

		const geometry = new BoxGeometry(length, notchOverLength.dimensions.height, width);
		const mesh = new Mesh(geometry, new MeshStandardMaterial());

		mesh.position.add(VectorHelper.getNotchOverLengthPosition(piece.dimensions, notchOverLength));
		mesh.updateMatrix();

		return mesh;
	}

	__createRightGrooves(length) {
		return new Mesh(new BoxGeometry(length, 0.1, 2), new MeshStandardMaterial());
	}

	__createRoundStoppingGrooves(length) {
		const height = 0.1;

		const material = new MeshStandardMaterial();
		const cylinderGeometry = new CylinderGeometry(1, 1, height, 20);

		let mainMesh = new Mesh(new BoxGeometry(length, height, 2), material);

		let leftCylinder = new Mesh(cylinderGeometry, material);

		let rightCylinder = new Mesh(cylinderGeometry, material);

		leftCylinder.position.add(new Vector3(-(length / 2), 0, 0));
		leftCylinder.updateMatrix();

		rightCylinder.position.add(new Vector3(length / 2, 0, 0));
		rightCylinder.updateMatrix();

		let mainBsp = CSG.fromMesh(mainMesh);
		let leftCylinderBsp = CSG.fromMesh(leftCylinder);
		let rightCylinderBsp = CSG.fromMesh(rightCylinder);

		mainBsp = mainBsp.union(leftCylinderBsp);
		mainBsp = mainBsp.union(rightCylinderBsp);

		return CSG.toMesh(mainBsp, mainMesh.matrix);
	}

	__createChiseledGrooves(length) {
		let boxGeometry = new BoxGeometry(length, 0.1, 0.3);
		let material = new MeshStandardMaterial();

		let firstLine = new Mesh(boxGeometry, material);
		let secondLine = new Mesh(boxGeometry, material);
		let thirdLine = new Mesh(boxGeometry, material);

		firstLine.position.add(new Vector3(0, 0, -0.6));
		thirdLine.position.add(new Vector3(0, 0, 0.6));

		firstLine.updateMatrix();
		thirdLine.updateMatrix();

		let mainBsp = CSG.fromMesh(secondLine);
		let firstLineBsp = CSG.fromMesh(firstLine);
		let thirdLineBsp = CSG.fromMesh(thirdLine);

		mainBsp = mainBsp.union(firstLineBsp);
		mainBsp = mainBsp.union(thirdLineBsp);

		return CSG.toMesh(mainBsp, firstLine.matrix);
	}

	__createLupatoGrooves(length) {
		return this.__createRightGrooves(length);
	}

	__getLongBoxPosition(ySide, width) {
		let position;

		if (ySide === FRONT) {
			position = new Vector3(0, 0, -(width / 2));
		} else {
			position = new Vector3(0, 0, width / 2);
		}

		return position;
	}

	__getSquareBoxPosition(xSide, ySide, width) {
		let xPos;
		let zPos;

		if (xSide === LEFT) {
			if (ySide === FRONT) {
				xPos = width / 2;
				zPos = width / 2;
			} else {
				// BACK
				xPos = width / 2;
				zPos = -width / 2;
			}
		} else {
			// RIGHT
			if (ySide === FRONT) {
				xPos = -(width / 2);
				zPos = width / 2;
			} else {
				// BACK
				xPos = -(width / 2);
				zPos = -(width / 2);
			}
		}

		return new Vector3(xPos, 0, zPos);
	}

	__getProfileType1And3Rotation(side) {
		let rotation;

		switch (side) {
			case LEFT:
				rotation = 90;
				break;
			case BACK:
				rotation = 180;
				break;
			case RIGHT:
				rotation = 270;
				break;
			default:
				// Front
				rotation = 0;
				break;
		}

		return MathUtils.degToRad(rotation);
	}

	__getProfileType4Rotation(side, surface) {
		let rotation = new Vector3();

		switch (side) {
			case LEFT:
				break;
			case BACK:
				rotation.y = 270;
				break;
			case RIGHT:
				rotation.y = 180;
				break;
			default:
				rotation.y = 90;
				break;
		}

		if (surface === BOTTOM) {
			// rotation.z = 80;
		}

		return rotation;
	}

	__createFrontToBackDebasing(pieceDimensions, operation) {
		let shape = new Shape();

		shape.moveTo(0, 0);

		if (operation.side === TOP) {
			shape.lineTo(-pieceDimensions.width, 0);
			shape.lineTo(0, -operation.dimensions.height);
		} else {
			shape.lineTo(-pieceDimensions.width, 0);
			shape.lineTo(0, operation.dimensions.height);
		}

		shape.lineTo(0, 0);

		const extrudeSettings = {
			depth: pieceDimensions.length,
			bevelEnabled: false,
		};

		let triangle = new Mesh(new ExtrudeGeometry(shape, extrudeSettings));
		triangle.rotateY(MathUtils.degToRad(90));

		return triangle;
	}

	__createBackToFrontDebasing(pieceDimensions, operation) {
		let shape = new Shape();

		shape.moveTo(0, 0);

		if (operation.side === TOP) {
			shape.lineTo(0, -operation.dimensions.height);
			shape.lineTo(pieceDimensions.width, 0);
		} else {
			shape.lineTo(pieceDimensions.width, 0);
			shape.lineTo(0, operation.dimensions.height);
		}

		shape.lineTo(0, 0);

		const extrudeSettings = {
			depth: pieceDimensions.length,
			bevelEnabled: false,
		};

		let triangle = new Mesh(new ExtrudeGeometry(shape, extrudeSettings));
		triangle.rotateY(MathUtils.degToRad(90));

		return triangle;
	}

	__createMiddleToSideDebasing(pieceDimensions, operation) {
		let shape = new Shape();

		shape.moveTo(0, 0);
		shape.lineTo(pieceDimensions.width / 2, 0);
		shape.lineTo(0, -operation.dimensions.height);
		shape.lineTo(0, 0);

		const extrudeSettings = {
			depth: pieceDimensions.length,
			bevelEnabled: false,
		};

		let triangleA = new Mesh(new ExtrudeGeometry(shape, extrudeSettings));
		let triangleB = new Mesh(new ExtrudeGeometry(shape, extrudeSettings));
		triangleA.rotateY(MathUtils.degToRad(90));
		triangleA.position.add(
			new Vector3(pieceDimensions.length / -2, pieceDimensions.height / 2, pieceDimensions.width / 2),
		);
		triangleA.updateMatrix();

		triangleB.rotateY(MathUtils.degToRad(270));
		triangleB.position.add(
			new Vector3(pieceDimensions.length / 2, pieceDimensions.height / 2, pieceDimensions.width / -2),
		);
		triangleB.updateMatrix();

		let bspA = CSG.fromMesh(triangleA);
		let bspB = CSG.fromMesh(triangleB);
		let union = bspA.union(bspB);

		let mesh = CSG.toMesh(union, triangleA.matrix);

		return mesh;
	}

	__createRoundCutOut(operation, height) {
		const radius = operation.dimensions.length / 2;
		let cylinderMesh = new Mesh(new CylinderGeometry(radius, radius, height, 30));

		cylinderMesh.position.add(operation.position);
		cylinderMesh.updateMatrix();

		return cylinderMesh;
	}

	__createRectangularCutOut(operation, height) {
		let rectangleMesh = new Mesh(new BoxGeometry(operation.dimensions.length, height, operation.dimensions.width));

		rectangleMesh.position.add(operation.position);
		rectangleMesh.updateMatrix();

		return rectangleMesh;
	}

	__getRotationForHeightCoupe({ side, angle }, preset) {
		let xRotation = 0;
		let yRotation = 0;
		let zRotation = 0;

		switch (side) {
			case FRONT:
				if (angle === Angles.INNER) {
					yRotation = 270;
				} else {
					yRotation = 90;
				}
				break;
			case LEFT:
				yRotation = 180;
				break;
			case BACK:
				yRotation = 270;
				break;
			case RIGHT:
				yRotation = 0;
				break;
			default:
				break;
		}

		if (side === FRONT) {
			if (angle === Angles.INNER) {
				zRotation = 180;
			}
		} else if (side === LEFT) {
			if (UPRIGHT_PRESETS.includes(preset)) {
				// Invert the drawn angle
				if (angle === Angles.INNER) {
					yRotation = 0;
				} else {
					zRotation = 180;
				}
			} else {
				if (angle === Angles.INNER) {
					zRotation = 180;
				} else {
					yRotation = 0;
				}
			}
		} else if (side === BACK) {
			if (angle === Angles.INNER) {
				xRotation = 180;
				yRotation = 90;
			}
		} else if (side === RIGHT) {
			if (UPRIGHT_PRESETS.includes(preset)) {
				// Invert the drawn angle
				if (angle === Angles.INNER) {
					yRotation = 180;
				} else {
					zRotation = 180;
				}
			} else {
				if (angle === Angles.INNER) {
					zRotation = 180;
				} else {
					yRotation = 180;
				}
			}
		}

		return new Vector3(xRotation, yRotation, zRotation);
	}

	__getProfileLength(piece, pieceType, side) {
		let length = [LEFT, RIGHT].includes(side) ? piece.dimensions.width : piece.dimensions.length;

		if ([TYPE_7, TYPE_8].includes(pieceType)) {
			length -= piece.dimensions.cushionWidth * 2;
		} else {
			piece.getOperationsByType(GLUED_CUSHION).forEach(cushion => {
				length -= cushion.dimensions.length;
			});
		}

		return length;
	}
}

export default OperationMeshCreator;
