import { useEffect, useState } from 'react';
import { addAlertMessage } from '../../actions/GeneralActions';
import { DANGER } from '../../constants/Variants';
import CustomerService from '../services/CustomerService';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';

export const useCustomers = () => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'hooks.useCustomers.';
	const [customers, setCustomers] = useState([]);

	const customerService = new CustomerService();

	const { currentCompanyBranch } = useSelector(state => state.companyReducer);

	const dispatch = useDispatch();

	useEffect(() => {
		reloadCustomers();
	}, [currentCompanyBranch]);

	const reloadCustomers = () => {
		customerService
			.get(currentCompanyBranch?.id)
			.then(response => {
				if (response.success) {
					setCustomers(response.data);
				} else {
					throw Error(response.message);
				}
			})
			.catch(error => {
				dispatch(addAlertMessage(DANGER, t(prefix + 'fetchCustomersFailedMessage')));
				throw error;
			});
	};

	return {
		reloadCustomers,
		customers,
	};
};
