import { Vector3 } from 'three';
import MeasurementPoints from '../../../models/MeasurementPoints';
import { OUTER } from '../../../../constants/Angles';
import { BACK, FRONT, LEFT, RIGHT } from '../../../../constants/ObjectSides';
import { distanceFromSide, getHorizontalPointsTextSide, getVerticalPointsTextSide } from '../SideMeasurementLineHelper';
import { getHeightCoupeWidth } from '../../OperationHelper';
import { UPRIGHT_PRESETS } from '../../../../constants/Presets';

export function getPointsForHeightCoupe(piece, heightCoupe, preset) {
	const horizontalPoints = new MeasurementPoints(
		getHorizontalPointA(piece, heightCoupe, preset),
		getHorizontalPointB(piece, heightCoupe, preset),
	);

	horizontalPoints.textSide = getHorizontalPointsTextSide(horizontalPoints);
	horizontalPoints.lineLength = (100 / (100 - (1 - piece.dimensionRatio.length) * 100)) * heightCoupe.dimensions.width;

	const verticalPoints = new MeasurementPoints(
		getVerticalPointA(piece.dimensions, heightCoupe),
		getVerticalPointB(piece.dimensions, heightCoupe),
	);

	verticalPoints.textSide = getVerticalPointsTextSide(verticalPoints);
	verticalPoints.lineLength = piece.dimensions.height;

	if (heightCoupe.connectedCoupe) {
		horizontalPoints.visible = false;
		verticalPoints.visible = false;
	}

	return {
		horizontal: [horizontalPoints],
		vertical: verticalPoints,
	};
}

function getHorizontalPointA(piece, heightCoupe, preset) {
	const xPos = getXPosHorizontalPointA(piece, heightCoupe);
	const yPos = getYPos(piece.dimensions, heightCoupe, preset);
	const zPos = getZPos(piece.dimensions, heightCoupe);

	return new Vector3(xPos, yPos, zPos);
}

function getHorizontalPointB(piece, heightCoupe, preset) {
	const xPos = getXPosHorizontalPointB(piece, heightCoupe);
	const yPos = getYPos(piece.dimensions, heightCoupe, preset);
	let zPos;

	if ([LEFT, RIGHT].includes(heightCoupe.side)) zPos = getZPos(piece.dimensions);
	else {
		if (heightCoupe.side === FRONT) {
			zPos = piece.dimensions.width / 2 - heightCoupe.dimensions.width;
		} else {
			zPos = piece.dimensions.width / -2 + heightCoupe.dimensions.width;
		}
	}

	return new Vector3(xPos, yPos, zPos);
}

function getVerticalPointA(pieceDimensions, heightCoupe) {
	const xPos = getXPosVerticalPoint(pieceDimensions, heightCoupe);
	const yPos = getYPosVerticalPointA(pieceDimensions);
	let zPos = getZPos(pieceDimensions, heightCoupe);

	return new Vector3(xPos, yPos, zPos);
}

function getVerticalPointB(pieceDimensions, heightCoupe) {
	const xPos = getXPosVerticalPoint(pieceDimensions, heightCoupe);
	const yPos = getYPosVerticalPointB(pieceDimensions);
	let zPos = getZPos(pieceDimensions, heightCoupe);

	return new Vector3(xPos, yPos, zPos);
}

function getXPosHorizontalPointA(piece, heightCoupe) {
	let xPos;

	if (heightCoupe.side === LEFT) {
		xPos = piece.dimensions.length / -2;
	} else {
		const width = getHeightCoupeWidth(heightCoupe, piece.dimensions) * piece.dimensionRatio.length;
		xPos = piece.dimensions.length / 2 - width;
	}

	return xPos;
}

function getXPosHorizontalPointB(piece, heightCoupe) {
	let xPos;

	if (heightCoupe.side === LEFT) {
		const width = getHeightCoupeWidth(heightCoupe, piece.dimensions) * piece.dimensionRatio.length;
		xPos = piece.dimensions.length / -2 + width;
	} else {
		xPos = piece.dimensions.length / 2;
	}

	return xPos;
}

function getYPos(pieceDimensions, heightCoupe, preset) {
	let yPos;
	const positiveYPos = pieceDimensions.height / 2 + distanceFromSide;
	const negativeYPos = pieceDimensions.height / -2 - distanceFromSide;

	if (UPRIGHT_PRESETS.includes(preset)) {
		if (heightCoupe.angle === OUTER) {
			yPos = negativeYPos;
		} else {
			yPos = positiveYPos;
		}
	} else {
		if (heightCoupe.angle === OUTER) {
			yPos = positiveYPos;
		} else {
			yPos = negativeYPos;
		}
	}

	return yPos;
}

function getZPos(pieceDimensions, heightCoupe = null) {
	let zPos = pieceDimensions.width / 2;

	if (heightCoupe?.side === BACK) zPos = -zPos;

	return zPos;
}

function getYPosVerticalPointA({ height }) {
	return height / -2;
}

function getYPosVerticalPointB({ height }) {
	return height / 2;
}

function getXPosVerticalPoint({ length }, { side }) {
	let xPos;

	if (side === LEFT) {
		xPos = length / -2 - distanceFromSide;
	} else {
		xPos = length / 2 + distanceFromSide;
	}

	return xPos;
}
