import * as React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';

export const EmptyOfferList = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'empty.emptyOfferList.';

	return (
		<div className="message message--empty">
			<h4>{t(prefix + 'title')}</h4>
			<p>{t(prefix + 'subtext')}</p>

			<div className="message__actions">
				<span className="button button--success button--icon-before" onClick={() => props.openCreateModal()}>
					<i className="material-icons">add</i> {t(prefix + 'createButton')}
				</span>
			</div>
		</div>
	);
};

EmptyOfferList.propTypes = {
	openCreateModal: PropTypes.func.isRequired,
};
