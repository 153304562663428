import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { useSelector } from 'react-redux';
import { SectionTitle } from '../headers/SectionTitle';
import { ConfirmationModal } from './ConfirmationModal';
import { useForm } from 'react-hook-form';
import { ValidationMessage } from '../shared/ValidationMessage';
import * as OperationTypes from '../../constants/OperationTypes';
import * as StandardMeasurements from '../../constants/StandardMeasurements';
import { Operation } from '../../classes/models/Operation';
import { InfoModal } from './InfoModal';
import { VectorHelper } from '../../classes/helpers/VectorHelper';
import { parseNumber } from '../../classes/helpers/StringHelper';
import { LocationSelectorForm } from './shared/LocationSelectorForm';
import { createOperation, deleteOperation, updateOperation } from './index';
import { PILLARS } from '../../constants/Presets';
import { HORIZONTAL, VERTICAL } from '../../constants/ObjectOrientations';
import { ModalHolder } from './ModalHolder';
import { getDistanceFromFront, getDistanceFromLeft } from '../../classes/helpers/OperationHelper';

function DrillHoleModal(props) {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'modals.drillHoleModal.';

	const { errors, register, handleSubmit } = useForm();

	const [confirmationModalIsActive, setConfirmationModalIsActive] = useState(false);
	const [infoModalIsActive, setInfoModalIsActive] = useState(false);
	const [diameter, setDiameter] = useState('');

	const { currentConfiguration, currentPiece, currentOperation } = useSelector(state => state.offerReducer);
	const canEdit = useSelector(state => state.generalReducer.canEdit);

	useEffect(() => {
		if (currentOperation == null) {
			setDiameter(StandardMeasurements.DRILL_HOLE_RADIUSES[0]);
		} else {
			setDiameter(currentOperation.dimensions.length);
		}
	}, [props.isActive, currentOperation]);

	const createDrillHole = fields => {
		let drillHole = new Operation(0, OperationTypes.DRILL_HOLE);

		drillHole.dimensions.length = diameter;
		drillHole.position = getPosition(currentPiece, fields);

		createOperation(drillHole, () => props.onClose(true), t(prefix + 'drillHoleCreateFailed'));
	};

	const updateDrillHole = fields => {
		currentOperation.dimensions.length = diameter;
		currentOperation.position = getPosition(currentPiece, fields);

		updateOperation(currentOperation, () => props.onClose(true), t(prefix + 'drillHoleUpdateFailed'));
	};

	const deleteDrillHole = () => {
		deleteOperation(
			currentOperation,
			() => props.onClose(true),
			t(prefix + 'drillHoleDeleteSuccess'),
			t(prefix + 'drillHoleDeleteFailed'),
		);
	};

	const getPosition = ({ dimensions }, fields) => {
		return VectorHelper.getDrillHolePosition(
			dimensions,
			getDistanceFromLeft(dimensions, fields.horizontalSide, parseNumber(fields.xField)),
			getDistanceFromFront(dimensions, fields.verticalSide, parseNumber(fields.yField)),
		);
	};

	const closeModal = fields => {
		if (!fields) {
			props.onClose();
			return;
		}

		if (currentOperation == null) {
			if (currentPiece?.dimensions.height > 6) {
				setInfoModalIsActive(true);
			} else {
				createDrillHole(fields);
			}
		} else {
			updateDrillHole(fields);
		}
	};

	const toggleConfirmationModal = userHasAccepted => {
		if (userHasAccepted) {
			deleteDrillHole();
		}

		setConfirmationModalIsActive(!confirmationModalIsActive);
	};

	const closeInfoModal = () => {
		setInfoModalIsActive(false);
		props.onClose();
	};

	const renderFooterButtons = () => {
		let secondaryButtonStyle = 'button button--outline';
		let secondaryButtonContent = t(prefix + 'cancelButton');
		let secondaryButtonOnClick = () => closeModal();

		if (currentOperation != null && currentOperation.type === OperationTypes.DRILL_HOLE && canEdit) {
			secondaryButtonStyle = 'button button--danger';
			secondaryButtonContent = t(prefix + 'deleteButton');
			secondaryButtonOnClick = () => setConfirmationModalIsActive(true);
		}

		const submitButton = (
			<button
				key={'drillHoleModalSubmitButton'}
				data-cy={'drillHoleModal-submitButton'}
				className="button button--primary"
				form="drillHoleForm"
				type="submit"
			>
				{t(prefix + 'doneButton')}
			</button>
		);

		let buttons = [
			<button
				key={'drillHoleModalCancelButton'}
				data-cy={'drillHoleModal-deleteButton'}
				type="button"
				className={secondaryButtonStyle}
				onClick={secondaryButtonOnClick}
			>
				{secondaryButtonContent}
			</button>,
		];

		if (canEdit) {
			buttons.unshift(submitButton);
		}

		return buttons;
	};

	return (
		<ModalHolder isActive={props.isActive}>
			<ConfirmationModal
				isActive={confirmationModalIsActive}
				onClose={userHasAccepted => toggleConfirmationModal(userHasAccepted)}
				content={t('modals.drillHoleModal.deleteDrillHole')}
			/>

			<InfoModal
				isActive={infoModalIsActive}
				onClose={() => closeInfoModal()}
				content={t(prefix + 'infoModalContent')}
			/>

			<Modal show={props.isActive} onHide={() => closeModal()} animation={true}>
				<Modal.Header className="modal-header">
					<Modal.Title className="modal-title">{t(prefix + 'header')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form id="drillHoleForm" onSubmit={handleSubmit(closeModal)}>
						<p>{t(prefix + 'description')}</p>

						<LocationSelectorForm
							register={register}
							errors={errors}
							pieceOrientation={currentConfiguration?.options.preset === PILLARS ? VERTICAL : HORIZONTAL}
						/>

						<SectionTitle content={t(prefix + 'diameterTitle')} />
						<div className="form-group">
							<label>{t(prefix + 'diameterLabel')}</label>
							<select
								name={'diameter'}
								data-cy={'diameter'}
								onChange={event => setDiameter(event.target.value)}
								value={diameter}
								disabled={!canEdit}
							>
								{StandardMeasurements.DRILL_HOLE_RADIUSES.map(item => (
									<option value={item} key={item}>
										{item}
									</option>
								))}
							</select>
							<ValidationMessage content={t(prefix + 'infoMessage')} />
						</div>
					</form>
				</Modal.Body>
				<Modal.Footer>{renderFooterButtons()}</Modal.Footer>
			</Modal>
		</ModalHolder>
	);
}

DrillHoleModal.propTypes = {
	isActive: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};

export { DrillHoleModal };
