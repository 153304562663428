import { Service } from './Service';
import { Parser } from '../helpers/Parser';

class ClientTypeService extends Service {
	async getAll() {
		return await this.authenticatedRequest('get', 'clienttypes').then(response => {
			if (response.data.success) {
				response.data.data = response.data.data.map(clientTypeData => {
					return Parser.parseClientType(clientTypeData);
				});

				return response.data;
			}
		});
	}
}

export default ClientTypeService;
