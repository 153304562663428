import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { useDispatch, useSelector } from 'react-redux';
import { DIVIDE_PIECE, DIVIDE_PIECE_MODAL_NAME, PIECE, SURFACE_OPERATIONS } from '../../../constants/PieceActionTypes';
import { SURFACE_OPERATION_TYPES } from '../../../constants/OperationTypes';
import { setActiveOperationModal } from '../../../actions/GeneralActions';
import { Piece } from '../../../classes/models/Piece';
import { setCurrentPiece } from '../../../actions/OfferActions';
import { DropdownDivider } from '../../headers/DropdownDivider';
import { getOperationIcon } from '../../../classes/helpers/OperationHelper';
import { REAR_HEEL_TYPES } from '../../../constants/ObjectTypes';
import { CUSHIONS, LINTELS, PILLARS } from '../../../constants/Presets';

export const PieceListItemActions = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'pages.configurator.configurationPieceList.operationActions.';
	const constantsPrefix = 'constants.operationTypes.';
	const modalTypeConstantsPrefix = 'constants.pieceActionTypes.';

	const [selectableOperations, setSelectableOperations] = useState([]);

	const currentConfiguration = useSelector(state => state.offerReducer.currentConfiguration);
	const dispatch = useDispatch();

	useEffect(() => {
		if (currentConfiguration) {
			setSelectableOperations(currentConfiguration.getSelectableOperationTypes(props.piece.type));
			// Filter the surface operations from this list as they are combined in one modal
			setSelectableOperations(
				currentConfiguration
					.getSelectableOperationTypes(props.piece.type)
					.filter(o => !SURFACE_OPERATION_TYPES.includes(o)),
			);
		}
	}, [currentConfiguration]);

	const openModal = type => {
		dispatch(setActiveOperationModal(type));
		dispatch(setCurrentPiece(props.piece));
	};

	return (
		<div className="configuration__objects__actions">
			<span className="configuration__objects__actions__item">
				<button
					data-cy="piece-list-item-actions-add-button"
					className="button button--icon button--light dropdown-toggle"
					type="button"
					id="dropdownMenuButton"
					data-toggle="dropdown"
					aria-haspopup="true"
					aria-expanded="false"
				>
					<strong>{t(prefix + 'content')}</strong> <i className="material-icons">keyboard_arrow_down</i>
				</button>
				<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
					<span
						data-cy={`piece-list-item-actions-surface-operation-button`}
						key={`selectableOperationsSurfaceOperations`}
						className="pointer dropdown-item"
						onClick={() => openModal(SURFACE_OPERATIONS)}
					>
						<img src={getOperationIcon(SURFACE_OPERATIONS)} alt={SURFACE_OPERATIONS} />
						{t('constants.pieceActionTypes.' + SURFACE_OPERATIONS)}
					</span>

					{selectableOperations.map((type, index) => {
						return (
							<span
								data-cy={`piece-list-item-actions-${type.toLowerCase()}Button`}
								key={`selectableOperations${index}`}
								className="pointer dropdown-item"
								onClick={() => openModal(type)}
							>
								<img src={getOperationIcon(type)} alt={type} />
								{t(constantsPrefix + type)}
							</span>
						);
					})}

					{selectableOperations.length && <DropdownDivider />}
					{!!(
						!REAR_HEEL_TYPES.includes(currentConfiguration?.options.type) &&
						![CUSHIONS, LINTELS, PILLARS].includes(currentConfiguration?.options.preset)
					) && (
						<span
							data-cy="piece-list-item-actions-piece-button"
							key={PIECE}
							className="pointer dropdown-item"
							onClick={() => openModal(PIECE)}
						>
							{t(modalTypeConstantsPrefix + PIECE)}
						</span>
					)}

					{currentConfiguration?.options.preset !== CUSHIONS && (
						<span
							data-cy="piece-list-item-actions-dividePieceButton"
							key={DIVIDE_PIECE}
							className="pointer dropdown-item"
							onClick={() => openModal(DIVIDE_PIECE)}
						>
							{t(modalTypeConstantsPrefix + DIVIDE_PIECE_MODAL_NAME)}
						</span>
					)}
				</div>
			</span>
		</div>
	);
};

PieceListItemActions.propTypes = {
	piece: PropTypes.instanceOf(Piece).isRequired,
	disabledActions: PropTypes.array,
};

PieceListItemActions.defaultProps = {
	disabledActions: [],
};
