import { FRONT } from '../../constants/ObjectSides';

class MeasurementPoints {
	constructor(pointA, pointB, visible = true) {
		this.pointA = pointA;
		this.pointB = pointB;
		this.visible = visible;
		this.lineSide = null;
		this.textSide = FRONT;
		this.isShortened = false;
		this.lineLength = null;
	}
}

export default MeasurementPoints;
