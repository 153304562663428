import * as React from 'react';
import PropTypes from 'prop-types';
import { parseToCommaSeparated } from '../../classes/helpers/StringHelper';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { MINIMUM_STOCK_AMOUNT } from '../../constants/StandardMeasurements';

export const StockInfo = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'shared.stockInfo.';

	const getColor = () => {
		if (props.amount < 10) {
			return 'color--red';
		}
	};

	const getToolTipContent = () => {
		if (props.amount < 10) {
			return t(prefix + 'toolTipContentInsufficientAmount', { minimumAmount: MINIMUM_STOCK_AMOUNT });
		} else {
			return t(prefix + 'toolTipContentSufficientAmount');
		}
	};

	return (
		<div className="stock-info" data-tip={getToolTipContent()}>
			<small>{t(prefix + 'description')}</small>
			<span>
				<strong className={getColor()}>{parseToCommaSeparated(props.amount, 2)}</strong> / 10 m²
			</span>
			<ReactTooltip />
		</div>
	);
};

StockInfo.propTypes = {
	amount: PropTypes.number.isRequired,
};
