import * as React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import ReactTooltip from 'react-tooltip';

export const SelectableImage = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);

	const prefix = 'forms.inputs.selectableImage.';

	const getStyling = () => {
		let styling = 'form-group form-group--selectable';

		if (props.selected) {
			styling += ' form-group--selectable--active';
		}
		if (props.disabled) {
			styling += ' form-group--disabled';
		}

		return styling;
	};

	return (
		<div
			className={getStyling()}
			onClick={() => !props.disabled && props.onClick()}
			data-tip={props.disabled ? t(prefix + 'optionNotAvailable') : null}
		>
			<label data-cy={props.dataCy}>
				<input type="radio" name={props.name} />
				{props.image ? <img src={props.image} alt={props.content} /> : null}
				{props.content ? props.content : ''}
			</label>
			{props.disabled && <ReactTooltip />}
		</div>
	);
};

SelectableImage.propTypes = {
	name: PropTypes.string.isRequired,
	content: PropTypes.string,
	image: PropTypes.node,
	selected: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	dataCy: PropTypes.string,
};

SelectableImage.defaultProps = {
	selected: false,
	content: '',
};
