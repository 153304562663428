export const TYPE_1 = 'TYPE_1';
export const TYPE_3 = 'TYPE_3';
export const TYPE_4 = 'TYPE_4';
export const TYPE_5 = 'TYPE_5';
export const ROUNDED = 'ROUNDED';
export const HALF_ROUNDED = 'HALF_ROUNDED';
export const SLOPING = 'SLOPING';

export const ALL = [TYPE_1, TYPE_3, TYPE_4, TYPE_5];

export const DESCRIPTIVE_NAMES = [ROUNDED, HALF_ROUNDED, SLOPING];
