import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { useDispatch, useSelector } from 'react-redux';
import { SectionTitle } from '../headers/SectionTitle';
import * as ProfileOperationTypes from '../../constants/ProfileOperationTypes';
import { RadioButton } from '../forms/inputs/RadioButton';
import { DANGER, SUCCESS } from '../../constants/Variants';
import { OperationService } from '../../classes/services/OperationService';
import {
	addAlertMessage,
	setConfirmationModal,
	setShouldUpdatePrice,
	setWindowIsLoading,
} from '../../actions/GeneralActions';
import { Operation } from '../../classes/models/Operation';
import ConfigurableReducerHelper from '../../classes/helpers/ConfigurableReducerHelper';
import { CheckboxWithIcon } from '../forms/inputs/CheckboxWithIcon';
import { useForm } from 'react-hook-form';
import { COMPLETELY, PARTIALLY } from '../../constants/FinishedSideStates';
import { ValidatedInput } from '../forms/inputs/ValidatedInput';
import { parseNumber } from '../../classes/helpers/StringHelper';
import { getProfileIcon, getSidesForProfilesByPieceType } from '../../classes/helpers/OperationHelper';
import { CUSHION_NOT_GLUED } from '../../constants/ObjectTypes';
import { PROFILE, PROHIBITED_OPERATION_TYPES } from '../../constants/OperationTypes';
import { BOTTOM, LEFT, RIGHT, SURFACES, TOP } from '../../constants/ObjectSides';
import { getSideNameByPreset } from '../../classes/helpers/ObjectHelper';
import { ModalHolder } from './ModalHolder';
import { updateProfiles } from './index';
import {
	getAvailableProfileTypes,
	getAvailableSidesForProfiles,
	getSidesWithAConfiguredProfile,
	translateProfileTypeToName,
} from '../../classes/helpers/operations/ProfileOperationHelper';

function ProfileOperationModal(props) {
	const prefix = 'modals.profileModal.';
	const constantsPrefix = 'constants.objectSides.';

	const operationService = new OperationService();
	const configurableReducerHelper = new ConfigurableReducerHelper();

	const { t } = useTranslation(TRANSLATION_NAMESPACE);

	const { errors, register, handleSubmit } = useForm();

	const [selectedSides, setSelectedSides] = useState([]);
	const [selectedType, setSelectedType] = useState('');
	const [selectedSurface, setSelectedSurface] = useState('');
	const [availableSides, setAvailableSides] = useState([]);
	const [availableTypes, setAvailableTypes] = useState([]);
	const [typesToRender, setTypesToRender] = useState([]);
	const [height, setHeight] = useState();
	const [width, setWidth] = useState();

	const { currentConfiguration, currentOperation, currentPiece } = useSelector(state => state.offerReducer);
	const { activeOperationModal, canEdit } = useSelector(state => state.generalReducer);

	const dispatch = useDispatch();

	useEffect(() => {
		if (!props.isActive) return;

		setInitialState();

		const types = [CUSHION_NOT_GLUED].includes(currentConfiguration?.options.type)
			? [ProfileOperationTypes.SLOPING]
			: ProfileOperationTypes.DESCRIPTIVE_NAMES;

		setTypesToRender(types);
	}, [props.isActive, currentOperation]);

	useEffect(() => {
		if (!props.isActive) return;

		updateSelectableSides();
	}, [selectedSurface, selectedType]);

	const closeModal = fields => {
		if (!fields) {
			props.onClose();
			return;
		}

		if (activeOperationModal.additionalType) {
			updateProfile(fields);
		} else {
			createProfile(fields);
		}
	};

	const translateDescriptiveNameToProfileType = (name, height = 0, width = 0) => {
		let type;

		switch (name) {
			case ProfileOperationTypes.ROUNDED:
				type = ProfileOperationTypes.TYPE_1;
				break;
			case ProfileOperationTypes.HALF_ROUNDED:
				type = ProfileOperationTypes.TYPE_3;
				break;
			case ProfileOperationTypes.SLOPING:
				if (height > 2 || width > 2) {
					type = ProfileOperationTypes.TYPE_5;
				} else {
					type = ProfileOperationTypes.TYPE_4;
				}
				break;
		}

		return type;
	};

	const isSurfaceAvailable = (profileType, surface) => {
		if (!currentPiece) return;

		const profileWithTypeExists =
			currentPiece
				.getOperationsByType(PROFILE)
				.filter(o => o.additionalDimension.type === profileType && o.data === surface).length > 0;

		return !profileWithTypeExists || activeOperationModal?.data === surface;
	};

	const createProfile = fields => {
		let profile = new Operation(0, PROFILE);
		const height = parseNumber(fields?.height);
		const width = parseNumber(fields?.width);
		const type = translateDescriptiveNameToProfileType(selectedType, height, width);

		profile.additionalDimension.type = type;
		profile.additionalDimension.value = type;
		profile.dimensions.height = height;
		profile.dimensions.width = width;
		profile.data = selectedSurface;

		dispatch(setWindowIsLoading(true));

		operationService
			.createProfile(currentConfiguration.id, currentPiece, profile, selectedSides)
			.then(response => {
				if (response.success) {
					response.data.forEach(operation => {
						currentPiece.operations.push(operation);
					});

					configurableReducerHelper.updatePiece(currentPiece, currentConfiguration);
					dispatch(setShouldUpdatePrice(true));

					props.onClose(true);
				} else {
					throw Error(response.message);
				}
			})
			.catch(error => {
				dispatch(addAlertMessage(DANGER, t(prefix + 'profileCreateFailed')));
				throw error;
			})
			.finally(() => dispatch(setWindowIsLoading(false)));
	};

	const updateProfile = fields => {
		const width = parseNumber(fields?.width);
		const height = parseNumber(fields?.height);

		const data = {
			dimensions: {
				width: width,
				height: height,
			},
			sides: selectedSides,
			type: translateDescriptiveNameToProfileType(selectedType, height, width),
			data: selectedSurface,
		};

		updateProfiles(data, () => props.onClose(true), t(prefix + 'profileUpdateFailed'));
	};

	const deleteProfile = () => {
		dispatch(setWindowIsLoading(true));
		const profileTypeToDelete = activeOperationModal.additionalType;

		operationService
			.deleteProfiles(currentConfiguration.id, currentPiece.id, profileTypeToDelete, selectedSurface)
			.then(data => {
				if (data) {
					dispatch(addAlertMessage(SUCCESS, t(prefix + 'profileDeleteSuccess')));

					currentPiece.operations = currentPiece.operations.filter(
						operation =>
							!(operation.additionalDimension.type === profileTypeToDelete && operation.data === selectedSurface),
					);

					configurableReducerHelper.updatePiece(currentPiece, currentConfiguration);

					dispatch(setShouldUpdatePrice(true));
					props.onClose(true);
				} else {
					throw Error(data.message);
				}
			})
			.catch(error => {
				dispatch(addAlertMessage(DANGER, t(prefix + 'profileDeleteFailed')));
				throw error;
			})
			.finally(() => dispatch(setWindowIsLoading(false)));
	};

	const setInitialState = () => {
		// Editing mode
		if (currentPiece) {
			const profileType = activeOperationModal?.additionalType;

			const profileOperation = currentPiece.operations.find(
				operation =>
					operation.type === PROFILE &&
					operation.additionalDimension.type === profileType &&
					operation.data === activeOperationModal?.data,
			);

			if (profileType) {
				const sides = getProfilesOfCurrentType(profileOperation.data).map(operation => {
					return { name: operation.side, status: operation.dimensions.length > 5 ? COMPLETELY : PARTIALLY };
				});

				// getSelectableSides(currentPiece, profileType, profileOperation.data);

				setSelectedType(translateProfileTypeToName(profileType));
				setSelectedSurface(profileOperation.data);
				setSelectedSides(sides);
				setHeight(profileOperation.dimensions.height);
				setWidth(profileOperation.dimensions.width);
			} else {
				// Creating mode
				const sides = [];

				setSelectedType('');
				setDefaultSelectedSurface();
				setSelectedSides(sides);
				setHeight(null);
				setWidth(null);
			}

			initializeAvailableTypes(profileType);
			updateSelectableSides();
		}
	};

	const setDefaultSelectedSurface = () => {
		let profileType = activeOperationModal?.additionalType ?? selectedType;
		profileType = translateDescriptiveNameToProfileType(profileType, height, width);

		if (!profileType) {
			setSelectedSurface('');
			return;
		}

		if (isSurfaceAvailable(profileType, TOP)) {
			setSelectedSurface(TOP);
		} else if (isSurfaceAvailable(profileType, BOTTOM)) setSelectedSurface(BOTTOM);
		else setSelectedSurface('');
	};

	const updateSelectableSides = () => {
		let sidesAvailable = getProfilesOfCurrentType(selectedSurface).map(operation => operation.side);

		const sidesWithAProfile = getSidesWithAConfiguredProfile(currentPiece, selectedSurface);

		sidesAvailable = sidesAvailable.concat(currentPiece.getAvailableSides(PROHIBITED_OPERATION_TYPES.PROFILE));
		sidesAvailable = getAvailableSidesForProfiles(currentPiece, sidesAvailable);
		sidesAvailable = sidesAvailable.filter(side => !sidesWithAProfile.includes(side));
		setAvailableSides([...new Set(sidesAvailable)]);
	};

	const initializeAvailableTypes = profileType => {
		let typesAvailable = getAvailableProfileTypes(currentPiece);

		if (profileType) {
			typesAvailable.push(profileType);
		}
	};

	const getProfilesOfCurrentType = surface => {
		let profiles = [];

		if (currentPiece && activeOperationModal.type) {
			profiles = currentPiece
				.getOperationsByType(PROFILE)
				.filter(
					operation =>
						operation.additionalDimension.type === activeOperationModal.additionalType && operation.data === surface,
				);
		}

		return profiles;
	};

	const sideSelected = sideName => {
		return selectedSides.findIndex(side => side.name === sideName) > -1;
	};

	const statusIsSelected = (name, status) => {
		let isSelected = selectedSides.findIndex(side => side.name === name && side.status === status) > -1;

		if (status === PARTIALLY && !isSelected) {
			const oneStatusIsSelected = selectedSides.filter(side => side.name === name && side.status).length > 0;

			if (!oneStatusIsSelected) isSelected = true;
		}

		return isSelected;
	};

	const sideClicked = sideName => {
		let tempSides = selectedSides;
		let existingSideIndex = tempSides.findIndex(side => side.name === sideName);

		// If exists -> delete
		if (existingSideIndex !== -1) {
			tempSides.splice(existingSideIndex, 1);
		} else {
			// Add side
			tempSides.push({ name: sideName, status: PARTIALLY });
		}

		setSelectedSides([...tempSides]);
	};

	const sideStatusClicked = (sideName, status) => {
		setSelectedSides(
			selectedSides.map(side => {
				if (side.name === sideName) {
					side.status = status;
				}

				return side;
			}),
		);
	};

	const onTypeChange = value => {
		if (!ProfileOperationTypes.ALL.includes(value) && !ProfileOperationTypes.DESCRIPTIVE_NAMES.includes(value)) return;

		// Set the new value for the radio buttons, so the correct one is shown as selected
		setSelectedType(value);
	};

	const openConfirmationModal = () => {
		dispatch(
			setConfirmationModal({
				isActive: true,
				content: t('modals.profileModal.deleteProfile'),
				onAccept: () => deleteProfile(),
			}),
		);
	};

	const renderCheckboxes = () => {
		return getSidesForProfilesByPieceType(currentPiece?.type).map((selectableSide, index) => {
			const isDisabled =
				!availableSides.includes(selectableSide) && !selectedSides.map(s => s.name).includes(selectableSide);
			const sideName = getSideNameByPreset(currentConfiguration?.options.preset, selectableSide);

			return (
				<React.Fragment key={index}>
					<CheckboxWithIcon
						dataCy={`profileOperationModal-side-${selectableSide.toLowerCase()}`}
						onChange={() => sideClicked(selectableSide)}
						content={t(constantsPrefix + sideName)}
						checked={sideSelected(selectableSide)}
						disabled={isDisabled || !canEdit}
					/>

					{!!(sideSelected(selectableSide) && [LEFT, RIGHT].includes(selectableSide)) && (
						<>
							<RadioButton
								dataCy={`profileOperationModal-${selectableSide.toLowerCase()}-partially`}
								name={'partially' + selectableSide.toLowerCase() + 'RadioButton'}
								className="m-l-3"
								content={t(prefix + 'partialRadioButton')}
								checked={statusIsSelected(selectableSide, PARTIALLY)}
								disabled={isDisabled || !canEdit}
								onChange={() => sideStatusClicked(selectableSide, PARTIALLY)}
							/>

							<RadioButton
								dataCy={`profileOperationModal-${selectableSide.toLowerCase()}-completely`}
								name={'completely' + selectableSide.toLowerCase() + 'RadioButton'}
								className="m-l-3"
								content={t(prefix + 'completelyRadioButton')}
								checked={statusIsSelected(selectableSide, COMPLETELY)}
								disabled={isDisabled || !canEdit}
								onChange={() => sideStatusClicked(selectableSide, COMPLETELY)}
							/>
						</>
					)}
				</React.Fragment>
			);
		});
	};

	const renderInputBoxes = () => {
		if (selectedType === ProfileOperationTypes.SLOPING) {
			return (
				<>
					<SectionTitle content={t(prefix + 'dimensionsTitle')} />
					<div className="row">
						<div className="col-6">
							<ValidatedInput
								dataCy={'profileOperationModal-sloping-height'}
								register={register}
								name="height"
								label={t(prefix + 'heightLabel')}
								placeholder={t(prefix + 'heightPlaceholder')}
								value={height}
								error={errors.height}
								required={true}
								min={0.5}
								max={currentPiece?.dimensions.height}
								disabled={!canEdit}
							/>
						</div>
						<div className="col-6">
							<ValidatedInput
								dataCy={'profileOperationModal-sloping-width'}
								register={register}
								name="width"
								label={t(prefix + 'widthLabel')}
								placeholder={t(prefix + 'widthPlaceholder')}
								value={width}
								error={errors.width}
								required={true}
								min={0.5}
								max={currentPiece?.dimensions.width / 2}
								disabled={!canEdit}
							/>
						</div>
					</div>
				</>
			);
		}
	};

	const renderFooterButtons = () => {
		let secondaryButtonStyle = 'button button--outline';
		let secondaryButtonContent = t(prefix + 'cancelButton');
		let secondaryButtonOnClick = () => closeModal();

		if (getProfilesOfCurrentType(selectedSurface).length > 0 && canEdit) {
			secondaryButtonStyle = 'button button--danger';
			secondaryButtonContent = t(prefix + 'deleteButton');
			secondaryButtonOnClick = () => openConfirmationModal();
		}

		const submitButton = (
			<button
				data-cy="profileOperationModal-submitButton"
				key={'profileOperationModalSubmitButton'}
				className="button button--primary"
				type="submit"
				form="profileOperationForm"
				disabled={!selectedSides.length || !selectedType}
			>
				{t(prefix + 'doneButton')}
			</button>
		);

		let buttons = [
			<button
				data-cy="profileOperationModal-cancelButton"
				key={'profileOperationModalCancelButton'}
				data-cy={'profileOperationModal-deleteButton'}
				type="button"
				className={secondaryButtonStyle}
				onClick={secondaryButtonOnClick}
			>
				{secondaryButtonContent}
			</button>,
		];

		if (canEdit) {
			buttons.unshift(submitButton);
		}

		return buttons;
	};

	return (
		<ModalHolder isActive={props.isActive}>
			<Modal show={props.isActive} onHide={() => closeModal()}>
				<Modal.Header className="modal-header">
					<Modal.Title className="modal-title">{t(prefix + 'header')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form id="profileOperationForm" onSubmit={handleSubmit(closeModal)}>
						<p>{t(prefix + 'description')}</p>

						<SectionTitle content={t(prefix + 'typeTitle')} />

						{typesToRender.map(type => {
							return (
								<RadioButton
									dataCy={`profileOperationModal-type-${type.toLowerCase()}`}
									key={type}
									name="profileType"
									content={t('constants.profileOperationTypes.' + type)}
									image={getProfileIcon(type)}
									checked={selectedType === type}
									onChange={() => onTypeChange(type)}
									disabled={!canEdit}
								/>
							);
						})}

						{[ProfileOperationTypes.HALF_ROUNDED, ProfileOperationTypes.SLOPING].includes(selectedType) && (
							<>
								<SectionTitle content={t(prefix + 'surfaceTitle')} />

								{SURFACES.map(surface => {
									return (
										<RadioButton
											dataCy={`profileOperationModal-surface-${surface.toLowerCase()}`}
											key={surface}
											name="surface"
											content={t('constants.objectSides.' + surface)}
											checked={selectedSurface === surface}
											onChange={() => setSelectedSurface(surface)}
											disabled={
												!canEdit || !isSurfaceAvailable(translateDescriptiveNameToProfileType(selectedType), surface)
											}
										/>
									);
								})}
							</>
						)}

						<SectionTitle content={t(prefix + 'sideTitle')} />

						<div className="form-group">{renderCheckboxes()}</div>

						{renderInputBoxes()}
					</form>
				</Modal.Body>
				<Modal.Footer>{renderFooterButtons()}</Modal.Footer>
			</Modal>
		</ModalHolder>
	);
}

ProfileOperationModal.propTypes = {
	isActive: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};

export { ProfileOperationModal };
