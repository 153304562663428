import * as OperationTypes from '../../constants/OperationTypes';
import { RECTANGULAR_CUT_OUT_TYPES } from '../../constants/OperationTypes';
import { parseNumber } from '../helpers/StringHelper';
import { determineCorner } from '../helpers/OperationHelper';
import { DEGREES } from '../../constants/Values';
import i18n from '../../i18n';
import { SummaryHelper } from '../helpers/SummaryHelper';
import { getSideNameByPreset } from '../helpers/ObjectHelper';

class Operation {
	constructor(id, type, dimensions = {}, position = null, isFinished = false) {
		this.id = id;
		this.type = type;
		this.dimensions = dimensions;
		this.position = position;
		this.isFinished = isFinished;
		this.side = null;
		this.angle = null;
		this.additionalDimension = { type: null, value: null };
		this.data = '';
		this.originalPosition = null;
	}

	setDistance(distance) {
		this.dimensions.distance = distance;
	}

	getSummary(preset = null) {
		const dimensions = this.dimensions;
		const additionalDimension = this.additionalDimension;

		let dimensionSummary;
		let sideSummary;
		let shouldShowSide = false;

		switch (this.type) {
			case OperationTypes.NOTCH:
				dimensionSummary = dimensions.length + 'x' + dimensions.width;
				shouldShowSide = true;
				break;
			case OperationTypes.COUPE:
				dimensionSummary = parseNumber(additionalDimension.value);

				if (Math.round(dimensionSummary) !== dimensionSummary) {
					dimensionSummary = dimensionSummary.toFixed(2);
				}

				if (additionalDimension.type === DEGREES) {
					dimensionSummary += '°';
				} else {
					dimensionSummary += ' cm';
				}
				shouldShowSide = true;
				break;
			case OperationTypes.GROOVES:
				dimensionSummary = i18n.t('app:constants.grooveTypes.' + additionalDimension.type);
				break;
			case OperationTypes.DRILL_HOLE:
				dimensionSummary = dimensions.length + ' mm';
				break;
			case OperationTypes.CORNER_CUTOUT:
				const cutoutCorner = i18n.t(
					'app:constants.objectCorners.' + determineCorner(additionalDimension.type, this.side),
				);
				dimensionSummary = `${cutoutCorner} - ${this.dimensions.length} x ${this.dimensions.width}`;
				break;
			case OperationTypes.ROUNDED_CORNER:
				const roundedCorner = i18n.t(
					'app:constants.objectCorners.' + determineCorner(additionalDimension.type, this.side),
				);
				dimensionSummary = `${roundedCorner} - ${this.dimensions.length} cm`;
				break;
			case OperationTypes.COUPE_OVER_LENGTH:
				const corner = i18n.t('app:constants.objectCorners.' + determineCorner(additionalDimension.type, this.side));
				dimensionSummary = `${corner} - ${this.dimensions.width} cm`;
				break;
			case OperationTypes.HEIGHT_COUPE:
				if (additionalDimension.type === DEGREES) {
					dimensionSummary = additionalDimension.value + '°';
				} else {
					dimensionSummary = additionalDimension.value + ' cm';
				}
				shouldShowSide = true;
				break;
			case OperationTypes.DEBASING_ROUGH:
				dimensionSummary = SummaryHelper.getDebasingSummary(this);
				break;
			case OperationTypes.RECTANGULAR_CUT_OUT:
				if (this.additionalDimension.type === RECTANGULAR_CUT_OUT_TYPES.ROUND) {
					dimensionSummary = `${dimensions.length} cm`;
				} else {
					dimensionSummary = `${dimensions.length} x ${dimensions.width} cm`;
				}
				break;
			case OperationTypes.ANCHOR_HOLE:
				dimensionSummary = `${dimensions.height} cm`;
				break;
			case OperationTypes.CHISELED_SIDE:
			case OperationTypes.FINISHED_SIDE:
				break;
			case OperationTypes.DEBASING:
				dimensionSummary = SummaryHelper.getDebasingSummary(this);
				break;
			case OperationTypes.RABAT:
			case OperationTypes.NOTCH_OVER_LENGTH:
				let side = this.side;
				let surface = this.additionalDimension.type;

				if (preset) {
					side = getSideNameByPreset(preset, this.side);
					surface = getSideNameByPreset(preset, this.additionalDimension.type);
				}

				side = i18n.t('app:constants.objectSides.' + side);
				surface = i18n.t('app:constants.objectSides.' + surface);
				dimensionSummary = `${surface} - ${side}`;
				break;
			case OperationTypes.BUSHHAMMERING:
				dimensionSummary = i18n.t('app:constants.objectSides.' + this.side);
				break;
			default:
				dimensionSummary = '';
				break;
		}

		if (shouldShowSide) {
			sideSummary = i18n.t('app:constants.objectSides.' + this.side) + ' - ';
		} else {
			sideSummary = '';
		}

		return sideSummary + dimensionSummary;
	}
}

export { Operation };
