import { CENTIMETERS } from '../../constants/Values';
import { OperationHelper, CoupeOperation, VectorHelper } from '../../internal';
import { BoxGeometry, ExtrudeGeometry, MathUtils, Mesh, MeshStandardMaterial, Shape, Vector3 } from 'three';
import CSG from '../../threeJsPlugins/csg';
import { COUPE, HEIGHT_COUPE } from '../../constants/OperationTypes';
import { BASEBOARDS } from '../../constants/Presets';

class OperationBuilder {
	buildCoupe(mainObject, additionalDimension, angle, side, type, width) {
		let length;
		const barExtrusion = mainObject.dimensions.extrusion ?? 0;

		if (additionalDimension.type === CENTIMETERS) {
			length = mainObject.dimensions.length - additionalDimension.value;
		} else {
			length = OperationHelper.getBottomSideLengthOfCoupeByAngleDegrees(additionalDimension.value, width);
		}

		const dimensions = {
			length: length,
			width: width ?? mainObject.dimensions.width,
			height: mainObject.dimensions.height + barExtrusion + 0.1,
		};

		let coupe = new CoupeOperation(0, mainObject.id, side, angle, dimensions);
		if (type === HEIGHT_COUPE) coupe.type = type;

		coupe.additionalDimension = additionalDimension;
		coupe.position =
			type === COUPE
				? VectorHelper.getVectorForCoupe(mainObject.dimensions, coupe)
				: VectorHelper.getHeightCoupePosition(mainObject.dimensions, coupe, BASEBOARDS);

		return coupe;
	}

	buildCushionForSlopingSide(objectDimensions, cushionHeight) {
		const depth = objectDimensions.width - objectDimensions.barWidth;

		let triangle = this.__createCushionTriangle(cushionHeight, depth, objectDimensions.cushionWidth);

		// If there is protrusion, create a triangle
		let protrusionTriangle = this.__createProtrusionTriangle(
			cushionHeight + objectDimensions.extrusion,
			objectDimensions.protrusion,
			objectDimensions.cushionWidth,
		);

		// Add the two objects to become a cushion
		let mainBsp = CSG.fromMesh(triangle);
		let protrusionBsp = CSG.fromMesh(protrusionTriangle);

		if (objectDimensions.extrusion > 0) {
			// There needs to be a slab on the triangle to reach the level of the extrusion
			// Otherwise the cushion would be lower than the highest point of the object (the extrusion)
			let slab = new Mesh(new BoxGeometry(objectDimensions.cushionWidth, objectDimensions.extrusion, depth));
			slab.position.add(
				new Vector3(objectDimensions.cushionWidth / 2, cushionHeight + objectDimensions.extrusion / 2, -depth / 2),
			);
			slab.updateMatrix();

			let slabBsp = CSG.fromMesh(slab);

			mainBsp = mainBsp.union(slabBsp);
		}

		if (objectDimensions.protrusion > 0) {
			mainBsp = mainBsp.subtract(protrusionBsp);
		}
		let cushion = CSG.toMesh(mainBsp, triangle.matrix);
		cushion.material = triangle.material;

		return cushion;
	}

	__createProtrusionTriangle(height, depth, width) {
		let shape = new Shape();
		shape.moveTo(0, 0);
		shape.lineTo(0, height);
		shape.lineTo(depth, height);
		shape.lineTo(0, 0);

		// Add some settings for the shape, I added 0.1 so it would extrude from the main object a bit.
		// That way the coupe will cut through the whole object
		let extrudeSettings = {
			steps: 2,
			depth: width,
			bevelEnabled: false,
		};

		let triangle = new Mesh(new ExtrudeGeometry(shape, extrudeSettings), new MeshStandardMaterial());
		triangle.rotateY(MathUtils.degToRad(90));
		triangle.updateMatrix();

		return triangle;
	}

	__createCushionTriangle(height, depth, width) {
		let shape = new Shape();
		shape.moveTo(0, 0);
		shape.lineTo(0, height);
		shape.lineTo(depth, height);
		shape.lineTo(0, 0);

		// Add some settings for the shape, I added 0.1 so it would extrude from the main object a bit.
		// That way the coupe will cut through the whole object
		let extrudeSettings = {
			steps: 2,
			depth: width,
			bevelEnabled: false,
		};

		let triangle = new Mesh(new ExtrudeGeometry(shape, extrudeSettings), new MeshStandardMaterial({ color: 0xff00ff }));
		triangle.rotateY(MathUtils.degToRad(90));
		triangle.updateMatrix();

		return triangle;
	}
}

export { OperationBuilder };
