import * as React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import history from '../../config/history';

export const EmptyOffer = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'empty.emptyOffer.';

	return (
		<div className="message message--empty">
			<h4 data-cy="empty-offer-title">{t(prefix + 'title', { offerName: props.offerName })}</h4>
			<p data-cy="empty-offer-subtitle">{t(prefix + 'subtext')}</p>

			<div className="message__actions m-b-4">
				{/*<button className="button button--icon-before" onClick={() => history.push('/articles')}>*/}
				{/*    <i className='material-icons'>shopping_cart</i> {t(prefix + 'articleButton')}*/}
				{/*</button>*/}
				<button
					data-cy="empty-offer-button"
					className="button button--success"
					onClick={() => props.onAddConfigurationClick()}
				>
					{t(prefix + 'configurationButton')}
				</button>
			</div>
		</div>
	);
};

EmptyOffer.propTypes = {
	offerName: PropTypes.string.isRequired,
	onAddConfigurationClick: PropTypes.func.isRequired,
};
